import { useReducer } from 'react';
import * as Sentry from '@sentry/react';
import * as FullStory from '@fullstory/browser';

import {
  AUTH_ERROR,
  AUTH_SUCCESS,
  LOADING,
  LOGIN,
  LOGOUT,
  SEND_EMAIL,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_SUCCESS,
  RESET_ADDRESS_REQUEST_STATE,
  RESUMING_SUBSCRIPTION,
} from '../../types';
import AuthContext from './AuthContext';
import AuthReducer from './AuthReducer';

import httpClient from '../../../services/httpClient.service';
import {
  isSignedInWithEmailLink,
  refreshAuthToken,
  sendLoginLink,
  signInWithLink,
  signOutUser,
} from '../../../services/firebaseServices';

const AuthState = (props) => {
  const initialState = {
    isAuthenticated: localStorage.getItem('nano-user-auth-token') ? true : false,
    userData: { customer: null, stripeUserData: null },
    isLoading: false,
    isError: false,
    sendEmailInfo: { isLoading: false, isError: false, isSent: false, message: '' },
    isAddressUpdated: false,
    resumingSubscription: false,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const AuthFN = {
    sendEmail: async (email) => {
      dispatch({ type: SEND_EMAIL, payload: { ...state.sendEmailInfo, isLoading: true } });
      try {
        const isEmailExist = await httpClient().post('authentication/verify-email', { email });
        if (isEmailExist.data.userExists) {
          await sendLoginLink(email);
          localStorage.setItem('nano-user-email', email);
        }
        dispatch({
          type: SEND_EMAIL,
          payload: {
            isError: false,
            isSent: true,
            message: 'Email has sent, please verify your inbox',
            userExists: isEmailExist.data.userExists,
          },
        });
      } catch (error) {
        Sentry.captureException(error);
        dispatch({
          type: SEND_EMAIL,
          payload: {
            isError: true,
            isSent: false,
            message: 'Email has not sent, please verify your data and retry',
            userExists: false,
          },
        });
      }
    },
    login: async (url) => {
      dispatch({ type: LOGIN });
      if (isSignedInWithEmailLink(url)) {
        const email = localStorage.getItem('nano-user-email');
        try {
          //TODO: Prompt to enter email if not present in localstorage (ex. different device)
          const firebaseUser = await signInWithLink(email, url);
          const authToken = firebaseUser.user.accessToken;
          const resUser = await httpClient().post('/authentication/verify', { authToken });
          localStorage.setItem('nano-user-auth-token', authToken);
          FullStory.identify(resUser.data.customer.originalSession);
          dispatch({ type: AUTH_SUCCESS, payload: { ...resUser.data, authToken } });
        } catch (error) {
          Sentry.captureException(error);
          dispatch({ type: AUTH_ERROR });
        }
      }
    },
    verify: async () => {
      dispatch({ type: LOGIN });
      try {
        const newToken = await refreshAuthToken();
        localStorage.setItem('nano-user-auth-token', newToken);

        const authToken = localStorage.getItem('nano-user-auth-token');
        //TODO: Prompt to enter email if not present in localstorage (ex. different device)
        const res = await httpClient().post('/authentication/verify', { authToken });
        if (!res.data.message) {
          FullStory.identify(res.data.original_session);
          dispatch({ type: AUTH_SUCCESS, payload: { ...res.data, authToken } });
        } else {
          localStorage.removeItem('nano-user-auth-token');
          dispatch({ type: AUTH_ERROR, payload: res.data });
        }
      } catch (error) {
        localStorage.removeItem('nano-user-auth-token');
        dispatch({ type: AUTH_ERROR });
        Sentry.captureException(error);
      }
    },
    logout: async () => {
      try {
        await signOutUser();
        localStorage.removeItem('nano-user-email');
        localStorage.removeItem('nano-user-auth-token');
        dispatch({ type: LOGOUT });
      } catch (error) {
        Sentry.captureException(error);
      }
    },
  };

  const ProfileFN = {
    putUserProfile: async (email, data) => {
      dispatch({ type: LOADING });
      try {
        const res = await httpClient().put(`users/${email}`, data);
        if (!res.data.message) dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: res.data });
        else dispatch({ type: UPDATE_PROFILE_ERROR, payload: res.data });
      } catch (error) {
        dispatch({ type: UPDATE_PROFILE_ERROR });
        Sentry.captureException(error);
      }
    },
    resetAddressRequestState: async () => {
      dispatch({ type: RESET_ADDRESS_REQUEST_STATE });
    },
    setResumingSubscription: async (resuming) => {
      dispatch({ type: RESUMING_SUBSCRIPTION, payload: resuming });
    },
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        userData: state.userData,
        isLoading: state.isLoading,
        isError: state.isError,
        sendEmailInfo: state.sendEmailInfo,
        isAddressUpdated: state.isAddressUpdated,
        resumingSubscription: state.resumingSubscription,
        AuthFN,
        ProfileFN,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
