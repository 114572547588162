import { ReactElement } from "react";

interface SubscriptionPriceProps {
  className?: string;
  price: string;
  frequency: number;
}

export const SubscriptionPrice = ({className = "", price, frequency}: SubscriptionPriceProps): ReactElement => {
  return (
    <div className={ `subscription-price-container ${ className }`}>
      <p>{ price }</p>
      <span>per month</span>
      <h5>For those that change filters every { frequency > 1 ? frequency : "" } month{ frequency > 1 ? "s" : "" }.</h5>
    </div>
  );
};
