import {
  LOGIN,
  AUTH_SUCCESS,
  AUTH_ERROR,
  LOGOUT,
  SEND_EMAIL,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  LOADING,
  RESET_ADDRESS_REQUEST_STATE,
  RESUMING_SUBSCRIPTION,
} from '../../types';

const AuthReducer = (state, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SEND_EMAIL:
      return {
        ...state,
        sendEmailInfo: action.payload,
        isLoading: false,
      };
    case LOGIN:
      return {
        ...state,
        isLoading: true,
        isError: false,
        userData: {},
      };
    case LOGOUT:
      window.location.href = 'https://nanohome.com';
      return {
        ...state,
        isError: false,
        isLoading: false,
        isAuthenticated: false,
        userData: {},
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        userData: action.payload,
        isAddressUpdated: false,
        isError: false,
      };
    case AUTH_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        isAuthenticated: false,
        userData: {},
      };

    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        isAddressUpdated: true,
        isLoading: false,
      };

    case RESUMING_SUBSCRIPTION:
      return {
        ...state,
        resumingSubscription: action.payload,
      };

    case RESET_ADDRESS_REQUEST_STATE:
      return { ...state, isAddressUpdated: false, isLoading: false };

    case UPDATE_PROFILE_ERROR:
      return { isLoading: false, isError: true };

    default:
      return state;
  }
};

export default AuthReducer;
