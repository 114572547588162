import { FormEvent, ReactElement, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../store/context/auth/AuthContext';
import { getParams } from '../../utils';
import Spinner from '../common/spinner/Spinner';

interface LoginFormProps {
  registeredEmail: string | null;
}

const LoginForm = ({ registeredEmail }: LoginFormProps): ReactElement => {
  const [queryParams] = useState(getParams());
  const [wizardId, setWizardId] = useState('');

  // CONTEXT
  const { AuthFN, sendEmailInfo } = useContext(AuthContext);

  // STATE
  const [formData, setFormData] = useState({ email: '' });

  useEffect(() => {
    let wizardIdParam = queryParams.find((param) => param.key === 'wizard_id')?.value;
    if (wizardIdParam) {
      setWizardId(wizardIdParam);
    }
  }, [queryParams, wizardId]);

  useEffect(() => {
    if (registeredEmail && registeredEmail !== '') setFormData({ email: registeredEmail });
  }, [registeredEmail]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value.toLowerCase() });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    AuthFN.sendEmail(formData.email);
  };

  return (
    <div className="step" data-step="email">
      <form onSubmit={handleSubmit}>
        {sendEmailInfo?.isLoading ? (
          <Spinner />
        ) : !sendEmailInfo?.isSent ? (
          <>
            <h3>
              {registeredEmail ? 'Your email is already registered, continue to login.' : 'Sign in to your account.'}
            </h3>
            <h5>{sendEmailInfo?.message !== '' ? sendEmailInfo?.message : 'We will email you a login link.'}</h5>
            <div className="input">
              <input
                onChange={handleChange}
                value={formData.email}
                name="email"
                type="email"
                placeholder="Email"
                autoComplete="off"
                required
                className="email-input simple-input"
                disabled={sendEmailInfo && sendEmailInfo?.isSent}
              />
            </div>
            <div className="buttons-footer">
              <button type="submit" disabled={formData.email.trim().length === 0} className="button next">
                Login
              </button>
            </div>
            <br />
            <h5>
              Or <Link to={`/${wizardId ? `?wizard_id=${wizardId}` : ''}`}>Use another email to create an account</Link>
            </h5>
          </>
        ) : sendEmailInfo.isSent && sendEmailInfo.userExists ? (
          <div className="step" data-step="waitlist">
            <h3>
              <span className="mdi mdi-email-check-outline"></span>Email sent, check your inbox
            </h3>
            {/* <h5>Please verify your inbox.</h5> */}
          </div>
        ) : (
          <div className="step" data-step="waitlist">
            <h3>
              <span className="mdi mdi-email-remove-outline"></span>We cannot find you, please register here.
            </h3>
            <Link to={{ pathname: '/', search: window.location.search }}>Create Account</Link>
          </div>
        )}
      </form>
    </div>
  );
};

export default LoginForm;
