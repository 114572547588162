import { Fragment, ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import './styles.scss';

function createWrapperAndAppendToBody(wrapperId: string) {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', wrapperId);
  document.body.appendChild(wrapperElement);
  return wrapperElement;
}

function ReactPortal({ children, wrapperId = 'react-portal' }: { children: JSX.Element; wrapperId: string }) {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    let systemCreated = false;
    if (!element) {
      systemCreated = true;
      element = createWrapperAndAppendToBody(wrapperId);
    }
    setWrapperElement(element);

    return () => {
      if (systemCreated && element?.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId]);

  if (wrapperElement === null) return null;

  return createPortal(children, wrapperElement);
}

interface Props {
  children: ReactNode;
  show: boolean;
  onClose: () => void;
  modalStyle?: any;
  backdropStyle?: any;
}

export function Portal({ modalStyle, children, show, onClose, backdropStyle }: Props) {
  const nodeRef = useRef<HTMLDivElement>(null);
  const modalContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) => (e.key === 'Escape' ? onClose() : null);
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [onClose]);

  useEffect(() => {
    const listener = (event: Event) => {
      if (!modalContentRef.current || modalContentRef.current.contains(event.target as Node)) {
        return;
      }
      onClose();
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [modalContentRef, onClose]);

  return show ? (
    <ReactPortal wrapperId="react-portal-container">
      <div className="portal-background" ref={nodeRef} style={backdropStyle}>
        <button className="close__btn modalCloseBtn smallerResolutionsBtn" onClick={onClose} />
        <div ref={modalContentRef} className="portal-content" style={modalStyle}>
          {children}
        </div>
      </div>
    </ReactPortal>
  ) : (
    <Fragment />
  );
}
