import React, { EventHandler, MouseEventHandler, ReactNode, useEffect, useRef } from 'react';
import './Modal.scss';

interface ProfileModalProps {
  children: ReactNode;
  show: boolean;
  onClose: MouseEventHandler;
  modalStyle?: any;
  backdropStyle?: any;
}

const ProfileModal = ({ modalStyle, children, show, onClose, backdropStyle }: ProfileModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const modalContentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (show) {
      modalRef.current?.classList.add('visible');
    } else {
      modalRef.current?.classList.remove('visible');
    }
  }, [show]);

  const useOnClickOutside = (ref: React.RefObject<HTMLElement>, handler: EventHandler<any>) => {
    useEffect(() => {
      const listener = (event: Event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target as Node)) {
          return;
        }
        handler(event);
      };
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }, [ref, handler]);
  };

  useOnClickOutside(modalContentRef, onClose);

  return (
    <React.Fragment>
      <button onClick={onClose} className="close__btn modalCloseBtn smallerResolutionsBtn" title="Close"></button>
      <div ref={modalRef} style={backdropStyle} className="modal">
        <div ref={modalContentRef} style={modalStyle} className="modal__wrap" onClick={() => null}>
          <button onClick={onClose} className="close__btn modalCloseBtn largerResolutionsBtn" title="Close"></button>
          {children}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProfileModal;
