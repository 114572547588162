import { ReactElement, useContext, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { TOKEN_NAME } from '../config';
import AuthContext from '../store/context/auth/AuthContext';

import Layout from '../components/layout/layout';
import LoginForm from '../components/login/LoginForm';
import Spinner from '../components/common/spinner/Spinner';

const Login = (): ReactElement => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const apiKey = queryParams.get(TOKEN_NAME);
  const email = queryParams.get('email');

  // CONTEXT
  const { AuthFN, isAuthenticated, isLoading, isError } = useContext(AuthContext);

  // EFFECTS
  useEffect(() => {
    const path = window.location.href;
    if (!isAuthenticated && !isLoading && apiKey) {
      AuthFN.login(path);
    }
    // eslint-disable-next-line
  }, [apiKey]);

  useEffect(() => {
    if (isAuthenticated && !isError && !isLoading) navigate('/dashboard/');
    // else navigate('/login');

    // eslint-disable-next-line
  }, [isLoading, isError, isAuthenticated]);
  return (
    <Layout>
      <div className="start table">
        <div className="middle">{isLoading || apiKey ? <Spinner /> : <LoginForm registeredEmail={email} />}</div>
      </div>
    </Layout>
  );
};

export default Login;
