import { useContext } from 'react';
import AuthContext from '../../../store/context/auth/AuthContext';
import Spinner from '../../common/spinner/Spinner';
import Card from '../Card';
import Invoices from '../Invoices';
import PaymentCard from '../PaymentCard';

const Billing = () => {
  // CONTEXT
  const { isLoading, userData } = useContext(AuthContext);

  const nextInvoiceDate = (lastDate: number, days: number) => {
    const date = new Date(lastDate);
    date.setDate(date.getDate() + days);
    return date;
  };

  const lastSubscription =
    userData.stripeUserData &&
    userData.stripeUserData.paymentMethods &&
    userData.stripeUserData.invoices &&
    userData.stripeUserData.paymentMethods.length > 0 &&
    userData.stripeUserData.invoices.length > 0;

  return (
    <>
      <div className="wrapper">
        <div className="inner-wrapper">
          {isLoading || isLoading || !userData ? (
            <Spinner />
          ) : (
            <>
              {lastSubscription ? (
                <>
                  <Card title="Payment Method">
                    {userData.stripeUserData &&
                      userData.stripeUserData.paymentMethods.map((paymentMethod: any) => (
                        <PaymentCard
                          key={paymentMethod.id}
                          brand={paymentMethod.card.brand}
                          last4={paymentMethod.card.last4}
                          exp_month={paymentMethod.card.exp_month}
                          exp_year={paymentMethod.card.exp_year}
                        />
                      ))}
                  </Card>

                  <Card title="Next Payment">
                    <p>
                      {userData.stripeUserData?.invoices?.length > 0 &&
                        Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(
                          nextInvoiceDate(userData.stripeUserData?.invoices[0].created * 1000, 30),
                        )}
                    </p>
                  </Card>

                  <Card title="Past Invoices">
                    <ul className="invoices list">
                      {userData?.stripeUserData?.invoices.length > 0 ? (
                        userData?.stripeUserData.invoices.map((invoice: any) => (
                          <Invoices
                            key={invoice?.id}
                            status={invoice?.status}
                            date={invoice?.created}
                            amountPaid={invoice?.amount_paid}
                            invoiceURL={invoice?.hosted_invoice_url}
                          />
                        ))
                      ) : (
                        <p>No invoices</p>
                      )}
                    </ul>
                  </Card>
                </>
              ) : (
                <div style={{ display: 'flex' }}>
                  <label style={{ padding: '0 10px', fontWeight: 400 }}>
                    {"There's no active subscription for your account. Complete your profile & subscribe."}
                  </label>
                </div>
              )}
              <div style={{ display: 'flex' }}>
                <label style={{ padding: '0 10px', fontWeight: 400 }}>
                  To manage your account or subscription please contact{' '}
                  <a href="mailto: support@nanohome.com">support@nanohome.com</a> or{' '}
                  <a href="tel:5126016969">512-601-6969</a>.
                </label>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Billing;
