import { every } from 'lodash';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { ProfileDataField } from '../../../interfaces';
import AuthContext from '../../../store/context/auth/AuthContext';
import { SUBSCRIPTION_INTERVALS } from '../../../utils';
import TextField from '../../common/forms/TextField';
import Modal from '../../common/modal/Modal';
import Spinner from '../../common/spinner/Spinner';
import UpdateProfile from '../UpdateProfile';

const Profile = () => {
  const [params] = useSearchParams();

  // CONTEXT
  const { isLoading, isError, userData } = useContext(AuthContext);

  // STATE
  const [showModal, setShowModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(params.get('edit') || false);
  const [dataFields, setDataFields] = useState<ProfileDataField[]>([]);

  useEffect(() => {
    if (userData.customer) {
      setDataFields([
        { label: 'Name', value: userData?.customer?.user?.name?.capitalizeFirstCharacter() },
        { label: 'Phone', value: userData?.customer?.phone },
        { label: 'Email', value: userData?.customer?.user?.email },
        { label: 'Address 1', value: userData?.customer?.activeAddress?.addressLine1 },
        { label: 'Address 2', value: userData?.customer?.activeAddress?.addressLine2 },
        { label: 'City', value: userData?.customer?.activeAddress?.city },
        { label: 'State', value: userData?.customer?.activeAddress?.state },
        { label: 'Zip Code', value: userData?.customer?.activeAddress?.zipCode },
        { label: 'Home Type', value: userData?.customer?.homeType },
        { label: 'Square Footage', value: userData?.customer?.homeSquareFootage },
        {
          label: 'Concerns',
          value: userData?.customer?.userConcerns?.map((concern: any) => `${concern} `),
        },
        {
          label: 'Subscription Interval',
          value: SUBSCRIPTION_INTERVALS[userData?.customer?.lastSubscription?.interval - 1]?.name || 'Unsubscribed',
        },
      ]);
      const { subscribed, filters } = userData.customer;
      const canCompleteAccount =
        !subscribed && filters?.length > 0 && every(filters, (filter) => !!filter.selectedVariantSize);

      const modalWasClosed = localStorage.getItem('profile-modal-closed') === 'true';

      if (canCompleteAccount && !modalWasClosed) {
        setShowModal(true);
      }
    }
    // eslint-disable-next-line
  }, [userData]);

  const subscribed = userData?.customer?.lastSubscription?.subscribedAt;
  const selected_filters = userData?.customer?.filters || [];
  const selected_variant_sizes =
    userData?.customer?.filters !== undefined
      ? userData?.customer?.filters.map((filter: any) => filter.selectedVariantSize)?.filter((size: string) => !!size)
      : [];
  const canCompleteAccount = !subscribed || selected_filters?.length === 0;

  const calculateRedirection =
    selected_filters?.length > 0 && selected_filters?.length === selected_variant_sizes?.length
      ? 'activate'
      : 'filters';

  const closeModal = () => {
    localStorage.setItem('profile-modal-closed', 'true');
    setShowModal(false);
  };

  return (
    <>
      <div className="wrapper">
        <div className="inner-wrapper">
          {isLoading && <Spinner />}
          {!isLoading && !isError && (
            <div className="dashboard-block">
              {canCompleteAccount && (
                <div className="completeAccountBanner">
                  <div className="bannerLeftContent">
                    <h3>
                      <b>{userData.customer?.user?.name?.capitalizeFirstCharacter()},</b> your profile is almost
                      complete!
                    </h3>
                    <span>We just need a little more information to start improving the air in your home.</span>
                    <ul>
                      <li>You still need to select a frequency plan.</li>
                      <li>We need to know if you're upgrading your filters selection to carbon.</li>
                    </ul>
                  </div>
                  <div className="bannerRightContent">
                    <Link to={`/dashboard/${calculateRedirection}`} className="completeAccountBannerBtn">
                      Complete Profile
                    </Link>
                  </div>
                </div>
              )}

              <div className="title">
                <h1>Profile</h1>
                {!isUpdating && (
                  <button className="next editProfileBtn" onClick={() => setIsUpdating(true)}>
                    Edit Profile
                  </button>
                )}
              </div>
              {isUpdating ? (
                <UpdateProfile setIsUpdating={setIsUpdating} userData={userData} />
              ) : (
                <div className="form-group no-padding profileView">
                  {dataFields.map((dataField: ProfileDataField, index: number) => (
                    <Fragment key={index}>
                      <TextField dataField={dataField} />
                    </Fragment>
                  ))}
                </div>
              )}
            </div>
          )}
          <div style={{ display: 'flex' }}>
            <label style={{ padding: '0 10px', fontWeight: 400 }}>
              For changes to your subscription please contact{' '}
              <a href="mailto: support@nanohome.com">support@nanohome.com</a> or{' '}
              <a href="tel:5126016969">512-601-6969</a>.
            </label>
          </div>
        </div>
      </div>

      <Modal show={showModal} onClose={closeModal}>
        <div className="modalFloatingContent">
          <h3>Your profile is almost complete!</h3>
          <p>We need to know if you're upgrading your filters selection to carbon.</p>
          <Link to={`/dashboard/${calculateRedirection}`} className="completeAccountModalBtn">
            Complete Profile
          </Link>
        </div>
      </Modal>
    </>
  );
};

export default Profile;
