import { ReactElement } from 'react';
import { IWizard, IActions, WizardStepIdentifiers, ISteps } from '../../interfaces';
import {
  EnterEmail,
  EnterName,
  EnterKindOfHome,
  EnterSquareFootage,
  EnterACSystemAge,
  EnterConcerns,
  EnterFiltersProfile,
  ProfileSummary,
  StockSelection,
  Order,
  Confirmation,
  ScheduleFilterAssistance,
  EnterFiltersProfileWithDropdown,
  EnterShippingInfo,
  MultiSelect,
} from './steps';

// Map between Step types and react Components
const StepComponents = new Map<WizardStepIdentifiers, (props: any) => ReactElement>([
  [WizardStepIdentifiers.EMAIL, EnterEmail],
  [WizardStepIdentifiers.NAME, EnterName],
  [WizardStepIdentifiers.HOME_TYPE, EnterKindOfHome],
  [WizardStepIdentifiers.SQUARE_FOOTAGE, EnterSquareFootage],
  [WizardStepIdentifiers.ACSYSTEM_AGE, EnterACSystemAge],
  [WizardStepIdentifiers.CONCERNS, EnterConcerns],
  [WizardStepIdentifiers.FILTERS_PROFILE, EnterFiltersProfile],
  [WizardStepIdentifiers.FILTERS_PROFILE_WITH_DROPDOWN, EnterFiltersProfileWithDropdown],
  [WizardStepIdentifiers.MULTI_SELECT, MultiSelect],
  [WizardStepIdentifiers.PROFILE_SUMMARY, ProfileSummary],
  [WizardStepIdentifiers.STOCK_SELECTION, StockSelection],
  [WizardStepIdentifiers.ORDER, Order],
  [WizardStepIdentifiers.CONFIRMATION, Confirmation],
  [WizardStepIdentifiers.SCHEDULE_FILTER_ASSITANCE, ScheduleFilterAssistance],
  [WizardStepIdentifiers.SHIPPING, EnterShippingInfo],
]);

// returns a Wizard with predefined order and sets their actions and props based on received params (config)
export const setupWizard = (
  wizard: IWizard,
  mapping: Map<WizardStepIdentifiers, { props: any; actions: IActions }>,
): IWizard => {
  const wizardObject = Object.assign({}, wizard);

  const agentCallStep: ISteps = {
    type: WizardStepIdentifiers.SCHEDULE_FILTER_ASSITANCE,
    required: false,
    position: wizardObject?.steps.length + 1,
  };

  const confirmationStep: ISteps = {
    type: WizardStepIdentifiers.CONFIRMATION,
    required: true,
    position: wizardObject?.steps.length + 2,
  };

  if (
    wizardObject?.steps.length > 0 &&
    wizardObject.steps[wizardObject.steps.length - 1].type !== WizardStepIdentifiers.CONFIRMATION
  ) {
    wizardObject?.steps.push(agentCallStep);
    wizardObject?.steps.push(confirmationStep);
  }

  wizardObject?.steps.forEach((step) => {
    step.content = {};
    step.actions = {};
    step.content.body = StepComponents.get(step.type);
    step.content.props = mapping.get(step.type)?.props;
    step.actions = mapping.get(step.type)?.actions;
  });

  return wizardObject as IWizard;
};
