import { ReactElement, useContext, useEffect } from 'react';
import AuthContext from '../store/context/auth/AuthContext';
import Spinner from '../components/common/spinner/Spinner';

const Logout = (): ReactElement => {
  // CONTEXT
  const { AuthFN } = useContext(AuthContext);

  useEffect(() => {
    AuthFN.logout();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="wizardContent">
      <div className="start table">
        <div className="middle">
          <Spinner />
        </div>
      </div>
    </div>
  );
};

export default Logout;
