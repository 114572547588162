import React, { useState, useContext, ReactElement, MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import { includes } from 'lodash';

import { Elements, useElements, useStripe, PaymentElement } from '@stripe/react-stripe-js';
import {
  loadStripe,
  StripeElementsOptions,
  StripePaymentElementChangeEvent,
  StripePaymentElementOptions,
  StripeError,
} from '@stripe/stripe-js';

import { STRIPE_PUBLISHABLE_KEY } from '../../config';
import { GeneralContext } from '../../store/context/GeneralState';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY as string);

interface PaymentFormProps {
  customSuccessCallback?: Function;
  externalFormCallback?: Function;
  externalDisableConditions?: boolean;
  handleBackClick: MouseEventHandler<HTMLButtonElement>;
  isBackDisabled: boolean;
  nextStep?: Function;
  intentType: string;
}

interface CheckoutFormProps extends PaymentFormProps {
  clientSecret: string | undefined;
}

const PaymentForm = ({
  customSuccessCallback,
  externalFormCallback,
  externalDisableConditions,
  handleBackClick,
  isBackDisabled,
  nextStep,
  intentType,
}: PaymentFormProps): ReactElement => {
  const navigate = useNavigate();
  const { subscriptionSuccessUrl } = useContext(GeneralContext);

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionSuccessfull, setSubscriptionSuccessfull] = useState(false);
  const [paymentFormComplete, setPaymentFormComplete] = useState(false);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    if (subscriptionSuccessfull) {
      if (subscriptionSuccessUrl) {
        includes(subscriptionSuccessUrl, 'http')
          ? (window.location.href = subscriptionSuccessUrl)
          : navigate(subscriptionSuccessUrl);
      } else {
        navigate('/login');
      }
    }

    setMessage(null);
    setIsLoading(true);

    const confirmParams = {
      payment_method_data: {
        billing_details: {
          address: {
            country: 'US',
          },
        },
      },
    };
    let error: StripeError | undefined;
    switch (intentType) {
      case "payment":
         error = (await stripe.confirmPayment({
          elements,
          confirmParams,
          redirect: "if_required",
        })).error;
        break;
      case "setup":
        error = (await stripe.confirmSetup({
          elements,
          confirmParams,
          redirect: "if_required",
        })).error;
        break;
    
      default:
        console.error("Unexpected intent type");
        return;
    }

    if (error) {
      setMessage(
        error.type === 'card_error' || error.type === 'validation_error'
          ? (error.message as string)
          : 'An unexpected error occurred.',
      );
    } else {
      await externalFormCallback?.();
      setMessage(null);
      setSubscriptionSuccessfull(true);
      setTimeout(() => {
        if (customSuccessCallback) customSuccessCallback();
      }, 1500);
    }

    setIsLoading(false);
  };

  const options: StripePaymentElementOptions = {
    wallets: {
      applePay: 'auto',
      googlePay: 'auto',
    },
    fields: {
      billingDetails: {
        address: {
          country: 'never',
        },
      },
    },
  };

  const onPaymentChangeHandler = (event: StripePaymentElementChangeEvent) => {
    setPaymentFormComplete(event.complete);
  };

  return (
    <>
      <PaymentElement onReady={() => setIsLoading(false)} options={options} onChange={onPaymentChangeHandler} />

      <button
        className="checkoutStepBackBtn button back"
        onClick={handleBackClick}
        disabled={isBackDisabled || subscriptionSuccessfull}
      >
        Back
      </button>

      <button
        disabled={isLoading || !stripe || !elements || !paymentFormComplete || externalDisableConditions}
        className={
          'checkoutStepNextBtn ' +
          (isLoading
            ? 'mdi mdi-loading mdi-spin next'
            : subscriptionSuccessfull
            ? 'orderdPlaced mdi mdi-check-bold next'
            : 'next')
        }
        onClick={() => handleSubmit()}
      >
        <span id="button-text" style={{ margin: '0 0 0 4px' }}>
          {isLoading ? (
            <div className="spinner" id="spinner"></div>
          ) : subscriptionSuccessfull ? (
            'Proceed to Dashboard'
          ) : (
            'Subscribe Now!'
          )}
        </span>
      </button>

      {!customSuccessCallback && subscriptionSuccessfull && (
        <button
          disabled={isLoading || !subscriptionSuccessfull}
          className="checkoutStepNextBtn next"
          onClick={() => nextStep?.()}
        >
          <span id="button-text" style={{ margin: '0 0 0 4px' }}>
            Complete Profile
          </span>
        </button>
      )}

      <div className="stripeDeclinedPaymentsMsg">{message ?? <span>{message}</span>}</div>
    </>
  );
};

const CheckoutForm = ({
  clientSecret,
  customSuccessCallback,
  externalFormCallback,
  externalDisableConditions,
  handleBackClick,
  isBackDisabled,
  nextStep,
  intentType,
}: CheckoutFormProps): ReactElement => {
  const options: StripeElementsOptions = {
    clientSecret,
    appearance: {
      theme: 'stripe',

      variables: {
        colorPrimary: '#F5A64F',
        colorBackground: '#FFFFFF',
        colorText: '#3C4858',
        colorDanger: '#DF1B41',
        fontFamily: 'Roobert Regular, sans-serif',
        fontSizeBase: '1rem',
        fontWeightLight: '300',
        fontWeightNormal: '400',
        fontWeightMedium: '500',
        fontWeightBold: '700',
        spacingUnit: '4px',
        borderRadius: '5px',
        spacingGridColumn: '15px',
      },
    },
    loader: 'always',
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentForm
        customSuccessCallback={customSuccessCallback}
        externalFormCallback={externalFormCallback}
        externalDisableConditions={externalDisableConditions}
        handleBackClick={handleBackClick}
        isBackDisabled={isBackDisabled}
        nextStep={nextStep}
        intentType={intentType}
      />
    </Elements>
  );
};

export default CheckoutForm;
