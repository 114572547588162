import { ReactElement, useContext, useEffect } from 'react';
import { Link, NavLink, Outlet } from 'react-router-dom';
import '../../../App.scss';
import AuthContext from '../../../store/context/auth/AuthContext';

import UserInfo from '../../dashboard/UserInfo';
import Spinner from '../../common/spinner/Spinner';
import { ZENDESK_KEY } from '../../../config';

const DashboardLayout = (): ReactElement => {
  // CONTEXT
  const { AuthFN, isAuthenticated, isLoading, userData } = useContext(AuthContext);

  useEffect(() => {
    if (ZENDESK_KEY) {
      const script = document.createElement('script');
      script.id = 'ze-snippet';
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && !isLoading && (userData.customer == null || userData.stripeUserData == null)) {
      AuthFN.verify();
    }
    // eslint-disable-next-line
  }, [isAuthenticated, userData, isLoading]);

  const routes = [
    { name: 'Home', path: '/dashboard/' },
    { name: 'Profile', path: '/dashboard/profile' },
    { name: 'Billing', path: '/dashboard/billing' },
    { name: 'Logout', path: '/logout' },
  ];

  return (
    <>
      <div className="profileCompleteModal"></div>
      <header className="dashboardHeader">
        <div className="dashboardWrapper">
          <div className="dashboardHeaderContent">
            <Link to="/dashboard/" className="dashboardLogo"></Link>

            <input type="checkbox" id="toggle" aria-hidden="true" />
            <label htmlFor="toggle" className="navToggleIcon" aria-hidden="true">
              Expand the menu
              <span className="toggleIconLines"></span>
              <span className="toggleIconLines"></span>
              <span className="toggleIconLines"></span>
            </label>

            <div className="dashboardNav">
              {routes.map(({ name, path }, index) => (
                <NavLink key={index} to={path} className={name}>
                  <i></i>
                  {name}
                </NavLink>
              ))}
            </div>
          </div>

          <UserInfo />
        </div>
      </header>
      <section className="dashboardContent">{!isLoading ? <Outlet></Outlet> : <Spinner />}</section>
    </>
  );
};

export default DashboardLayout;
