import React, { ReactElement, ReactNode } from 'react';
import Spinner from '../common/spinner/Spinner';

interface LoadingProps {
  loading: boolean;
  children?: ReactNode;
}

export const Loading = ({ loading, children }: LoadingProps): ReactElement => {
  return <>{loading ? <Spinner /> : children}</>;
};
