import React, { useEffect, useContext, ReactElement } from 'react';
import { Loading } from '../Loading';
import { Footer } from '../Footer';
import { GeneralContext } from '../../../store/context/GeneralState';

import { placeholders, SENDINBLUE_EVENTS } from '../../../utils';
import { SessionData, StepComponentProps } from '../../../interfaces';
import { WizardContext } from '../../../store/context/wizard/WizardState';
import { socket } from '../../../store/context/socket/SocketContext';

interface EnterFiltersProfileProps extends StepComponentProps {
  dataTemplate: Function;
  sessionId: string;
}

export const EnterFiltersProfile = ({
  dataTemplate,
  sessionId,
  actions,
  children,
}: EnterFiltersProfileProps): ReactElement => {
  const { sessionsFN, sendinblueFN, productsFN, isLoading } = useContext(GeneralContext);
  const { email, filterFields, setWizardState } = useContext(WizardContext);

  useEffect(() => {
    const data: { sessionId: string; sessionData: SessionData[] } = {
      sessionId,
      sessionData: [],
    };
    filterFields.forEach((filter: any, index: number) => {
      if (filter[`filter_${index + 1}_label`] !== '') {
        data.sessionData.push({ key: `filter_${index + 1}_label`, value: filter[`filter_${index + 1}_label`] });
      }
      if (filter[`filter_${index + 1}_x`] !== '') {
        data.sessionData.push({ key: `filter_${index + 1}_x`, value: filter[`filter_${index + 1}_x`] });
      }
      if (filter[`filter_${index + 1}_y`] !== '') {
        data.sessionData.push({ key: `filter_${index + 1}_y`, value: filter[`filter_${index + 1}_y`] });
      }
      if (filter[`filter_${index + 1}_z`] !== '') {
        data.sessionData.push({ key: `filter_${index + 1}_z`, value: filter[`filter_${index + 1}_z`] });
      }
    });
    sessionsFN.put(data);
    // eslint-disable-next-line
  }, [filterFields]);

  const setFilterFieldsData = (value: string, index: number, name: string) => {
    let data = [...filterFields];
    data[index][name] = value;

    setWizardState('filterFields', data);
  };

  const handleDepthInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    min: number,
    max: number,
  ) => {
    let newValue = event.target.value;
    if (newValue.length > 1) {
      newValue = newValue.slice(1, 2);
    }
    const value = newValue ? Math.max(min, Math.min(max, Number(newValue))) : '';
    setFilterFieldsData(value.toString(), index, event.target.name);
  };

  const handleFilterInputsChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setFilterFieldsData(event.target.value, index, event.target.name);
  };

  const handleAddFilter = () => {
    const newCount = filterFields.length + 1;
    // TODO: remove fkng index in keys wtf thogth that was a good idea (?)
    const newFilter = {
      [`filter_${newCount}_label`]: '',
      [`filter_${newCount}_x`]: '',
      [`filter_${newCount}_y`]: '',
      [`filter_${newCount}_z`]: '1',
      [`filter_${newCount}_quantity`]: '1',
    };
    setWizardState('filterFields', filterFields.concat([newFilter]));

    const data: { sessionId: string; sessionData: SessionData[] } = {
      sessionId,
      sessionData: [{ key: 'count', value: newCount.toString() }],
    };
    sessionsFN.put(data);
  };

  const handleRemoveFilter = (e: any, position: number) => {
    e.preventDefault();
    let newFilterFields = Object.assign([] as any[], filterFields);
    newFilterFields.splice(position - 1, 1);
    newFilterFields = newFilterFields.map((filter, index) => {
      if (index < position - 1) return filter;
      return {
        [`filter_${index + 1}_label`]: filter[`filter_${index + 2}_label`],
        [`filter_${index + 1}_x`]: filter[`filter_${index + 2}_x`],
        [`filter_${index + 1}_y`]: filter[`filter_${index + 2}_y`],
        [`filter_${index + 1}_z`]: filter[`filter_${index + 2}_z`],
        [`filter_${index + 1}_quantity`]: filter[`filter_${index + 2}_quantity`],
      };
    });
    setWizardState('filterFields', [...newFilterFields]);

    const data: { sessionId: string; sessionData: SessionData[] } = {
      sessionId,
      sessionData: [{ key: 'count', value: newFilterFields.length.toString() }],
    };
    sessionsFN.put(data);
  };

  const addProducts = () => {
    const userSelection = filterFields.filter(
      (f: any, index: number) =>
        f[`filter_${index + 1}_x`] !== '' && f[`filter_${index + 1}_y`] !== '' && f[`filter_${index + 1}_z`] !== '',
    );
    const variantNames = userSelection.map(
      (s: any, index: number) =>
        `${s[`filter_${index + 1}_x`]}x${s[`filter_${index + 1}_y`]}x${s[`filter_${index + 1}_z`]}`,
    );

    if (!userSelection.length) {
      actions.skipStep?.();
    } else {
      sessionsFN.put(dataTemplate('profile_completed', true));
      sessionsFN.put(dataTemplate('count', filterFields.length.toString()));
      sendinblueFN.updateContact({ email, attributes: { PROFILE_COMPLETED: true } });
      productsFN.getProducts(variantNames, actions.nextStep);
      socket.emit('send-event', {
        event: SENDINBLUE_EVENTS.added_filters,
        properties: {},
      });

      // GA events to mark selected filters
      variantNames.forEach((variantName) => {
        window.gtag('event', 'select_item', {
          item_list_id: 'subscription_filters',
          item_list_name: 'subscription filters',
          items: [
            {
              item_id: variantName,
            },
          ],
        });
      });
    }
  };

  useEffect(() => {
    const filtersInputs = document.querySelector(
      ".step[data-step='filters'] .input-row[data-filter='1'] .label-input",
    ) as HTMLElement;
    filtersInputs?.focus({
      preventScroll: true,
    });
  }, []);

  return (
    <div className="step" data-step="filters">
      <h3>Build your home’s filtration profile.</h3>
      <h5>This is literally the last time you will ever need to look-up the sizes of your home's filters.</h5>
      <h5>Type filter dimensions (width x length x depth) in inches.</h5>
      <Loading loading={isLoading}>
        <div>
          <div className="guide">
            <img src="https://cdn.shopify.com/s/files/1/0611/2120/0349/products/air-filter-01.png" alt="" />
            <div className="dim w">
              <span>W</span>
            </div>
            <div className="dim l">
              <span>L</span>
            </div>
            <div className="dim d">
              <span>D</span>
            </div>
          </div>
          <div className="filters-container">
            {filterFields.map((_value, index) => (
              <div key={index} className="input-row" data-filter={index + 1}>
                <div className="input label">
                  <input
                    name={`filter_${index + 1}_label`}
                    className="label-input simple-input"
                    type="text"
                    placeholder={placeholders[index]}
                    autoComplete="off"
                    autoCapitalize="words"
                    value={filterFields[index][`filter_${index + 1}_label`]}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterInputsChange(e, index)}
                  />
                </div>
                <div className="input dimensions">
                  <input
                    name={`filter_${index + 1}_x`}
                    className="search-box x-search-box"
                    type="number"
                    placeholder="W"
                    autoComplete="off"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={filterFields[index][`filter_${index + 1}_x`]}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterInputsChange(e, index)}
                  />
                  <span className="mdi mdi-close"></span>
                  <input
                    name={`filter_${index + 1}_y`}
                    className="search-box y-search-box"
                    type="number"
                    placeholder="L"
                    autoComplete="off"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={filterFields[index][`filter_${index + 1}_y`]}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterInputsChange(e, index)}
                  />
                  <span className="mdi mdi-close"></span>
                  <input
                    name={`filter_${index + 1}_z`}
                    className="search-box z-search-box"
                    type="number"
                    placeholder="D"
                    autoComplete="off"
                    maxLength={1}
                    value={filterFields[index][`filter_${index + 1}_z`]}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleDepthInputChange(e, index, 1, 4)}
                  />

                  {/* <input
                    name={`filter_${index + 1}_z`}
                    className="search-box z-search-box"
                    type="number"
                    placeholder="D"
                    autoComplete="off"
                    inputMode="numeric"
                    pattern="[1-4]*"
                    value={filterFields[index][`filter_${index + 1}_z`]}
                  /> */}
                  {/* TODO handle quantity per filter row */}
                  {/* <span className="mdi mdi-quantity"> - qty.</span>
                  <input
                    name={`filter_${index + 1}_quantity`}
                    className="search-box quantity-search-box"
                    type="number"
                    placeholder="L"
                    autoComplete="off"
                    inputMode="numeric"
                    min={1}
                    max={6}
                    value={filterFields[index][`filter_${index + 1}_quantity`]}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterInputsChange(e, index)}
                  /> */}
                  {index > 0 && (
                    <button
                      className="remove-filter-button"
                      onClick={(e: any) => handleRemoveFilter(e, index + 1)}
                      title="Remove filter"
                    >
                      <span className="mdi mdi-close"></span>
                    </button>
                  )}
                </div>
              </div>
            ))}
            <div className="add-filter-row">
              <button
                className="add-filter-button"
                disabled={filterFields.length >= 6}
                onClick={handleAddFilter}
                title={filterFields.length >= 6 ? 'Max of 6 filters per account' : 'Add more filters'}
              >
                <span className="mdi mdi-plus"></span>
              </button>
            </div>
          </div>
        </div>
        <Footer>
          {children({ customCallback: addProducts, nextDisabled: isLoading, backDisabled: isLoading })}
          <a
            href="#!"
            onClick={() => {
              setWizardState('filterSizeUnknown', true);
              actions.nextStep?.(true);
            }}
          >
            I don't know my filter size
          </a>
        </Footer>
      </Loading>
    </div>
  );
};
