import { useReducer, createContext } from 'react';

import WizardReducer from './WizardReducer';

import { SET_WIZARD_STATE } from '../../types';

/* Context to manage general storage */

type WizardContextKey = keyof IWizardContext;

interface IWizardContext {
  email: string;
  name: string;
  clientSurvey: { hvacOld: boolean, pets: boolean, odors: boolean, allergies: boolean };
  homeType: string;
  squareFootage: string;
  mervOne: string;
  mervTwo: string;
  old: string;
  smoke: string;
  viruses: string;
  allergens: string;
  pollution: string;
  petDander: string;
  count: number | string; // check for string
  filterFields: any[];
  addressLine1: string;
  addressLine2: string;
  addressState: string;
  addressCity: string;
  addressZipCode: string;
  phoneNumber: string;
  subscriptionInterval: number;
  premiumUpgrade: boolean;
  couponCode: string;
  requestedCode: string;
  ecoDelivery: boolean;
  optIn: boolean;
  filterSizeUnknown: boolean;
  scheduleFilterHelp: boolean;
  setWizardState(key: WizardContextKey, payload: any): void;
}

const defaultState: IWizardContext = {
  email: '',
  name: '',
  clientSurvey: { hvacOld: false, pets: false, odors: false, allergies: false },
  homeType: '',
  squareFootage: '',
  mervOne: '',
  mervTwo: '',
  old: '',
  smoke: '',
  viruses: '',
  allergens: '',
  pollution: '',
  petDander: '',
  count: '',
  filterFields: [{ filter_1_label: '', filter_1_x: '', filter_1_y: '', filter_1_z: '1', filter_1_quantity: '1' }],
  addressLine1: '',
  addressLine2: '',
  addressState: '',
  addressCity: '',
  addressZipCode: '',
  phoneNumber: '',
  subscriptionInterval: 2,
  premiumUpgrade: false,
  couponCode: '',
  requestedCode: '',
  ecoDelivery: false,
  optIn: true,
  filterSizeUnknown: false,
  scheduleFilterHelp: false,
  setWizardState: () => {},
};

export const WizardContext = createContext<IWizardContext>(defaultState);

const WizardState = (props: any) => {
  const initialState = defaultState;

  const [state, dispatch] = useReducer(WizardReducer, initialState);

  const setWizardState = (key: WizardContextKey, payload: any) => {
    dispatch({ type: SET_WIZARD_STATE, key, payload });
  };

  return (
    <WizardContext.Provider
      value={{
        email: state.email,
        name: state.name,
        clientSurvey: state.clientSurvey,
        homeType: state.homeType,
        squareFootage: state.squareFootage,
        mervOne: state.mervOne,
        mervTwo: state.mervTwo,
        old: state.old,
        smoke: state.smoke,
        viruses: state.viruses,
        allergens: state.allergens,
        pollution: state.pollution,
        petDander: state.petDander,
        count: state.count,
        filterFields: state.filterFields,
        addressLine1: state.addressLine1,
        addressLine2: state.addressLine2,
        addressState: state.addressState,
        addressCity: state.addressCity,
        addressZipCode: state.addressZipCode,
        phoneNumber: state.phoneNumber,
        subscriptionInterval: state.subscriptionInterval,
        premiumUpgrade: state.premiumUpgrade,
        couponCode: state.couponCode,
        requestedCode: state.requestedCode,
        ecoDelivery: state.ecoDelivery,
        optIn: state.optIn,
        filterSizeUnknown: state.filterSizeUnknown,
        scheduleFilterHelp: state.scheduleFilterHelp,
        setWizardState,
      }}
    >
      {props.children}
    </WizardContext.Provider>
  );
};

export default WizardState;
