import classNames from 'classnames';
import { FormikValues } from 'formik';
import { DataField } from '../../../interfaces';
import InputField from './InputField';
import SelectField from './SelectField';

interface FieldProps {
  inputField: DataField;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  value: FormikValues;
  errors: any;
}

const Field = ({ inputField, handleChange, handleBlur, value, errors = { isError: false } }: FieldProps) => {
  const { inputName, label, placeholder, type, options, disabled, renderComponent } = inputField;

  const fieldClassNames = classNames({
    editProfileCol: true,
    'field-valid': !errors.isError,
    'field-invalid': errors.isError,
  });

  return (
    <div className={fieldClassNames}>
      <label htmlFor={inputName} className="editProfileLabel">
        {label}
      </label>
      {renderComponent ? (
        renderComponent(inputField, handleBlur, value)
      ) : !options ? (
        <>
          <InputField
            inputName={inputName}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={placeholder}
            type={type}
            value={value}
            disabled={disabled}
          />
          {errors.isError && (
            <div className="nano-tooltip">
              <i></i>
              <span className="nano-tooltip-text leftTooltip redAccent">
                <p>{errors.error}</p>
              </span>
            </div>
          )}
        </>
      ) : (
        <SelectField
          inputName={inputName}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          options={options}
          value={value}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default Field;
