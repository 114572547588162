// API HEALTH
export const GET_HEALTH = 'GET_HEALTH';

// SESSIONS
export const LOADING = 'LOADING';
export const UPDATING_VERSION = 'UPDATING_VERSION';
export const PUT_SESSION_SUCCESS = 'PUT_SESSION_SUCCESS';
export const PUT_SESSION_ERROR = 'PUT_SESSION_ERROR';
export const POST_CHECK_EMAIL_LOADING = 'POST_CHECK_EMAIL_LOADING';
export const POST_CHECK_EMAIL_SUCCESS = 'POST_CHECK_EMAIL_SUCCESS';
export const POST_CHECK_EMAIL_ERROR = 'POST_CHECK_EMAIL_ERROR';
export const POST_USER_PROFILE_SUCCESS = 'POST_USER_PROFILE_SUCCESS';
export const POST_USER_PROFILE_ERROR = 'POST_USER_PROFILE_ERROR';
export const GET_ALL_VARIANTS_SUCCESS = 'GET_ALL_VARIANTS_SUCCESS';
export const GET_VARIANTS_SUCCESS = 'GET_VARIANTS_SUCCESS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_VARIANTS_ERROR = 'GET_VARIANTS_ERROR';
export const SET_VARIANTS = 'SET_VARIANTS';
export const SET_MERV = 'SET_MERV';
export const UPDATE_VARIANTS = 'UPDATE_VARIANTS';
export const GET_COUPON_SUCCESS = 'GET_COUPON_SUCCESS';
export const GET_COUPON_ERROR = 'GET_COUPON_ERROR';
export const SAVE_PROFILE = 'SAVE_PROFILE';
export const GET_REDIRECT_SUCCESS = 'GET_REDIRECT_SUCCESS';
export const GET_REDIRECT_ERROR = 'GET_REDIRECT_ERROR';

// SHOPIFY PRODUCTS
export const UPDATE_PRODUCT_VARIANT_SUCCESS = 'UPDATE_PRODUCT_VARIANT_SUCCESS';
export const UPDATE_PRODUCT_VARIANT_ERROR = 'UPDATE_PRODUCT_VARIANT_ERROR';
export const GET_UPGRADE_AVAILABLE = 'GET_UPGRADE_AVAILABLE';
export const GET_UPGRADE_AVAILABLE_ERROR = 'GET_UPGRADE_AVAILABLE_ERROR';
export const LOADING_UPGRADE_AVAILABLE = 'LOADING_UPGRADE_AVAILABLE';

// AUTHENTICATION
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';
export const RESET_ADDRESS_REQUEST_STATE = 'RESET_ADDRESS_REQUEST_STATE';
export const RESUMING_SUBSCRIPTION = 'RESUMING_SUBSCRIPTION';

// PAYMENTS
export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS';
export const GET_PAYMENT_ERROR = 'GET_PAYMENT_ERROR';
export const SEND_EMAIL = 'SEND_EMAIL';
export const POST_SUBSCRIPTION_SUCCESS = 'POST_SUBSCRIPTION_SUCCESS';
export const POST_SUBSCRIPTION_ERROR = 'POST_SUBSCRIPTION_ERROR';
export const POST_CHECKOUT_SESSION_SUCCESS = 'POST_CHECKOUT_SESSION_SUCCESS';
export const POST_CHECKOUT_SESSION_ERROR = 'POST_CHECKOUT_SESSION_ERROR';

// SENDINBLUE
export const CREATE_CONTACT_SENDINBLUE_SUCCESS = 'CREATE_CONTACT_SENDINBLUE_SUCCESS';
export const CREATE_CONTACT_SENDINBLUE_ERROR = 'CREATE_CONTACT_SENDINBLUE_ERROR';
export const UPDATE_CONTACT_SENDINBLUE_SUCCESS = 'UPDATE_CONTACT_SENDINBLUE_SUCCESS';
export const UPDATE_CONTACT_SENDINBLUE_ERROR = 'UPDATE_CONTACT_SENDINBLUE_ERROR';

// WIZARD
export const SET_WIZARD_STATE = 'SET_WIZARD_STATE';
export const GET_WIZARD_SUCCESS = 'GET_WIZARD_SUCCESS';
export const GET_WIZARD_ERROR = 'GET_WIZARD_ERROR';

// FILTERS
export const UPDATE_CUSTOMER_FILTERS_LOADING = 'UPDATE_CUSTOMER_FILTERS_LOADING';
export const UPDATE_CUSTOMER_FILTERS_SUCCESS = 'UPDATE_CUSTOMER_FILTERS_SUCCESS';
export const UPDATE_CUSTOMER_FILTERS_ERROR = 'UPDATE_CUSTOMER_FILTERS_ERROR';
