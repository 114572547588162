import React, { ReactElement, useContext } from 'react';
import { Loading } from '../Loading';
import { Footer } from '../Footer';
import { GeneralContext } from '../../../store/context/GeneralState';
import { WizardContext } from '../../../store/context/wizard/WizardState';
import { StepComponentProps } from '../../../interfaces';

interface EnterEmailProps extends StepComponentProps {
  validateEmail: Function;
}

export const EnterEmail = ({ actions, children }: EnterEmailProps): ReactElement => {
  const { isCheckEmailLoading, isLoading } = useContext(GeneralContext);
  const { email, optIn, setWizardState } = useContext(WizardContext);

  const addEmail = () => {
    actions.nextStep?.(email);
  };

  const onKeyUpEmail = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && event.target.value !== '') {
      addEmail();
    }
  };

  return (
    <div className="step" data-step="email">
      <h3>Please enter your email.</h3>
      <h5>Want cleaner air at home? Start building your custom profile.</h5>
      <Loading loading={isCheckEmailLoading || isLoading}>
        <>
          <div className="input">
            <input
              className="email-input simple-input"
              type="email"
              placeholder="Type your answer here..."
              autoComplete="off"
              pattern=".+@globex\.com"
              onKeyUp={onKeyUpEmail}
              value={email}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => setWizardState('email', e.target.value.toLowerCase())}
              onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                e.preventDefault();
                setWizardState('email', e.clipboardData.getData('Text').toLowerCase());
              }}
              autoFocus={true}
            />
          </div>
          <br />
          <label
            className={optIn ? 'agreeSmsDisclaimer selected' : 'agreeSmsDisclaimer'}
            onClick={() => setWizardState('optIn', !optIn)}
          >
            <i className="mdi mdi-checkbox-blank-outline first-line-icon"></i>
            <i className="mdi mdi-checkbox-marked first-line-icon"></i>
            <span>
              Opt-in to nano emails/SMS. <a href="https://www.nanohome.com/privacy">PRIVACY POLICY</a>
            </span>
          </label>
          <Footer>{children({ customCallback: addEmail, nextDisabled: !email || isLoading })}</Footer>
        </>
      </Loading>
    </div>
  );
};
