import { ReactElement } from 'react';

export enum WizardStepIdentifiers {
  EMAIL = 'EMAIL',
  NAME = 'NAME',
  FILTERS_PROFILE = 'FILTERS_PROFILE',
  FILTERS_PROFILE_WITH_DROPDOWN = 'FILTERS_PROFILE_WITH_DROPDOWN',
  MULTI_SELECT = 'MULTI_SELECT',
  PROFILE_SUMMARY = 'PROFILE_SUMMARY',
  STOCK_SELECTION = 'STOCK_SELECTION',
  ORDER = 'ORDER',
  HOME_TYPE = 'HOME_TYPE',
  SQUARE_FOOTAGE = 'SQUARE_FOOTAGE',
  ACSYSTEM_AGE = 'ACSYSTEM_AGE',
  CONCERNS = 'CONCERNS',
  CONFIRMATION = 'CONFIRMATION',
  SCHEDULE_FILTER_ASSITANCE = 'SCHEDULE_FILTER_ASSITANCE',
  SHIPPING = 'SHIPPING',
}

export interface StepComponentProps {
  actions: IActions;
  children: (props: any) => ReactElement;
}

export interface IActions {
  nextStep?: Function | undefined;
  backStep?: Function | undefined;
  skipStep?: Function | undefined;
}

export interface ISteps {
  id?: number;
  type: WizardStepIdentifiers;
  required: boolean;
  position: number;
  content?: {
    body?: any;
    props?: any;
    // title, description, etc.
  };
  actions?: IActions;
}

export interface IWizard {
  id: number;
  firstStep: WizardStepIdentifiers;
  steps: ISteps[];
  name: string;
}
