import React, { useEffect, useContext, ReactElement } from 'react';
import { Loading } from '../Loading';
import { Footer } from '../Footer';
import { GeneralContext } from '../../../store/context/GeneralState';
import { WizardContext } from '../../../store/context/wizard/WizardState';
import { StepComponentProps } from '../../../interfaces';
import { socket } from '../../../store/context/socket/SocketContext';
import { SENDINBLUE_EVENTS } from '../../../utils';

interface EnterKindOfHomeProps extends StepComponentProps {
  dataTemplate: Function;
  hideBackStep: boolean;
}

export const EnterKindOfHome = ({
  dataTemplate,
  hideBackStep = false,
  actions,
  children,
}: EnterKindOfHomeProps): ReactElement => {
  const { sessionsFN, isLoading } = useContext(GeneralContext);
  const { homeType, setWizardState } = useContext(WizardContext);

  useEffect(() => {
    if (homeType !== '') {
      sessionsFN.put(dataTemplate('home_type', homeType));
      socket.emit('send-event', {
        event: SENDINBLUE_EVENTS.added_home,
        properties: { HOME_TYPE: homeType },
      });
    }
    // eslint-disable-next-line
  }, [homeType]);

  const addHomeType = (element: Element) => {
    document
      .querySelectorAll(".step[data-step='home-type'] ul.radio li")
      .forEach((e) => e.classList.remove('selected'));
    element.classList.add('selected');
    setWizardState('homeType', element.getAttribute('data-value'));
  };

  const onKeyUpHomeType = (event: React.KeyboardEvent<HTMLLIElement>) => {
    if (event.key === 'Enter') {
      addHomeType(event.target as Element);
    }
  };

  const onClickHandler = (event: React.MouseEvent<HTMLLIElement>) => {
    addHomeType(event.target as Element);
  };

  return (
    <div className="step" data-step="home-type">
      <h3>What kind of home do you want to protect?</h3>
      <Loading loading={isLoading}>
        <ul className="radio radio-text">
          <li
            tabIndex={0}
            data-value="Single-Family House"
            className={homeType === 'Single-Family House' ? 'mdi selected' : 'mdi'}
            onKeyUp={onKeyUpHomeType}
            onClick={onClickHandler}
          >
            Single-Family House
          </li>
          <li
            tabIndex={0}
            data-value="Duplex"
            className={homeType === 'Duplex' ? 'mdi selected' : 'mdi'}
            onKeyUp={onKeyUpHomeType}
            onClick={onClickHandler}
          >
            Duplex
          </li>
          <li
            tabIndex={0}
            data-value="Condo / Apartment"
            className={homeType === 'Condo / Apartment' ? 'mdi selected' : 'mdi'}
            onKeyUp={onKeyUpHomeType}
            onClick={onClickHandler}
          >
            Condo / Apartment
          </li>
        </ul>
        <Footer>{children({ nextDisabled: !homeType || isLoading, backDisabled: hideBackStep })}</Footer>
      </Loading>
    </div>
  );
};
