import { ReactElement, useContext } from 'react';

import { Loading } from '../Loading';
import { GeneralContext } from '../../../store/context/GeneralState';
import { WizardContext } from '../../../store/context/wizard/WizardState';
import CheckoutForm from '../../checkout/CheckoutForm';
import Summary from '../../checkout/Summary';
import { calculateNewFrequencyPrice, SENDINBLUE_EVENTS, SUBSCRIPTION_INTERVALS } from '../../../utils';
import { SessionData, IActions, ShopifyProduct, ShopifVariant } from '../../../interfaces';
import { PhoneNumberInput } from '../../checkout/PhoneNumberInput';
import { socket } from '../../../store/context/socket/SocketContext';
import { PREMIUM_TIER_LABEL, REGULAR_TIER_LABEL } from '../../../config'

export interface OrderProps {
  dataTemplate: Function;
  sessionId: string;
  actions: IActions;
}

export const Order = ({ dataTemplate, sessionId, actions }: OrderProps): ReactElement => {
  const { isLoading, productsData, productsHelper, mervGrade, stripeClientSecret, intentType, sessionsFN, tax } = useContext(GeneralContext);

  const {
    subscriptionInterval,
    premiumUpgrade,
    ecoDelivery,
    phoneNumber,
    addressLine1,
    addressLine2,
    addressState,
    addressCity,
    addressZipCode,
  } = useContext(WizardContext);

  const addPhoneNumber = async () => {
    await sessionsFN.put(dataTemplate('user_phone_number', phoneNumber));
    socket.emit('send-event', {
      event: SENDINBLUE_EVENTS.added_phone,
      properties: { PHONE: phoneNumber },
    });
  };

  const addAddress = async () => {
    const sessionData: SessionData[] = [
      {
        key: 'user_address_line_1',
        value: addressLine1,
      },
      {
        key: 'user_address_line_2',
        value: addressLine2,
      },
      {
        key: 'user_address_city',
        value: addressCity,
      },
      {
        key: 'user_address_state',
        value: addressState,
      },
      {
        key: 'user_address_zip_code',
        value: addressZipCode,
      },
    ];
    await sessionsFN.put({
      sessionId,
      sessionData,
    });
    socket.emit('send-event', {
      event: SENDINBLUE_EVENTS.added_address,
      properties: {
        ADDRESS_LINE1: addressLine1,
        ADDRESS_LINE2: addressLine2,
        ADDRESS_CITY: addressCity,
        ADDRESS_STATE: addressState,
        ADDRESS_ZIP_CODE: addressZipCode,
      },
    });
  };

  const tierLabel = premiumUpgrade ? PREMIUM_TIER_LABEL : REGULAR_TIER_LABEL;

  const frequencyPrice = calculateNewFrequencyPrice(productsHelper.variantsTotal[tierLabel][mervGrade], subscriptionInterval, ecoDelivery);
  const externalFormCallback = async () => {
    await Promise.all([addPhoneNumber(), addAddress()]);
    await sessionsFN.postUserProfile(sessionId, null, true);
    window.gtag('event', 'purchase', {
      currency: 'USD',
      transaction_id: stripeClientSecret,
      value: frequencyPrice,
      items: productsData.map((product: ShopifyProduct) => {
        const selectedVariant = product.variants.find((variant: ShopifVariant) => variant.option1 === mervGrade && variant.option2 === tierLabel)
        return {
          item_id: selectedVariant?.id,
          item_name: product.title + selectedVariant?.title
        }
      }),
    });
  };

  return (
    <div className="step" data-step="confirm">
      <Summary
        amount={ frequencyPrice }
        tax={ (tax / 100) }
        filtersCount={ productsData.length }
        deliveryInterval={SUBSCRIPTION_INTERVALS[subscriptionInterval - 1].name}
        ecoDelivery={ecoDelivery}
      />
      <Loading loading={isLoading}>
        <PhoneNumberInput addPhoneNumber={addPhoneNumber} />

        <CheckoutForm
          clientSecret={stripeClientSecret}
          intentType={intentType}
          externalFormCallback={externalFormCallback}
          externalDisableConditions={
            !addressLine1 || !addressCity || !addressZipCode || addressZipCode.length < 5 || !addressState
          }
          handleBackClick={() => actions.backStep?.()}
          isBackDisabled={isLoading}
          nextStep={actions.nextStep}
        />
      </Loading>
    </div>
  );
};
