interface TextFieldProps {
  inputName?: string;
  label: string;
  value: any;
}

const TextField = ({ dataField }: { dataField: TextFieldProps }) => {
  const { inputName, label, value } = dataField;

  return (
    <div>
      <label htmlFor={inputName}>{label}</label>
      {value}
    </div>
  );
};

export default TextField;
