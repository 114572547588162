import React from 'react';
import ReactDOM from 'react-dom/client';
import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GeneralState from './store/context/GeneralState';
import AuthState from './store/context/auth/AuthState';
import WizardState from './store/context/wizard/WizardState';
import { ENV, SENTRY_DSN, SENTRY_SAMPLE_RATE, FULLSTORY_ORG_ID } from './config';
import NotFound from './components/layout/ErrorPage';
import { socket, SocketContext } from './store/context/socket/SocketContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (ENV === 'production' && SENTRY_DSN && SENTRY_DSN !== '') {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.APP_ENV || process.env.NODE_ENV,
    tracesSampleRate: SENTRY_SAMPLE_RATE ? parseFloat(SENTRY_SAMPLE_RATE) : 1.0,
  });
}

if (FULLSTORY_ORG_ID) FullStory.init({ orgId: FULLSTORY_ORG_ID, devMode: process.env.NODE_ENV !== 'production' });

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<NotFound errorType={500} />}>
      <SocketContext.Provider value={socket}>
        <GeneralState>
          <AuthState>
            <WizardState>
              <App />
            </WizardState>
          </AuthState>
        </GeneralState>
      </SocketContext.Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
