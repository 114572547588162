import { useState, useEffect, useContext, ReactElement } from 'react';
import * as FullStory from '@fullstory/browser';
import { useNavigate } from 'react-router-dom';
import { endOfToday, add, format } from 'date-fns';
import { every } from 'lodash';

import { GeneralContext } from '../../../store/context/GeneralState';
import AuthContext from '../../../store/context/auth/AuthContext';
import {
  STANDARD_IMAGE,
  PREMIUM_IMAGE,
  STANDARD_NAME,
  PREMIUM_NAME,
  ECO_DELIVERY_DISCOUNT,
} from '../../../config';

import Spinner from '../../common/spinner/Spinner';
import { carbonRegularPriceDiff, productTierLabel, SENDINBLUE_EVENTS, SUBSCRIPTION_INTERVALS } from '../../../utils';
import Summary from '../../checkout/Summary';
import CheckoutForm from '../../checkout/CheckoutForm';
import { PhoneNumberInput } from '../../checkout/PhoneNumberInput';
import { ShippingInfoInput } from '../../checkout/ShippingInfoInput';
import { socket } from '../../../store/context/socket/SocketContext';
import { WizardContext } from '../../../store/context/wizard/WizardState';
import { SessionData } from '../../../interfaces';
import { calculateNewFrequencyPrice } from '../../../utils';

const Activate = (): ReactElement => {
  const navigate = useNavigate();

  // CONTEXT
  const { addressLine1, addressLine2, addressState, addressCity, addressZipCode } = useContext(WizardContext);

  const {
    PaymentFN,
    productsFN,
    sessionsFN,
    isLoading: isLoadingGeneral,
    isLoadingUpgradeAvailable,
    productsData,
    productsHelper,
    mervGrade,
    checkoutSessionUrl,
    stripeClientSecret,
    tax,
  } = useContext(GeneralContext);

  const {
    AuthFN,
    userData: { customer },
    isLoading: isLoadingAuth,
    isAddressUpdated,
    ProfileFN,
  } = useContext(AuthContext);

  const { putUserProfile } = ProfileFN;

  // STATE
  const [subscriptionInterval, setSubscriptionInterval] = useState(2);
  const [premiumUpgrade, setPremiumUpgrade] = useState(false);
  const [ecoDelivery, setEcoDelivery] = useState(false);
  const [addressStep, setAddressStep] = useState(true);
  const [checkoutStep, setCheckoutStep] = useState(false);
  const [customerFiltersUpdated, setCustomerFiltersUpdated] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  // TODO: review this logic, maybe there's room for improvement
  useEffect(() => {
    if (customer) {
      const subscribed = customer.lastSubscription?.subscribedAt;
      const filters = customer.filters || [];
      const canCompleteAccount =
        !subscribed && filters?.length > 0 && every(filters, (filter) => !!filter.selectedVariantSize);

      if (!canCompleteAccount) navigate('/dashboard');
      const filterDimensions = customer.filters.filter((filter: any) => !!filter.filterId).map((filter: any) => filter.inputSize)
      productsFN.getProducts(filterDimensions);

      if (isAddressUpdated) {
        setAddressStep(false);
      }
      FullStory.identify(customer.originalSessionId);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (productsData.length === 1 && productsData[0].dimensions.split("x")[2] === "1") setEcoDelivery(true);
    // eslint-disable-next-line
  }, [productsData]);

  useEffect(() => {
    redirectToStripeCheckout();
    // eslint-disable-next-line
  }, [checkoutSessionUrl]);

  const redirectToStripeCheckout = async () => {
    if (checkoutSessionUrl) {
      window.location.href = checkoutSessionUrl;
    }
  };

  const tierLabel = productTierLabel(premiumUpgrade);
  const productsTotal = productsHelper.variantsTotal[tierLabel][mervGrade]

  const handleEcoDeliveryChange = () => {
    const ecoDeliverySet = !ecoDelivery;
    setEcoDelivery(ecoDeliverySet);
  };

  const handlePremiumUpgrade = () => {
    const premiumUpgradeSet = !premiumUpgrade;
    setPremiumUpgrade(premiumUpgradeSet);
  };

  const saveAddress = async () => {
    const sessionData: SessionData[] = [
      {
        key: 'user_address_line_1',
        value: addressLine1,
      },
      {
        key: 'user_address_line_2',
        value: addressLine2,
      },
      {
        key: 'user_address_city',
        value: addressCity,
      },
      {
        key: 'user_address_state',
        value: addressState,
      },
      {
        key: 'user_address_zip_code',
        value: addressZipCode,
      },
    ];

    sessionsFN.put({
      sessionId: customer.originalSession,
      sessionData,
    });
    socket.emit('send-event', {
      event: SENDINBLUE_EVENTS.added_address,
      properties: {
        ADDRESS_LINE1: addressLine1,
        ADDRESS_LINE2: addressLine2,
        ADDRESS_CITY: addressCity,
        ADDRESS_STATE: addressState,
        ADDRESS_ZIP_CODE: addressZipCode,
      },
    });

    const data = {
      address_line_1: addressLine1,
      address_line_2: addressLine2,
      city: addressCity,
      state: addressState,
      zip_code: addressZipCode,
      name: customer.user.name,
      phone: customer.phone,
      home_type: customer.homeType,
      home_square_footage: customer.homeSquareFootage,
    };

    await putUserProfile(customer.user.email, data);
    setAddressStep(false);
    setCheckoutStep(false);
  };

  const onOrderNow = async () => {
    registerFullStory();
    await productsFN.customerUpdateFilters(customer.id, customer.filters, premiumUpgrade);
    setCustomerFiltersUpdated(true);
  };

  useEffect(() => {
    if (customerFiltersUpdated) {
      PaymentFN.postSubscription(subscriptionInterval, customer.user.email, premiumUpgrade, ecoDelivery, mervGrade, true);
      setCheckoutStep(true);
    }
    // eslint-disable-next-line
  }, [customerFiltersUpdated]);

  const registerFullStory = () => {
    // FullStory.setUserVars({
    //   email,
    //   count,
    // });
  };

  const onAddressBackStep = () => {
    ProfileFN.resetAddressRequestState();
    setAddressStep(true);
    setCheckoutStep(false);
  };

  const selectFrequency = (frequency: number) => {
    document.querySelectorAll('.frequencyDataContainer').forEach((e) => e.classList.remove('selected'));
    document.getElementById(`frequencyMonth${frequency}`)?.classList.add('selected');

    setSubscriptionInterval(frequency);
  };

  const filtersCount = ecoDelivery ? 2 : productsData.length;

  const successCheckout = () => {
    AuthFN.verify();
  };

  const addPhoneNumber = async () => {
    socket.emit('send-event', {
      event: SENDINBLUE_EVENTS.added_phone,
      properties: { PHONE: phoneNumber },
    });
  };

  const externalFormCallback = async () => {
    window.gtag('event', 'purchase', {
      currency: 'USD',
      transaction_id: stripeClientSecret,
      value: calculateNewFrequencyPrice(productsTotal, subscriptionInterval, ecoDelivery),
    });
  };

  const safeCarbonRegularPriceDiff = productsData.length ? carbonRegularPriceDiff(productsHelper, mervGrade) : 0;

  return (
    <div className="wrapper">
      <div className="inner-wrapper">
        <div className="wizardContent">
          <div className="start table">
            <div className="middle">
              {addressStep && !checkoutStep && (
                <>
                  <h3>Shipping Address</h3>
                  <h5>Search for your address and select a result from the dropdown menu.</h5>
                  <ShippingInfoInput />
                  <div className="buttons-footer">
                    <button
                      className="button next"
                      disabled={
                        !addressLine1 ||
                        !addressCity ||
                        !addressZipCode ||
                        addressZipCode.length < 5 ||
                        !addressState ||
                        isLoadingUpgradeAvailable ||
                        isLoadingAuth ||
                        isLoadingGeneral ||
                        subscriptionInterval <= 0
                      }
                      onClick={saveAddress}
                    >
                      <span className="mdi mdi-loading mdi-spin"></span>Next
                    </button>
                  </div>
                </>
              )}

              {!checkoutStep && !addressStep && (
                <div className="step" data-step="confirm">
                  <h3>What we have in stock</h3>
                  <h5>
                    Our subscriptions are designed to send you filters when, and only when it's time to change them.
                  </h5>
                  <h5>
                    Considering your geographic location and the areas of concern you shared, we recommend changing your
                    filter(s) at least every 2 months.
                  </h5>
                  {isLoadingUpgradeAvailable || isLoadingAuth || isLoadingGeneral || checkoutSessionUrl ? (
                    <Spinner />
                  ) : (
                    <div>
                      <div className="stock">
                        <ul>
                          {productsData.map((product: any, index: number) => {
                            return (
                              <li key={index}>
                                <img src={premiumUpgrade ? PREMIUM_IMAGE : STANDARD_IMAGE} alt="" />
                                <div className="line-one">
                                  {premiumUpgrade ? PREMIUM_NAME : STANDARD_NAME}
                                  <span className="size">{product.dimensions}</span>
                                </div>
                                <div className="line-two">{customer.filters[index].label || `Filter #${index + 1}`}</div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="frequencySelector">
                        <div className="frequencyPlansContainer">
                          <div className="frequencyPlan">
                            <div className="frequencySelectorElement" onClick={(_e) => selectFrequency(3)}></div>
                            <div id="frequencyMonth3" className="frequencyDataContainer">
                              <div className="frequencyTitleContainer">
                                <h4>Clean</h4>
                              </div>
                              <p>{calculateNewFrequencyPrice(productsTotal, 3, ecoDelivery).toFixed(2)}</p>
                              <span>per month</span>
                              <h5>For those that change filters every 3 months.</h5>
                            </div>
                          </div>
                          <div className="frequencyPlan">
                            <div className="frequencyRibbon">BEST DEAL!</div>
                            <div className="frequencySelectorElement" onClick={(_e) => selectFrequency(2)}></div>
                            <div id="frequencyMonth2" className="frequencyDataContainer selected">
                              <div className="frequencyTitleContainer">
                                <h4>Cleaner</h4>
                              </div>
                              <p>{calculateNewFrequencyPrice(productsTotal, 2, ecoDelivery).toFixed(2)}</p>
                              <span>per month</span>
                              <h5>For those that change filters every 2 months.</h5>
                            </div>
                          </div>
                          <div className="frequencyPlan">
                            <div className="frequencySelectorElement" onClick={(_e) => selectFrequency(1)}></div>
                            <div id="frequencyMonth1" className="frequencyDataContainer">
                              <div className="frequencyTitleContainer ">
                                <h4>Cleanest</h4>
                              </div>
                              <p>{calculateNewFrequencyPrice(productsTotal, 1, ecoDelivery).toFixed(2)}</p>
                              <span>per month</span>
                              <h5>For those that change filters every month.</h5>
                            </div>
                          </div>
                        </div>
                    </div>
                      <div className="frequencyCopyContainer">
                        {productsData.length === 1 && productsData[0].dimensions.split("x")[2] === "1" && (
                          <div
                            className={ecoDelivery ? 'frequencyCopy selected' : 'frequencyCopy'}
                            onClick={handleEcoDeliveryChange}
                          >
                            <span className="mdi mdi-checkbox-blank-outline first-line-icon"></span>
                            <span className="mdi mdi-checkbox-marked first-line-icon"></span>
                            <p>
                              Save 
                              <b>${`${ (productsHelper.variantsTotal[productTierLabel(premiumUpgrade)][mervGrade] * ECO_DELIVERY_DISCOUNT / subscriptionInterval).toFixed(2) }`}</b> 
                              with "Eco Delivery".
                              <div className="nano-tooltip">
                                <i></i>
                                <span className="nano-tooltip-text topTooltip blueAccent">
                                  <p>
                                    <b>"Eco Delivery"</b> helps us be more environment friendly. The fewer trips we
                                    make to your home, the more we can save on boxes and gasoline.
                                  </p>
                                </span>
                              </div>
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="frequencyCopyContainer">
                        <div
                          className={premiumUpgrade ? 'frequencyCopy selected' : 'frequencyCopy'}
                          onClick={handlePremiumUpgrade}
                        >
                          <span className="mdi mdi-checkbox-blank-outline second-line-icon"></span>
                          <span className="mdi mdi-checkbox-marked second-line-icon"></span>
                          <p>
                            Upgrade your plan to <b>Carbon</b> for{' '}
                            <b>
                              ${ (safeCarbonRegularPriceDiff / subscriptionInterval).toFixed(2) }/month{' '}
                            </b>{' '}
                            to remove even more pollutans from your home.
                            <div className="nano-tooltip">
                              <i></i>
                              <span className="nano-tooltip-text topTooltip blueAccent">
                                <p>
                                  <b>"Carbon"</b> filters are best for removing smells in your air, they also help
                                  remove even more pollutans from your home.
                                </p>
                              </span>
                            </div>
                          </p>
                        </div>
                      </div>
                      <div className="frequencyShippingSchedule">
                        <h3>
                          Shipping Schedule <i>🗓</i>
                        </h3>
                        <div className="scheduleRow">
                          <span className="alignLeft">
                            {ecoDelivery
                              ? `${productsData.length * 2} Filters`
                              : productsData.length > 1
                              ? `${productsData.length} Filters`
                              : `${productsData.length} Filter`}
                          </span>
                          <span className="alignRight">Ships this week</span>
                        </div>
                        <div className="scheduleRow">
                          <span className="alignLeft">
                            {ecoDelivery
                              ? `${productsData.length * 2} Filters`
                              : productsData.length > 1
                              ? `${productsData.length} Filters`
                              : `${productsData.length} Filter`}
                          </span>
                          <span className="alignRight">
                            Ships{' '}
                            {format(
                              add(endOfToday(), {
                                months: ecoDelivery ? subscriptionInterval * 2 : subscriptionInterval,
                              }),
                              'MMMM yyyy',
                            )}
                          </span>
                        </div>
                        <div className="scheduleRow">
                          <span className="alignLeft">
                            {ecoDelivery
                              ? `${productsData.length * 2} Filters`
                              : productsData.length > 1
                              ? `${productsData.length} Filters`
                              : `${productsData.length} Filter`}
                          </span>
                          <span className="alignRight">
                            Ships{' '}
                            {format(
                              add(endOfToday(), {
                                months: ecoDelivery ? subscriptionInterval * 4 : subscriptionInterval * 2,
                              }),
                              'MMMM yyyy',
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="buttons-footer">
                    <button
                      className="button back"
                      disabled={
                        isLoadingUpgradeAvailable || isLoadingAuth || isLoadingGeneral || subscriptionInterval <= 0
                      }
                      onClick={onAddressBackStep}
                    >
                      <span className="mdi mdi-loading mdi-spin"></span>Back
                    </button>
                    <button
                      className="button next"
                      disabled={
                        isLoadingUpgradeAvailable || isLoadingAuth || isLoadingGeneral || subscriptionInterval <= 0
                      }
                      onClick={onOrderNow}
                    >
                      <span className="mdi mdi-loading mdi-spin"></span>Activate Subscription
                    </button>
                  </div>
                </div>
              )}
              {checkoutStep && (
                <div className="step" data-step="confirm">
                  {isLoadingGeneral || !stripeClientSecret ? (
                    <Spinner />
                  ) : (
                    <>
                      <Summary
                        amount={ calculateNewFrequencyPrice(productsTotal, subscriptionInterval, ecoDelivery) }
                        tax={ (tax / 100) }
                        filtersCount={filtersCount}
                        deliveryInterval={SUBSCRIPTION_INTERVALS[subscriptionInterval - 1].name}
                        ecoDelivery={ecoDelivery}
                      />
                      <PhoneNumberInput
                        addPhoneNumber={addPhoneNumber}
                        onChange={(phone: string) => setPhoneNumber(phone)}
                      />
                      <CheckoutForm
                        externalFormCallback={externalFormCallback}
                        clientSecret={stripeClientSecret}
                        intentType={"payment"}
                        customSuccessCallback={successCheckout}
                        handleBackClick={() => {
                          setCheckoutStep(false);
                          setAddressStep(false);
                          setCustomerFiltersUpdated(false);
                        }}
                        isBackDisabled={isLoadingGeneral}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activate;
