import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import classNames from 'classnames';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useContext, useEffect, useRef, useState } from 'react';
import AuthContext from '../../../store/context/auth/AuthContext';
import { SUBSCRIPTION_INTERVALS, calculateNextDeliveryDate } from '../../../utils';
import { PREMIUM_IMAGE, STANDARD_IMAGE } from '../../../config';
import { Reactivate } from './reactivate';
import { every } from 'lodash';

const Home = () => {
  const upgradeCardsEl = useRef<HTMLDivElement>(null);

  const settings = {
    arrows: true,
    dots: true,
    centerMode: true,
    centerPadding: '50px',
    cssEase: 'ease',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // CONTEXT
  const { userData } = useContext(AuthContext);
  const { customer, stripeUserData } = userData;

  const [upgradeCardsClassnames, setUpgradeCardsClassnames] = useState('upgradeOptionsBoxes');

  const subscribed = userData?.customer?.lastSubscription?.subscribedAt;
  const filters = userData?.customer?.filters || [];
  const calculateRedirection =
    filters.length > 0 && every(filters, (filter) => !!filter.selectedVariantSize) ? 'activate' : 'filters';

  useEffect(() => {
    if (upgradeCardsEl.current) {
      setUpgradeCardsClassnames(
        classNames({
          'upgradeOptionsBoxes-SingleCol': upgradeCardsEl?.current?.children.length === 1,
          upgradeOptionsBoxes: true,
        }),
      );
    } else {
      setUpgradeCardsClassnames('upgradeOptionsBoxes');
    }
  }, [upgradeCardsEl]);

  const checkOutOfStock = () => {
    return customer?.filters.find((filter: any) => filter.selectedVariantSize == null);
  };

  return (
    <>
      <div className="dashboardHomeContainer">
        <div className="topInfoContainer">
          <div className="topInfoLeftContent">
            <div className="topInfoContentTop">
              <div className="contentTopHeader grayAccent">
                <Link to="/dashboard/profile?edit=true" title="EDIT">
                  EDIT
                </Link>
              </div>
              <div className="contentTopContent">
                <div className="profileInformationBox">
                  <div className="profilePicture profilePicture-userIcon">
                    <i className="mdi mdi-account-circle"></i>
                  </div>
                  <h5>{customer?.user?.name?.capitalizeFirstCharacter()}</h5>
                  <div className="centeredInfo">
                    <div className="profileEmailAndLocation iconBox">
                      <div>
                        <p className="emailIcon">{customer?.user.email}</p>
                      </div>
                      <div>
                        <p className="locationIcon">
                          {customer?.city && customer?.state ? `${customer?.city}, ${customer?.state}` : '-'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="profileBottomInformation">
                  <div>
                    <i className="clockIcon"></i>
                    <span>Member Since</span>
                    <p>
                      {customer?.createdAt &&
                        Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(new Date(customer.createdAt))}
                    </p>
                  </div>
                  <div>
                    <i className="cardIcon"></i>
                    <span>Credit Card</span>
                    {stripeUserData && stripeUserData?.paymentMethods.length > 0 ? (
                      stripeUserData.paymentMethods.slice(0, 1).map((paymentMethod: any, index: number) => (
                        <p key={index}>
                          <span className="card-icon" data-brand={paymentMethod.card.brand}></span>
                          {`- ${paymentMethod.card.last4}`}
                        </p>
                      ))
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                  <div>
                    <img
                      src="https://cdn.shopify.com/s/files/1/0611/2120/0349/files/NANO-Dashboard-Home-Icon-Blue.svg?v=1654628816"
                      alt="NANO Blue Logo"
                    />
                    <span>Active Subscriptions</span>
                    <p>{!customer?.lastSubscription?.cancelled && customer?.lastSubscription?.subscribedAt ? 1 : 0}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="topInfoLeftContentBottom">
              {/* INVOICES COL. */}
              <div className="invoicesCol">
                <Link to="/dashboard/billing">
                  <div className="invoicesColContent">
                    <i className="mdi mdi-receipt"></i>
                    <p className="invoicesAnchor">
                      View
                      <br />
                      Invoices
                    </p>
                  </div>
                </Link>
              </div>

              {/* SUBSCRIPTION PLAN COL. */}
              <div className="subscriptionPlanCol">
                <Link to={`/dashboard/${calculateRedirection}`}>
                  <div className="subscriptionColContent">
                    <img
                      src="https://nano-home.github.io/nano/assets/brand/NANO-Dashboard-Home-Icon-Blue.svg"
                      alt="NANO Blue Logo"
                    />
                    <p className="subscriptionAnchor">
                      Current Plan:
                      <br />
                      <b>{SUBSCRIPTION_INTERVALS[customer?.lastSubscription?.interval - 1]?.plan || '-'}</b>
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="topInfoRightContent">
            <div ref={upgradeCardsEl} className={upgradeCardsClassnames}>
              {customer?.lastSubscription?.cancelled ? (
                <div className="cancelledSubscriptionCol">
                  <Reactivate />
                </div>
              ) : !customer?.lastSubscription?.subscribedAt ? (
                <div className="completeProfileCol">
                  <Link to={`/dashboard/${calculateRedirection}`}>
                    <div className="completeProfileColContent">
                      <i className="mdi mdi-account-circle"></i>
                      <p className="completeProfileAnchor">
                        <b>{customer?.user?.name?.capitalizeFirstCharacter()},</b> your profile
                        <br />
                        is almost complete!
                      </p>
                    </div>
                  </Link>
                </div>
              ) : (
                <>
                  {/* ECO-DELIVERY COL. */}
                  {!customer?.lastSubscription?.deliveryDiscount && customer?.filters?.length === 1 && (
                    <div className="ecoDeliveryCol">
                      <Link to="/dashboard/billing">
                        <div className="ecoDeliveryColContent">
                          <i className="mdi mdi-truck"></i>
                          <p className="ecoDeliveryAnchor">
                            Save money
                            <br />
                            with <b>eco-delivery!</b>
                          </p>
                        </div>
                      </Link>
                    </div>
                  )}

                  {/* UPGRADE TO CARBON COL. */}
                  {!customer?.lastSubscription?.premiumUpgrade && (
                    <div className="upgradeToCarbonCol">
                      <Link to="/dashboard/billing">
                        <div className="upgradeToCarbonColContent">
                          <i className="mdi mdi-star"></i>
                          <p className="upgradeToCarbonAnchor">
                            Upgrade
                            <br />
                            to <b>carbon!</b>
                          </p>
                        </div>
                      </Link>
                    </div>
                  )}
                </>
              )}
            </div>

            <div
              className={
                upgradeCardsEl.current && upgradeCardsEl?.current?.children.length > 0
                  ? 'topInfoRightContentTop'
                  : 'topInfoRightContentTop topInfoRightContentTop-FullHeight'
              }
            >
              <div className="contentTopHeader grayAccent">
                <div className="homeInformationBox">
                  <div className="homePicture">
                    <i className="mdi mdi-home"></i>
                  </div>
                  <div className="homeInfo iconBox">
                    {customer?.homeType ? <p className="homeIcon">{customer?.homeType}</p> : ''}
                    {customer?.homeSquareFootage ? (
                      <p className="squareIcon">{`${customer?.homeSquareFootage} sq. ft.`}</p>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
              <div className="contentTopContent scrollY">
                <div className="homeBottomInformation">
                  {customer?.filters &&
                  customer?.filters?.length > 0 &&
                  customer?.filters?.filter((filter: any) => !!filter.selectedVariantSize).length > 0 ? (
                    <>
                      <div style={{ display: 'flex' }}>
                        <label style={{ padding: '10px 20px 0px 20px', fontWeight: 400 }}>
                          For changes to your subscription please contact{' '}
                          <a href="mailto: support@nanohome.com">support@nanohome.com</a> or{' '}
                          <a href="tel:5126016969">512-601-6969</a>.
                        </label>
                      </div>

                      {checkOutOfStock() && (
                        <div className="homeFilterRow">
                          <span>
                            <b>
                              We currently don't have your filter(s) in stock, but we will let you know as soon as we
                              get it.
                            </b>
                          </span>
                        </div>
                      )}

                      {customer.filters.map((filter: any, index: number) => (
                        <div className="homeFilterRow" key={index}>
                          <div>
                            <img
                              src={customer?.lastSubscription?.premiumUpgrade ? PREMIUM_IMAGE : STANDARD_IMAGE}
                              alt="Filter Thumbnail Img."
                            />
                            <p>
                              {`FILTER #${index + 1}`}
                              <br />
                              <span>Size: {filter.selectedVariantSize || 'Out of stock'}</span>
                            </p>
                            {!subscribed && (
                              <Link to="/dashboard/filters" title="Edit">
                                EDIT
                              </Link>
                            )}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="noFilterSelected">
                      <p>
                        You have no filters selected yet.
                        <br />
                        Complete your profile <Link to="/dashboard/profile">here</Link>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="subscriptionRelatedBoxes">
          {/* COL #1 */}
          <div className="colBox">
            <div className="leftCol">
              <div className="leftContent">
                <span>Subscription Amount</span>
                <p>
                  {userData.stripeUserData?.invoices[0]
                    ? `$${Intl.NumberFormat('en-US').format(userData.stripeUserData?.invoices[0]?.amount_paid / 100)}`
                    : '-'}
                </p>
              </div>
            </div>
            <div className="rightCol">
              <i className="mdi mdi-currency-usd"></i>
            </div>
          </div>

          {/* COL #2 */}
          <div className="colBox">
            <div className="leftCol">
              <div className="leftContent">
                <span>Subscription Interval</span>
                <p>{SUBSCRIPTION_INTERVALS[customer?.lastSubscription?.interval - 1]?.name || '-'}</p>
              </div>
            </div>
            <div className="rightCol">
              <i className="mdi mdi-calendar-month"></i>
            </div>
          </div>

          {/* COL #3 */}
          <div className="colBox">
            <div className="leftCol">
              <div className="leftContent">
                <span>Next Shipment Day</span>
                <p>
                  {customer?.lastSubscription?.subscribedAt
                    ? calculateNextDeliveryDate(
                        customer.lastSubscription.subscribedAt,
                        customer.lastSubscription.interval,
                      )
                    : '-'}
                </p>
              </div>
            </div>
            <div className="rightCol">
              <i className="mdi mdi-truck-fast"></i>
            </div>
          </div>
        </div>

        {/* SLICK CAROUSEL */}
        <div className="sliderContanier">
          <Slider {...settings}>
            {/* SLIDE #1 */}
            <div className="slideCol">
              <div className="colBox">
                <div className="leftCol">
                  <div className="leftContent">
                    <span>Next Shipment Day</span>
                    <p>
                      {customer?.lastSubscription?.subscribedAt
                        ? calculateNextDeliveryDate(
                            customer.lastSubscription.subscribedAt,
                            customer.lastSubscription.interval,
                          )
                        : '-'}
                    </p>
                  </div>
                </div>
                <div className="rightCol">
                  <i className="mdi mdi-currency-usd"></i>
                </div>
              </div>
            </div>

            {/* SLIDE #2 */}
            <div className="slideCol">
              <div className="colBox">
                <div className="leftCol">
                  <div className="leftContent">
                    <span>Subscription Amount</span>
                    <p>
                      {userData.stripeUserData?.invoices[0]
                        ? `$${Intl.NumberFormat('en-US').format(
                            userData.stripeUserData?.invoices[0]?.amount_paid / 100,
                          )}`
                        : '-'}
                    </p>
                  </div>
                </div>
                <div className="rightCol">
                  <i className="mdi mdi-currency-usd"></i>
                </div>
              </div>
            </div>

            {/* SLIDE #3 */}
            <div className="slideCol">
              <div className="colBox">
                <div className="leftCol">
                  <div className="leftContent">
                    <span>Subscription Interval</span>
                    <p>{SUBSCRIPTION_INTERVALS[customer?.lastSubscription?.interval - 1]?.name || '-'}</p>
                  </div>
                </div>
                <div className="rightCol">
                  <i className="mdi mdi-calendar-month"></i>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Home;
