import { ReactElement, useContext, useEffect } from 'react';
import { Loading } from '../Loading';
import { GeneralContext } from '../../../store/context/GeneralState';

import { copyToClipboard, getShareLink } from '../../../utils';
import { WizardContext } from '../../../store/context/wizard/WizardState';
import { includes } from 'lodash';
import { useNavigate } from 'react-router-dom';

interface ConfirmationProps {
  isSkipped: boolean;
  isAdmin: boolean;
  sessionId: string;
}

export const Confirmation = ({ isSkipped, isAdmin, sessionId }: ConfirmationProps): ReactElement => {
  const { isLoading, productsData, createdUser, subscriptionSuccessUrl } = useContext(GeneralContext);
  const { email, filterFields, scheduleFilterHelp, filterSizeUnknown } = useContext(WizardContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (filterFields.length !== productsData.length) {
      localStorage.setItem('nano-user-email', email);
    }

    // eslint-disable-next-line
  }, [isLoading]);

  const handleGoToDashboard = async (url: string) => {
    includes(url, 'http') ? (window.location.href = url) : navigate(url);
  };

  return (
    <div className="step" data-step="confirm">
      <Loading loading={isLoading}>
        {scheduleFilterHelp ? (
          <>
            <h3>Thank you! A Nano professional will contact you soon.</h3>
            <div className="buttons-footer">
              <button className="button next" onClick={() => window.location.replace('http://nanohome.com/')}>
                Nano Home
              </button>
            </div>
          </>
        ) : productsData.length === 0 || isSkipped ? (
          <>
            <span className="emoji">🎉</span>
            <h3>{isSkipped ? 'Profile Complete' : 'Thanks for building your profile!'}</h3>
            <h5>
              {isSkipped
                ? "We're going to send you an email from beta@nanohome.com with a magic link for next steps. Keep an eye out, and thank you for joining the Nano Beta!"
                : 'You have been added to our beta list. We will contact you soon'}
            </h5>
          </>
        ) : (filterFields.length !== productsData.length) ? (
          <>
            <span className="emoji">🎉</span>
            <h3>Thanks for building your profile!</h3>
            {!filterSizeUnknown && (
              <h5>
                We currently don't have the size(s) of N95 level air filters that fit you. but we’re scaling up our
                production rapidly. We'll contact as soon as we have your filters in stock.
              </h5>
            )}
            <div className="buttons-footer">
              <button className="button next" onClick={() => window.location.replace('http://nanohome.com/')}>
                Nano Home
              </button>
              <button className="button next" onClick={() => handleGoToDashboard(createdUser.login)}>
                Go to Dashboard
              </button>
            </div>
          </>
        ) : isAdmin ? (
          <>
            <span className="emoji">🎉</span>
            <h3>Thanks for building the profile!</h3>
            <h5>
              Now you're ready to share this link to your client.
              <div className="input">
                <input type="text" className="simple-input" value={getShareLink(sessionId)} disabled />
              </div>
              <div className="buttons-footer">
                <button className="button" onClick={() => copyToClipboard(getShareLink(sessionId))}>
                  Copy to clipboard
                </button>
              </div>
            </h5>
          </>
        ) : subscriptionSuccessUrl ? (
          <>
            <span className="emoji">🎉</span>
            <h3>Thanks for building your profile!</h3>
            <h5>
              You can now proceed to the dashboard to review your profile & subscription with the button below, or you
              can log in at anytime with your email address.
            </h5>
            <div className="buttons-footer">
              <button className="button next" onClick={() => handleGoToDashboard(subscriptionSuccessUrl)}>
                Go to Dashboard
              </button>
            </div>
          </>
        ) : (
          <></>
        )}
      </Loading>
    </div>
  );
};
