import { initializeApp } from 'firebase/app';
import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, signOut } from 'firebase/auth';
import { firebaseConfig } from '../config';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

/* Obtains and refresh auth token */
export const refreshAuthToken = () =>
  new Promise((resolve, reject) => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        let token = await user.getIdToken();
        resolve(token);
      } else {
        reject(new Error('No user logged in'));
      }
    });
  });

/* Send login link to email */
export const sendLoginLink = (email) =>
  new Promise((resolve, reject) => {
    sendSignInLinkToEmail(auth, email, { url: window.location.href, handleCodeInApp: true })
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
  });

/* Verify if is singed in with email link */
export const isSignedInWithEmailLink = (linkURL) => isSignInWithEmailLink(auth, linkURL);

/* SignIn with email link */
export const signInWithLink = (email, linkURL) =>
  new Promise((resolve, reject) => {
    signInWithEmailLink(auth, email, linkURL)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });

/* Sign out */
export const signOutUser = () =>
  new Promise((resolve, reject) => {
    signOut(auth)
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
  });
