import { ReactElement, useContext } from 'react';
import { Loading } from '../Loading';
import { Footer } from '../Footer';
import { GeneralContext } from '../../../store/context/GeneralState';

import { SENDINBLUE_EVENTS } from '../../../utils';
import { SessionData, StepComponentProps } from '../../../interfaces';
import { WizardContext } from '../../../store/context/wizard/WizardState';
import { socket } from '../../../store/context/socket/SocketContext';
import { ShippingInfoInput } from '../../checkout/ShippingInfoInput';

interface EnterShippingInfoProps extends StepComponentProps {
  sessionId: string;
}

export const EnterShippingInfo = ({ sessionId, actions, children }: EnterShippingInfoProps): ReactElement => {
  const { sessionsFN, isLoading } = useContext(GeneralContext);

  const { addressLine1, addressLine2, addressState, addressCity, addressZipCode } = useContext(WizardContext);

  const addAddress = () => {
    const sessionData: SessionData[] = [
      {
        key: 'user_address_line_1',
        value: addressLine1,
      },
      {
        key: 'user_address_line_2',
        value: addressLine2,
      },
      {
        key: 'user_address_city',
        value: addressCity,
      },
      {
        key: 'user_address_state',
        value: addressState,
      },
      {
        key: 'user_address_zip_code',
        value: addressZipCode,
      },
    ];
    sessionsFN.put(
      {
        sessionId,
        sessionData,
      },
      actions.nextStep?.(),
    );
    socket.emit('send-event', {
      event: SENDINBLUE_EVENTS.added_address,
      properties: {
        ADDRESS_LINE1: addressLine1,
        ADDRESS_LINE2: addressLine2,
        ADDRESS_CITY: addressCity,
        ADDRESS_STATE: addressState,
        ADDRESS_ZIP_CODE: addressZipCode,
      },
    });
  };

  return (
    <div className="step" data-step="shipping-address">
      <h3>Shipping Address</h3>
      <h5>Search for your address and select a result from the dropdown menu.</h5>
      <Loading loading={isLoading}>
        <ShippingInfoInput />
        <Footer>
          {children({
            customCallback: addAddress,
            nextDisabled:
              isLoading ||
              !addressLine1 ||
              !addressCity ||
              !addressZipCode ||
              addressZipCode.length < 5 ||
              !addressState,
            backDisabled: isLoading,
          })}
        </Footer>
      </Loading>
    </div>
  );
};
