import React, { ReactElement, useContext } from 'react';
import { Loading } from '../Loading';
import { Footer } from '../Footer';
import { GeneralContext } from '../../../store/context/GeneralState';
import { StepComponentProps } from '../../../interfaces';
import { WizardContext } from '../../../store/context/wizard/WizardState';
import { socket } from '../../../store/context/socket/SocketContext';
import { SENDINBLUE_EVENTS } from '../../../utils';

export const EnterConcerns = ({ actions, children }: StepComponentProps): ReactElement => {
  const { isLoading } = useContext(GeneralContext);

  const { smoke, viruses, allergens, pollution, petDander, setWizardState } = useContext(WizardContext);

  const addConcerns = (element: Element) => {
    const value = element.getAttribute('data-value');

    element.classList.toggle('selected');
    const klass = element.classList.value;

    if (value === 'Smoke') {
      if (klass === 'mdi selected') {
        setWizardState('smoke', true);
      } else {
        setWizardState('smoke', false);
      }
    }

    if (value === 'Viruses') {
      if (klass === 'mdi selected') {
        setWizardState('viruses', true);
      } else {
        setWizardState('viruses', false);
      }
    }

    if (value === 'Allergens') {
      if (klass === 'mdi selected') {
        setWizardState('allergens', true);
      } else {
        setWizardState('allergens', false);
      }
    }

    if (value === 'Pollution') {
      if (klass === 'mdi selected') {
        setWizardState('pollution', true);
      } else {
        setWizardState('pollution', false);
      }
    }

    if (value === 'Pet Dander') {
      if (klass === 'mdi selected') {
        setWizardState('petDander', true);
      } else {
        setWizardState('petDander', false);
      }
    }
  };

  const onKeyUpAddConcerns = (event: React.KeyboardEvent<HTMLLIElement>) => {
    if (event.key === 'Enter') {
      addConcerns(event.target);
    }
  };

  const onClickHandler = (event: React.MouseEvent<HTMLLIElement>) => {
    addConcerns(event.target as Element);
  };

  return (
    <div className="step" data-step="concerns">
      <h3>Which of these are you most concerned about?</h3>
      <Loading loading={isLoading}>
        <ul className="radio checkboxes">
          <li
            tabIndex={0}
            data-value="Pet Dander"
            className={petDander ? 'mdi selected' : 'mdi'}
            onClick={onClickHandler}
            onKeyUp={onKeyUpAddConcerns}
          >
            Pet dander
          </li>
          <li
            tabIndex={0}
            data-value="Allergens"
            className={allergens ? 'mdi selected' : 'mdi'}
            onClick={onClickHandler}
            onKeyUp={onKeyUpAddConcerns}
          >
            Allergens
          </li>
          <li
            tabIndex={0}
            data-value="Viruses"
            className={viruses ? 'mdi selected' : 'mdi'}
            onClick={onClickHandler}
            onKeyUp={onKeyUpAddConcerns}
          >
            Viruses (Cold, Flu, COVID)
          </li>
          <li
            tabIndex={0}
            data-value="Smoke"
            className={smoke ? 'mdi selected' : 'mdi'}
            onClick={onClickHandler}
            onKeyUp={onKeyUpAddConcerns}
          >
            Wildfire smoke
          </li>
          <li
            tabIndex={0}
            data-value="Pollution"
            className={pollution ? 'mdi selected' : 'mdi'}
            onClick={onClickHandler}
            onKeyUp={onKeyUpAddConcerns}
          >
            Pollution/Smog
          </li>
        </ul>
        <Footer>
          {children({
            customCallback: () => {
              socket.emit('send-event', { event: SENDINBLUE_EVENTS.added_concerns, properties: {} });
              actions.nextStep?.();
            },
            nextDisabled: !(smoke || viruses || allergens || petDander || pollution || isLoading),
            backDisabled: isLoading,
          })}
        </Footer>
      </Loading>
    </div>
  );
};
