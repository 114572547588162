import { ChangeEventHandler, Fragment, ReactElement, useContext } from 'react';
import { FormikErrors, FormikTouched, FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { HOME_TYPES } from '../../utils';
import Field from '../common/forms/Field';
import AuthContext from '../../store/context/auth/AuthContext';
import InputPlaces from '../common/forms/InputPlaces';
import { DataField } from '../../interfaces';

interface UpdateProfileProps {
  setIsUpdating: Function;
  userData: any;
}

const UpdateProfile = ({ setIsUpdating, userData }: UpdateProfileProps): ReactElement => {
  const navigate = useNavigate();

  // CONTEXT
  const { ProfileFN } = useContext(AuthContext);
  const { putUserProfile } = ProfileFN;

  const formik = useFormik({
    initialValues: {
      name: userData?.customer?.user.name?.capitalizeFirstCharacter(),
      email: userData?.customer?.user.email,
      home_type: userData?.customer?.homeType,
      home_square_footage: userData?.customer?.homeSquareFootage,
      phone: userData?.customer?.phone,
      address_line_1: userData?.customer?.activeAddress?.addressLine1,
      address_line_2: userData?.customer?.activeAddress?.addressLine2,
      city: userData?.customer?.activeAddress?.city,
      state: userData?.customer?.activeAddress?.state,
      zip_code: userData?.customer?.activeAddress?.zipCode,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[a-zA-Z\s]*$/, 'Invalid name')
        .required('Name is required'),
      home_square_footage: Yup.number().integer('Invalid number').positive('Invalid number'),
      phone: Yup.number()
        .positive('Invalid phone number')
        .max(9999999999, 'Invalid phone number')
        .integer('Invalid phone number'),
      address_line_1: Yup.string().required('Address line 1 is required'),
      city: Yup.string().required('City is required'),
      state: Yup.string().required('State is required'),
      zip_code: Yup.number().required('Zip code is required'),
    }),
    onSubmit: (data: any) => {
      if (formik.initialValues !== formik.values) {
        setIsUpdating(false);

        delete data.email;
        data.auth_token = localStorage.getItem('nano-user-auth-token');
        putUserProfile(userData.customer.user.email, data);
      }
    },
  });

  const updateAddress = (data: any) => {
    formik.handleChange({ target: data });
  };

  const cancelUpdate = () => {
    setIsUpdating(false);
    navigate('/dashboard/profile', { replace: true });
  };
  const dataFields: DataField[] = [
    {
      inputName: 'name',
      label: 'Name',
      placeholder: 'Type your fullname here',
      type: 'text',
      disabled: false,
    },
    {
      inputName: 'phone',
      label: 'Phone',
      placeholder: 'Type your phone number here',
      type: 'tel',
      disabled: false,
    },
    {
      inputName: 'email',
      label: 'Email',
      placeholder: 'Type your email here',
      type: 'email',
      disabled: true,
    },
    {
      inputName: 'address_line_1',
      label: 'Address 1',
      placeholder: 'Type your address here',
      type: 'text',
      disabled: false,
      renderComponent: (inputField: DataField, handleBlur: ChangeEventHandler<HTMLInputElement>, value: any) => (
        <InputPlaces inputField={inputField} updateAddress={updateAddress} value={value} onBlur={handleBlur} />
      ),
    },
    {
      inputName: 'address_line_2',
      label: 'Address 2',
      placeholder: 'Type your address here',
      type: 'text',
      disabled: false,
    },
    {
      inputName: 'city',
      label: 'City',
      placeholder: 'Type your city here',
      type: 'text',
      disabled: false,
    },
    {
      inputName: 'state',
      label: 'State',
      placeholder: 'Type your state here',
      type: 'text',
      disabled: false,
    },
    {
      inputName: 'zip_code',
      label: 'Zip Code',
      placeholder: 'Type your zip code here',
      type: 'text',
      disabled: false,
    },
    {
      inputName: 'home_type',
      label: 'Home Type',
      placeholder: 'Choose your home type',
      type: 'select',
      options: HOME_TYPES,
      disabled: false,
    },
    {
      inputName: 'home_square_footage',
      label: 'Square Footage',
      placeholder: 'Type your square footage here',
      type: 'number',
      disabled: false,
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form-group editProfileView">
        {dataFields.map((inputField: DataField, index) => (
          <Fragment key={index}>
            <Field
              inputField={inputField}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              value={(formik.values as FormikValues)[inputField.inputName]}
              errors={{
                isError:
                  (formik.touched as FormikTouched<FormikValues>)[inputField.inputName] &&
                  (formik.errors as FormikErrors<FormikValues>)[inputField.inputName],
                error: (formik.errors as FormikErrors<FormikValues>)[inputField.inputName],
              }}
            />
          </Fragment>
        ))}
      </div>
      <div className="editProfileActions">
        <button className="button cancel cancelChangesBtn" onClick={cancelUpdate}>
          Cancel
        </button>
        <button
          className="saveChangesBtn"
          type="submit"
          disabled={formik.initialValues === formik.values || !formik.isValid}
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default UpdateProfile;
