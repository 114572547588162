/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState } from 'react';
import AuthContext from '../../../store/context/auth/AuthContext';
import { SUBSCRIPTION_INTERVALS } from '../../../utils';
import Spinner from '../../common/spinner/Spinner';
import { Portal } from '../../common/portal';
import httpClient from '../../../services/httpClient.service';
import { useNavigate } from 'react-router-dom';

export const Reactivate = () => {
  const { AuthFN, ProfileFN, resumingSubscription } = useContext(AuthContext);
  const { userData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { id, user, filters, lastSubscription: subscription } = userData.customer;
  const { paymentMethods, invoices } = userData.stripeUserData;

  const handleCloseModal = () => {
    ProfileFN.setResumingSubscription(false);
    localStorage.setItem('profile-modal-closed', 'true');
    setShowModal(false);
  };

  const handleChangeFilters = () => {
    handleCloseModal();
    navigate('/dashboard/filters');
  };

  const handleReactivate = async () => {
    try {
      setIsLoading(true);
      const response = await httpClient().get(`customers/${id}/resume-subscription`);
      if (response) {
        ProfileFN.setResumingSubscription(true);

        // Wait for customer.lastSubscription.subscribedAt to be set by Stripe webhook
        await setTimeout(() => {
          AuthFN.verify();
          handleCloseModal();
        }, 3000);
      }
    } catch (error) {
      console.error(`Error reactivating subscription for ${id} customer`, error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <a href="#" onClick={() => setShowModal(true)}>
        <div className="cancelledSubscriptionColContent">
          <i className="mdi mdi-close-circle"></i>
          <p className="cancelledSubscriptionAnchor">
            <b>{user?.name?.capitalizeFirstCharacter()},</b> your subscription
            <br />
            is cancelled. Reactivate it anytime.
          </p>
        </div>
      </a>

      {invoices.length > 0 && filters.length > 0 && paymentMethods.length && (
        <Portal onClose={handleCloseModal} show={showModal}>
          {isLoading && (
            <div className="loading-container">
              <Spinner />
            </div>
          )}
          <div className="reactivationModal">
            <h3>Reactivate Subscription</h3>
            {resumingSubscription ? (
              <>Verifying subscription, please wait...</>
            ) : (
              <>
                <section className="reactivationSubscriptionsDetails">
                  <div className="amountCol">
                    <i className="mdi mdi-currency-usd"></i>
                    <p>
                      <b>Total</b>
                      <br />${Intl.NumberFormat('en-US').format(invoices[0].amount_paid / 100)}
                    </p>
                  </div>
                  <div className="filtersCol">
                    <i className="mdi mdi-rhombus-split-outline"></i>
                    <p>
                      <b>Filters</b>
                      <br />
                      {filters.length}
                    </p>
                  </div>
                  <div className="intervalCol">
                    <i className="mdi mdi-truck-fast"></i>
                    <p>
                      <b>Delivery Schedule</b>
                      <br />
                      {SUBSCRIPTION_INTERVALS[subscription.interval - 1]?.name || '-'}
                    </p>
                  </div>
                  <div className="paymentCol">
                    <i className="mdi mdi-credit-card"></i>
                    <p>
                      <b>Payment Details</b>
                      <br />
                      <span>
                        <span className="card-icon" data-brand={paymentMethods[0].card.brand}></span>
                        {`- ${paymentMethods[0].card.last4}`}
                      </span>
                    </p>
                  </div>
                </section>
                <section className="reactivationFiltersDetails">
                  <h4>Filter Details</h4>
                  <ul>
                    {filters.map((filter: any, index: any) => (
                      <li key={index}>{`(${index + 1}) - (${filter.label} : ${filter.selectedVariantSize})`}</li>
                    ))}
                  </ul>
                </section>
                <section className="reactivationActions">
                  <span>
                    By confirming the changes fresh subscription cycle will start inmediately and a new filters will be
                    delivered
                  </span>
                  <button className="cancelBtn" disabled={isLoading} onClick={handleCloseModal}>
                    Cancel
                  </button>
                  <button className="changeFiltersBtn" disabled={isLoading} onClick={handleChangeFilters}>
                    Change Filters
                  </button>
                  <button className="confirmBtn" disabled={isLoading} onClick={handleReactivate}>
                    Confirm
                  </button>
                </section>
              </>
            )}
          </div>
        </Portal>
      )}
    </>
  );
};
