import { ReactElement, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../store/context/auth/AuthContext';

const PrivateRoute = ({ children }: React.PropsWithChildren): ReactElement => {
  // CONTEXT
  const { isAuthenticated } = useContext(AuthContext);

  return isAuthenticated ? <>{children}</> : <Navigate to="/login" />;
};

export default PrivateRoute;
