import { ReactElement, useContext, useState } from 'react';
import { Loading } from '../Loading';
import { Footer } from '../Footer';
import { GeneralContext } from '../../../store/context/GeneralState';
import { WizardContext } from '../../../store/context/wizard/WizardState';
import { getParams } from '../../../utils';
import { StepComponentProps } from '../../../interfaces';

interface ProfileSummaryProps extends StepComponentProps {
  isSkipped: boolean;
  sessionId: string;
  dataTemplate: Function;
}

export const ProfileSummary = ({
  actions,
  isSkipped,
  sessionId,
  dataTemplate,
  children,
}: ProfileSummaryProps): ReactElement => {
  const { isLoading, productsData, sessionsFN, sendinblueFN } = useContext(GeneralContext);

  const {
    email,
    filterFields,
    name,
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressZipCode,
    homeType,
    squareFootage,
    mervOne,
    old,
    scheduleFilterHelp,
    filterSizeUnknown,
  } = useContext(WizardContext);

  const [queryParams] = useState(getParams());

  const saveProfile = () => {
    if (queryParams.some(({ key }) => key === 'admin')) {
      sessionsFN.postUserProfile(sessionId, actions.nextStep);
      return;
    }

    if (!productsData.length || isSkipped) {
      sessionsFN.postUserProfile(sessionId, actions.nextStep);
    } else {
      sessionsFN.put(dataTemplate('profile_completed', true), () =>
        sessionsFN.postUserProfile(sessionId, actions.nextStep),
      );
      sendinblueFN.updateContact({ email, attributes: { PROFILE_COMPLETED: true } });
      // GA event to record profile sign up
      window.gtag('event', 'sign_up', {
        method: 'wizard',
      });
    }
  };

  return (
    <div className="step" data-step="profile-set">
      <h3>Please confirm your selection</h3>
      <Loading loading={isLoading}>
        <div className="selections">
          <ul className="profile">
            {name !== '' && (
              <li>
                <span className="label">Name</span>
                <span className="dimensions">{name}</span>
              </li>
            )}
            <li>
              <span className="label">Email</span>
              <span className="dimensions">{email}</span>
            </li>
            {addressLine1 !== '' && addressCity !== '' && addressState !== '' && addressZipCode !== '' && (
              <>
                <li>
                  <span className="label">Address</span>
                  <span className="dimensions">
                    {addressLine1} {addressLine2 && ` - ${addressLine2}`}
                  </span>
                </li>
                <li>
                  <span className="label">City</span>
                  <span className="dimensions">
                    {addressCity}, {addressState} {addressZipCode}
                  </span>
                </li>
              </>
            )}
            {homeType && (
              <li>
                <span className="label">Home Type</span>
                <span className="dimensions">{homeType}</span>
              </li>
            )}
            {squareFootage && (
              <li>
                <span className="label">Square Footage</span>
                <span className="dimensions">{squareFootage}</span>
              </li>
            )}
            {mervOne && (
              <li>
                <span className="label">MERV Rating</span>
                <span className="dimensions">{mervOne}</span>
              </li>
            )}
            {old && (
              <li>
                <span className="label">Old System</span>
                <span className="dimensions">{old}</span>
              </li>
            )}
            {filterSizeUnknown && (
              <li>
                <span className="label">Needs help with filters?</span>
                <span className="dimensions">{scheduleFilterHelp ? 'Yes' : 'No'}</span>
              </li>
            )}
          </ul>
          <ul className="filter-list">
            {filterFields.map(
              (filter: any, index: number) =>
                filter[`filter_${index + 1}_x`] &&
                filter[`filter_${index + 1}_y`] &&
                filter[`filter_${index + 1}_z`] && (
                  <li key={index}>
                    <span className="label">{filter[`filter_${index + 1}_label`] || `Filter #${index + 1}`}</span>
                    <span className="dimensions">{`${filter[`filter_${index + 1}_x`]}x${
                      filter[`filter_${index + 1}_y`]
                    }x${filter[`filter_${index + 1}_z`]}`}</span>
                  </li>
                ),
            )}
          </ul>
        </div>
        <Footer>{children({ customCallback: saveProfile, nextDisabled: isLoading, backDisabled: isLoading })}</Footer>
      </Loading>
    </div>
  );
};
