import React, { ReactElement } from 'react';

interface BackButtonProps {
  disabled?: boolean;
  onClick: React.MouseEventHandler;
}

export const BackButton = ({ disabled, onClick }: BackButtonProps): ReactElement => {
  return (
    <button className="button back" onClick={onClick} disabled={disabled}>
      Back
    </button>
  );
};
