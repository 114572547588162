import { ReactElement, ReactNode } from 'react';

interface CardProps {
  title: string;
  children: ReactNode;
}
const Card = ({ title, children }: CardProps): ReactElement => {
  return (
    <div className="dashboard-block">
      <div className="title">{title}</div>
      {children}
    </div>
  );
};

export default Card;
