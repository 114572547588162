import { ReactElement } from 'react';

interface InvoicesProps {
  status: any;
  date: any;
  amountPaid: number;
  invoiceURL: string;
}
const Invoices = ({ status, date, amountPaid, invoiceURL }: InvoicesProps): ReactElement => {
  return (
    <li data-invoice-id="in_1Kr6S1H4YyfnZ4k0nUQnMbkV">
      <a href={invoiceURL} target="_blank" rel="noopener noreferrer">
        <span className="status" data-status={status}>
          {status}
        </span>
        <span className="date">{Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(date * 1000)}</span>
        <span className="amount-paid">{`$${Intl.NumberFormat('en-US').format(amountPaid / 100)}`}</span>
      </a>
    </li>
  );
};

export default Invoices;
