import { ReactElement, useContext, useState } from "react";
import { StepComponentProps } from "../../../interfaces";
import { GeneralContext } from "../../../store/context/GeneralState";
import { WizardContext } from "../../../store/context/wizard/WizardState";
import { Footer } from "../Footer";
import { SelectableContainerSmall } from "./SelectableContainerSmall";

interface MultiSelectProps extends StepComponentProps {
  sessionId: string;
  dataTemplate: Function;
}

export const MultiSelect = ({ sessionId, dataTemplate, actions, children }: MultiSelectProps): ReactElement => {
  const { sessionsFN } = useContext(GeneralContext)
  const { setWizardState, clientSurvey } = useContext(WizardContext);

  const [hvac, setHvac] = useState(clientSurvey.hvacOld);
  const [pets, setPets] = useState(clientSurvey.pets);
  const [odors, setOdors] = useState(clientSurvey.odors);
  const [allergies, setAllergies] = useState(clientSurvey.allergies);

  const setSelections = () => {
    actions.nextStep?.();
    setWizardState("clientSurvey", { hvacOld: hvac, pets, odors, allergies });
    const sessionData = [
      { key: "survey_hvac_old", value: hvac },
      { key: "survey_pets", value: pets },
      { key: "survey_odors", value: odors },
      { key: "survey_allergies", value: allergies },
    ]
    sessionsFN.put({ sessionId, sessionData })
  };

  return (
    <div className="step" data-step="multi-select">
      <h3>Select all that apply</h3>
      <h5>This will help us determine what filters are best for you.</h5>
      <div className="multi-select">
        <div className="grid">
          <SelectableContainerSmall className={ hvac ? "selected" : "" } title="HVAC System" onClick={ () => setHvac(!hvac) }>
            <span>Older than 2010</span>
          </SelectableContainerSmall>
          <SelectableContainerSmall className={ pets ? "selected" : "" } title="I have pets" onClick={ () => setPets(!pets) }>
            <div className={`mask paw ${ pets ? "selected" : "" }`}>
              <div className="filler"></div>
            </div>
          </SelectableContainerSmall>
          <SelectableContainerSmall className={ odors ? "selected" : "" } title="I want to remove odors" onClick={ () => setOdors(!odors) }>
            <div className={`mask odors ${ odors ? "selected" : "" }`}>
              <div className="filler"></div>
            </div>
          </SelectableContainerSmall>
          <SelectableContainerSmall className={ allergies ? "selected" : "" } title="I have allergies" onClick={ () => setAllergies(!allergies) }>
            <div className={`mask allergies ${ allergies ? "selected" : "" }`}>
              <div className="filler"></div>
            </div>
          </SelectableContainerSmall>
        </div>
      </div>
      <Footer>{ children({ customCallback: setSelections }) }</Footer>
    </div>
  );
};
