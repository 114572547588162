import React, { ReactElement } from 'react';

interface NextButtonProps {
  disabled?: boolean;
  onClick: React.MouseEventHandler;
  customSpan?: boolean;
  customText?: string;
}

export const NextButton = ({ disabled, onClick, customSpan, customText }: NextButtonProps): ReactElement => {
  return (
    <button className="button next" disabled={disabled} onClick={onClick}>
      {customSpan && <span className="mdi mdi-loading mdi-spin"></span>}
      {customText ?? 'Next'}
    </button>
  );
};
