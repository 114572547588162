import { ReactElement } from 'react';

interface PaymentCardProps {
  brand: string;
  last4: string;
  exp_month: string;
  exp_year: string;
}
const PaymentCard = ({ brand, last4, exp_month, exp_year }: PaymentCardProps): ReactElement => {
  return (
    <div className="current-card">
      <p>
        <span className="card-icon" data-brand={brand}></span>
        {`•••• ${last4}`}
        <span style={{ display: 'inline-block', margin: '0 0 0 20px' }}></span>
        {`(${exp_month}/${exp_year})`}
      </p>
    </div>
  );
};

export default PaymentCard;
