import { useReducer, createContext } from 'react';

import GeneralReducer from './GeneralReducer';

import httpClient from '../../services/httpClient.service';
import { ENV, STANDARD_PRODUCT_ID, PREMIUM_PRODUCT_ID, PRODUCT_TIER_LABELS, SUPPORTED_MERV_VARIANTS, } from '../../config';

import {
  LOADING,
  LOADING_UPGRADE_AVAILABLE,
  GET_UPGRADE_AVAILABLE,
  GET_UPGRADE_AVAILABLE_ERROR,
  GET_PRODUCTS_SUCCESS,
  GET_ALL_VARIANTS_SUCCESS,
  GET_VARIANTS_SUCCESS,
  GET_VARIANTS_ERROR,
  GET_COUPON_SUCCESS,
  GET_COUPON_ERROR,
  POST_USER_PROFILE_ERROR,
  POST_USER_PROFILE_SUCCESS,
  POST_CHECK_EMAIL_ERROR,
  POST_CHECK_EMAIL_SUCCESS,
  PUT_SESSION_ERROR,
  PUT_SESSION_SUCCESS,
  UPDATE_VARIANTS,
  POST_SUBSCRIPTION_SUCCESS,
  POST_SUBSCRIPTION_ERROR,
  POST_CHECKOUT_SESSION_SUCCESS,
  POST_CHECKOUT_SESSION_ERROR,
  POST_CHECK_EMAIL_LOADING,
  GET_REDIRECT_SUCCESS,
  GET_REDIRECT_ERROR,
  CREATE_CONTACT_SENDINBLUE_SUCCESS,
  CREATE_CONTACT_SENDINBLUE_ERROR,
  UPDATE_CONTACT_SENDINBLUE_SUCCESS,
  UPDATE_CONTACT_SENDINBLUE_ERROR,
  GET_WIZARD_SUCCESS,
  GET_WIZARD_ERROR,
  UPDATE_CUSTOMER_FILTERS_LOADING,
  UPDATE_CUSTOMER_FILTERS_SUCCESS,
  UPDATE_CUSTOMER_FILTERS_ERROR,
  SET_MERV,
} from '../types';
import { ISteps, IWizard, ShopifyProduct, ShopifVariant, ProductsHelper } from '../../interfaces';

/* Context to manage general storage */

interface IGeneralContext {
  env: string;
  sessionData: any;
  checkEmailData: any; // update type
  productsData: any[];
  productsHelper: ProductsHelper;
  mervGrade: string;
  paymentData: {
    invoiceData: any[];
    paymentMethodData: any;
  };
  createdUser: any;
  upgradeAvailable: boolean;
  isLoadingUpgradeAvailable: boolean;
  isLoadingUpdateFilters: boolean;
  customerFiltersUpdated: boolean;
  isCheckEmailLoading: boolean;
  isError: boolean;
  isLoading: boolean;
  updatingVersion: boolean;
  checkoutSessionUrl: any;
  stripeClientSecret: any;
  intentType: string;
  subscriptionSuccessUrl: any;
  redirectUrl: any;
  sessionId: any;
  wizard: IWizard | null;
  PaymentFN: any;
  sessionsFN: any;
  sendinblueFN: any;
  productsFN: any;
  getWizard: Function;
  allVariants: any;
  couponData: any;
  tax: number;
}

const defaultState: IGeneralContext = {
  env: ENV,
  sessionData: {},
  checkEmailData: {
    alreadyLoaded: false,
    isExisting: false,
  },
  productsData: [],
  createdUser: {},
  productsHelper: { optionsAvailable: {}, variantsTotal: {} },
  mervGrade: "13",
  paymentData: { invoiceData: [], paymentMethodData: {} },
  upgradeAvailable: false,
  isLoadingUpgradeAvailable: false,
  isLoadingUpdateFilters: false,
  customerFiltersUpdated: false,
  isCheckEmailLoading: false,
  isError: false,
  isLoading: false,
  updatingVersion: false,
  checkoutSessionUrl: null,
  stripeClientSecret: null,
  intentType: "",
  subscriptionSuccessUrl: null,
  redirectUrl: null,
  sessionId: null,
  wizard: null,
  PaymentFN: {},
  sessionsFN: {},
  sendinblueFN: {},
  productsFN: {},
  allVariants: [],
  couponData: {},
  tax: 0,
  getWizard: () => {},
};

export const GeneralContext = createContext<IGeneralContext>(defaultState);

const GeneralState = (props: any) => {
  const initialState = defaultState;

  const [state, dispatch] = useReducer(GeneralReducer, initialState);

  /* Session functions */
  const sessionsFN = {
    startLoading: () => {
      dispatch({ type: LOADING });
    },
    put: async (data: any, nextStep: Function | undefined = undefined) => {
      try {
        await httpClient().put('/sessions', data);
        dispatch({ type: PUT_SESSION_SUCCESS });
        if (nextStep) {
          nextStep();
        }
      } catch (error) {
        console.error('ERROR: ', error);
        dispatch({ type: PUT_SESSION_ERROR });
      }
    },
    postCheckEmail: async (data: any) => {
      dispatch({ type: POST_CHECK_EMAIL_LOADING });
      try {
        const {
          data: { userExists },
        } = await httpClient().post('sessions/check-email', data);
        dispatch({
          type: POST_CHECK_EMAIL_SUCCESS,
          payload: { userExists },
        });
      } catch (error) {
        dispatch({ type: POST_CHECK_EMAIL_ERROR, payload: ['Error checking email: ', error] });
      }
    },
    postUserProfile: async (
      sessionId: string,
      nextStep: Function | undefined = undefined,
      skipLoader: boolean = false,
    ) => {
      if (nextStep) nextStep();
      if (!skipLoader) dispatch({ type: LOADING });
      try {
        const res = await httpClient().post(`users/${sessionId}`, {});
        dispatch({ type: POST_USER_PROFILE_SUCCESS, payload: res.data });
      } catch (error) {
        dispatch({ type: POST_USER_PROFILE_ERROR, payload: ['Error saving user profile: ', error] });
      }
    },
    getRedirectUrl: async (sessionId: string) => {
      dispatch({ type: LOADING });
      try {
        const res = await httpClient().get(`users/redirect/${sessionId}`);
        if (res.data.success) dispatch({ type: GET_REDIRECT_SUCCESS, payload: { res: res.data, sessionId } });
        else dispatch({ type: GET_REDIRECT_ERROR, payload: ['Error getting redirect URL: ', ''] });
      } catch (error) {
        dispatch({ type: GET_REDIRECT_ERROR, payload: ['Error getting redirect URL: ', error] });
      }
    },
  };

  const sendinblueFN = {
    createContact: async (data: any) => {
      try {
        await httpClient().post('/customers/contact', data);
        dispatch({ type: CREATE_CONTACT_SENDINBLUE_SUCCESS });
      } catch (error) {
        console.error('ERROR: ', error);
        dispatch({ type: CREATE_CONTACT_SENDINBLUE_ERROR });
      }
    },
    updateContact: async (data: any) => {
      try {
        await httpClient().put('/customers/contact', data);
        dispatch({ type: UPDATE_CONTACT_SENDINBLUE_SUCCESS });
      } catch (error) {
        console.error('ERROR: ', error);
        dispatch({ type: UPDATE_CONTACT_SENDINBLUE_ERROR });
      }
    },
    disconnectUser: async (data: any) => {
      try {
        await httpClient().post('/customers/disconnect-user', data);
      } catch (error) {
        console.error('ERROR: ', error);
      }
    },
  };

  const productsFN = {
    setMerv: (newMerv: string) => {
      dispatch({ type: SET_MERV, payload: { newMerv } });
    },
    // TODO: Seek and destroy
    getAllVariants: async () => {
      dispatch({ type: LOADING });
      try {
        const response = await httpClient().get(`products/variants/all`);
        dispatch({ type: GET_ALL_VARIANTS_SUCCESS, payload: response.data });
      } catch (error) {
        dispatch({ type: GET_VARIANTS_ERROR, payload: ['Error getting variants: ', error] });
      }
    },
    getProducts: async (dimensions: string[], nextStep: Function | undefined = undefined,) => {
      if (nextStep) nextStep();
      dispatch({ type: LOADING });
      try {
        const products = await httpClient().get(`products?dimensions=${dimensions}`);
        const reviewedProducts = dimensions.map((dimension) => {
          const product = products.data.data.find((product: ShopifyProduct) => product.dimensions === dimension)
          if (product)
            product.filtered_images = {
              carbon: product.images.find((image: any) => image.alt?.[0] === "C"),
              regular: product.images.find((image: any) => image.alt?.[0] === "R"),
            }
          return product;
        }).filter((p) => p !== undefined);
        const helper = buildProductsHelper(reviewedProducts);
        dispatch({ type: GET_PRODUCTS_SUCCESS, payload: { products: reviewedProducts, productsHelper: helper } });
      } catch (error) {
        console.log(error);
        dispatch({ type: GET_VARIANTS_ERROR, payload: ["Error getting products: ", error] });
      }
    },
    // TODO: Seek and destroy
    getVariantsByTitle: async (
      regularProductId: any,
      carbonProductId: any,
      variants: any,
      nextStep: Function | undefined = undefined,
    ) => {
      if (nextStep) nextStep();
      dispatch({ type: LOADING });
      try {
        const responses = await Promise.all([
          httpClient().get(
            `products/${regularProductId}/variants?byTitle=${variants.join()}&fields=price,sku,title,id,inventory_quantity`,
          ),
          httpClient().get(
            `products/${carbonProductId}/variants?byTitle=${variants.join()}&fields=price,sku,title,id,inventory_quantity`,
          ),
        ]);
        const [standardFilters, premiumFilters] = responses;
        dispatch({ type: GET_VARIANTS_SUCCESS, payload: standardFilters.data.data.concat(premiumFilters.data.data) });
        updateVariants(standardFilters.data.data.concat(premiumFilters.data.data));
      } catch (error) {
        dispatch({ type: GET_VARIANTS_ERROR, payload: ['Error getting variants: ', error] });
      }
    },
    getVariantsByIds: async (variantIds: any, premiumUpgrade: boolean) => {
      dispatch({ type: LOADING });
      try {
        const responses = await Promise.all(
          variantIds.map((variantId: any) =>
            httpClient().get(
              `products/${
                premiumUpgrade ? PREMIUM_PRODUCT_ID : STANDARD_PRODUCT_ID
              }/variants/${variantId}?fields=price,sku,title,id,inventory_quantity`,
            ),
          ),
        );
        const variants = responses.map((res) => res.data.data);
        dispatch({ type: GET_VARIANTS_SUCCESS, payload: variants });
      } catch (error) {
        dispatch({ type: GET_VARIANTS_ERROR, payload: ['Error getting variants: ', error] });
      }
    },
    // TODO: This is probably not needed anymore
    getUpgradeAvailable: async (variantIds: any) => {
      dispatch({ type: LOADING_UPGRADE_AVAILABLE });
      try {
        const res = await httpClient().get(`products/check-variants-upgrade?variantIds=${variantIds.join(',')}`);
        dispatch({ type: GET_UPGRADE_AVAILABLE, payload: res.data.data });
      } catch (error) {
        dispatch({ type: GET_UPGRADE_AVAILABLE_ERROR, payload: ['Error getting variants upgrade: ', error] });
      }
    },

    customerUpdateFilters: async (customerId: string, filtersSelection: Array<any>, premiumUpgrade: boolean) => {
      dispatch({ type: UPDATE_CUSTOMER_FILTERS_LOADING });

      try {
        const response = await httpClient().put(`customers/${customerId}/filters`, { new_filter_inputs: filtersSelection });
        if (response.data.success) {
          dispatch({ type: UPDATE_CUSTOMER_FILTERS_SUCCESS });
        } else {
          dispatch({ type: UPDATE_CUSTOMER_FILTERS_ERROR });
        }
        return response.data;
      } catch (error) {
        dispatch({ type: UPDATE_CUSTOMER_FILTERS_ERROR, payload: ['Error getting filters: ', error] });
      }
    },
  };

  /* Payments Methods */
  const PaymentFN = {
    getCoupon: async (couponCode: string) => {
      dispatch({ type: LOADING });
      try {
        const response = await httpClient().get(`customers/coupons/${couponCode}`);
        dispatch({ type: GET_COUPON_SUCCESS, payload: response.data })
      } catch (error) {
        dispatch({ type: GET_COUPON_ERROR })
      }
    },
    postSubscription: async (
      subscriptionInterval: number,
      email: string,
      premiumUpgrade: boolean,
      ecoDelivery: boolean,
      mervRating: string,
      couponId: string,
      alreadyLogged: boolean = false,
    ) => {
      dispatch({ type: LOADING });
      try {
        const { data } = await httpClient().post('customers/subscription', {
          email,
          subscriptionInterval,
          premiumUpgrade,
          ecoDelivery,
          mervRating,
          couponId,
        });

        localStorage.setItem('nano-user-email', email);
        if (data.success) {
          const { paymentIntentSecret, intentType, loginLink, tax } = data;
          const subscriptionSuccessUrl = alreadyLogged ? '/dashboard' : loginLink;
          const payload = { paymentIntentSecret, intentType, subscriptionSuccessUrl, tax };
          dispatch({ type: POST_SUBSCRIPTION_SUCCESS, payload });
        } else {
          dispatch({ type: POST_SUBSCRIPTION_ERROR, payload: ['Error creating checkout subscription: ', data] });
        }
      } catch (error) {
        dispatch({ type: POST_SUBSCRIPTION_ERROR, payload: ['Error creating checkout subscription: ', error] });
      }
    },
    postCheckoutSession: async (
      subscriptionInterval: number,
      email: string,
      premiumUpgrade: boolean,
      ecoDelivery: boolean,
    ) => {
      dispatch({ type: LOADING });
      try {
        const { data } = await httpClient().post('customers/checkout-session', {
          email,
          subscriptionInterval,
          premiumUpgrade,
          ecoDelivery,
        });

        localStorage.setItem('nano-user-email', email);
        if (data.success) {
          dispatch({ type: POST_CHECKOUT_SESSION_SUCCESS, payload: data.checkoutSessionUrl });
        } else {
          dispatch({ type: POST_CHECKOUT_SESSION_ERROR, payload: ['Error creating checkout session: ', data] });
        }
      } catch (error: any) {
        dispatch({ type: POST_CHECKOUT_SESSION_ERROR, payload: ['Error creating checkout session: ', error] });
      }
    },
  };

  // TODO: Check if this can be removed
  const updateVariants = (products: any) => {
    const newVariants = state.shopifyVariants.map((v: any) => {
      const stockVariants = products.filter(
        (stock: any) => stock.title === v.standard.variant || stock.title === v.premium.variant,
      );

      const findStandardVariantStock = stockVariants.find((stock: any) => stock.product_id === STANDARD_PRODUCT_ID);
      const findPremiumVariantStock = stockVariants.find((stock: any) => stock.product_id === PREMIUM_PRODUCT_ID);

      return {
        standard: {
          ...v.standard,
          id: findStandardVariantStock?.id,
          price: findStandardVariantStock?.price,
          variant: findStandardVariantStock?.title,
        },
        premium: findPremiumVariantStock
          ? {
              ...v.premium,
              id: findPremiumVariantStock?.id,
              price: findPremiumVariantStock?.price,
              variant: findPremiumVariantStock?.title,
            }
          : {},
        isPremium: findStandardVariantStock ? false : true,
        selectedId: findStandardVariantStock?.id || findPremiumVariantStock?.id,
        selectedProductId: findStandardVariantStock ? STANDARD_PRODUCT_ID : PREMIUM_PRODUCT_ID, // validation in case we have a product with only premium version
      };
    });
    dispatch({ type: UPDATE_VARIANTS, payload: newVariants });
  };

  const buildProductsHelper = (products: ShopifyProduct[]): ProductsHelper => {
    const productsHelper: ProductsHelper = defaultState.productsHelper;
    const optionsCheck: { [key: string]: { [key: string]: number } } = {};

    // Initialize
    PRODUCT_TIER_LABELS.forEach((tier) =>{
      productsHelper.optionsAvailable[tier] = { anyAvailable: false, highestMERV: "0", lowestMERV: "20" };
      productsHelper.variantsTotal[tier] = {};
      optionsCheck[tier] = {}; 
      SUPPORTED_MERV_VARIANTS.forEach((merv) => {
        productsHelper.optionsAvailable[tier][merv] = false;
        productsHelper.variantsTotal[tier][merv] = 0;
        optionsCheck[tier][merv] = 0;
      });
    });

    // Add available options for each variant combo
    products.forEach((product: ShopifyProduct) => {
      product.variants.forEach((variant: ShopifVariant) => {
        if (PRODUCT_TIER_LABELS.includes(variant.option2) && SUPPORTED_MERV_VARIANTS.includes(variant.option1)) {
          optionsCheck[variant.option2][variant.option1] += 1;
          productsHelper.variantsTotal[variant.option2][variant.option1] += parseFloat(variant.price);
        }
      });
    });

    // Define variants avalable
    const productsQuantity = products.length;
    PRODUCT_TIER_LABELS.forEach((tier) =>{
      SUPPORTED_MERV_VARIANTS.forEach((merv) => {
        if (optionsCheck[tier][merv] === productsQuantity) {
          productsHelper.optionsAvailable[tier][merv] = true;
          productsHelper.optionsAvailable[tier].anyAvailable = true;
          if (parseInt(productsHelper.optionsAvailable[tier].highestMERV) < parseInt(merv)) productsHelper.optionsAvailable[tier].highestMERV = merv;
          if (parseInt(productsHelper.optionsAvailable[tier].lowestMERV) > parseInt(merv)) productsHelper.optionsAvailable[tier].lowestMERV = merv;
        } else {
          productsHelper.optionsAvailable[tier][merv] = false;
        }
      });
    });

    return productsHelper;
  }

  const getWizard = async (uuid: string) => {
    dispatch({ type: LOADING });

    try {
      const res = await httpClient().get(`wizards/${uuid}`);
      if (res.data.steps) {
        // TODO: check if this can be done by db query
        res.data.steps = res.data.steps.sort((a: ISteps, b: ISteps) => a.position - b.position);
        dispatch({ type: GET_WIZARD_SUCCESS, payload: res.data });
      } else {
        dispatch({ type: GET_WIZARD_ERROR });
      }
    } catch (error) {
      dispatch({ type: GET_WIZARD_ERROR });
    }
  };

  return (
    <GeneralContext.Provider
      value={{
        env: state.env,
        sessionData: state.sessionData,
        checkEmailData: state.checkEmailData,
        productsData: state.productsData,
        productsHelper: state.productsHelper,
        mervGrade: state.mervGrade,
        createdUser: state.createdUser,
        isError: state.isError,
        isLoading: state.isLoading,
        upgradeAvailable: state.upgradeAvailable,
        isLoadingUpgradeAvailable: state.isLoadingUpgradeAvailable,
        isLoadingUpdateFilters: state.isLoadingUpdateFilters,
        customerFiltersUpdated: state.customerFiltersUpdated,
        isCheckEmailLoading: state.isCheckEmailLoading,
        updatingVersion: state.updatingVersion,
        paymentData: state.paymentData,
        checkoutSessionUrl: state.checkoutSessionUrl,
        stripeClientSecret: state.stripeClientSecret,
        intentType: state.intentType,
        subscriptionSuccessUrl: state.subscriptionSuccessUrl,
        redirectUrl: state.redirectUrl,
        sessionId: state.sessionId,
        wizard: state.wizard,
        allVariants: state.allVariants,
        couponData: state.couponData,
        tax: state.tax,
        PaymentFN,
        sessionsFN,
        sendinblueFN,
        productsFN,
        getWizard,
      }}
    >
      {props.children}
    </GeneralContext.Provider>
  );
};

export default GeneralState;
