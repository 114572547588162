import { BrowserRouter } from 'react-router-dom';
import Root from './Root';

const Router = () => {
  return (
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  );
};

export default Router;
