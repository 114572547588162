import React, { ReactElement, useContext } from 'react';
import { WizardContext } from '../../store/context/wizard/WizardState';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input/input';

interface PhoneNumberInputProps {
  addPhoneNumber: Function;
  skipTitle?: boolean;
  onChange?: Function;
}

export const PhoneNumberInput = ({ addPhoneNumber, skipTitle, onChange }: PhoneNumberInputProps): ReactElement => {
  const { phoneNumber, setWizardState } = useContext(WizardContext);

  const onKeyUpPhoneNumber = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      addPhoneNumber();
    }
  };

  return (
    <div className="step" data-step="phone-number">
      {!skipTitle && <h3>Payment Details</h3>}
      <div className="phoneNumberFormGroup">
        <label>
          <span>Phone number</span>
          <PhoneInput
            country="US"
            onKeyUp={onKeyUpPhoneNumber}
            value={phoneNumber}
            onChange={(phone) => {
              setWizardState('phoneNumber', phone);
              if (onChange) onChange(phone);
            }}
            autoFocus={true}
          />
        </label>
      </div>
    </div>
  );
};
