import { ReactElement, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../components/common/spinner/Spinner';
import { GeneralContext } from '../store/context/GeneralState';

const Redirect = (): ReactElement => {
  const navigate = useNavigate();
  const { session_id } = useParams();

  // CONTEXT
  const { sessionsFN, isLoading, isError, redirectUrl } = useContext(GeneralContext);

  useEffect(() => {
    sessionsFN.getRedirectUrl(session_id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isLoading && !isError && redirectUrl) navigate(`/${redirectUrl}`);
    if (isError && !isLoading) navigate('/');

    // eslint-disable-next-line
  }, [isLoading, isError]);

  return <Spinner />;
};

export default Redirect;
