interface SkipButtonProps {
  skipFunction: Function;
}

export const SkipButton = ({ skipFunction }: SkipButtonProps) => {
  const handleClick = () => {
    skipFunction();
  };

  return (
    <>
      <a href="#!" onClick={handleClick}>
        Skip
      </a>
    </>
  );
};
