import React from 'react';
import { NextButton, BackButton, SkipButton } from './navigation';

import { IActions } from '../../interfaces';

export interface ActionProps extends IActions {
  nextDisabled?: boolean;
  backDisabled?: boolean;
  required?: boolean;
  customCallback?: Function;
  nextButtonProps?: any;
}

export const Actions = ({
  backStep,
  nextStep,
  skipStep,
  nextDisabled,
  backDisabled,
  required,
  customCallback,
  nextButtonProps,
}: ActionProps) => (
  <>
    {backStep && <BackButton disabled={backDisabled} onClick={() => backStep?.()} />}
    {nextStep && (
      <NextButton
        {...nextButtonProps}
        disabled={nextDisabled}
        onClick={() => (customCallback ? customCallback() : nextStep?.())}
      />
    )}
    {skipStep && !required && <SkipButton skipFunction={() => skipStep?.()} />}
  </>
);
