import { ReactElement, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../store/context/auth/AuthContext';
import '../../../App.scss';

import Layout from '../layout';
import Wizard from '../../wizard/Wizard';

const WizardLayout = (): ReactElement => {
  const navigate = useNavigate();

  // CONTEXT
  const { AuthFN, isAuthenticated, isLoading, isError, userData } = useContext(AuthContext);

  // EFFECTS
  useEffect(() => {
    if (isAuthenticated && !isLoading && (userData.customer == null || userData.stripeUserData == null)) {
      AuthFN.verify();
    }
    // eslint-disable-next-line
  }, [isAuthenticated, userData, isLoading]);

  useEffect(() => {
    if (isAuthenticated && !isError && !isLoading) navigate('/dashboard/');
    // eslint-disable-next-line
  }, [isLoading, isError, isAuthenticated]);

  return (
    <Layout>
      <Wizard />
    </Layout>
  );
};

export default WizardLayout;
