import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ErrorPage from '../components/layout/ErrorPage';
import PrivateRoute from './PrivateRoute';
import Login from './Login';
import Logout from './Logout';

import WizardLayout from '../components/layout/wizard/Wizard.layout';
import DashboardLayout from '../components/layout/dashboard/Dashboard.layout';
import Billing from '../components/dashboard/outlet/Billing';
import Profile from '../components/dashboard/outlet/Profile';
import Redirect from './Redirect';
import Activate from '../components/dashboard/outlet/Activate';
import Filters from '../components/dashboard/outlet/Filters';
import Home from '../components/dashboard/outlet/Home';

const Root = () => {
  // const location = useLocation();

  // useEffect(() => {
  //   ReactGA.send({ hitType: 'pageview', page: location.pathname });
  // }, [location]);

  return (
    <Routes>
      <Route index element={<WizardLayout />} />

      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <DashboardLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<Home />} />
        <Route path="profile" element={<Profile />} />
        <Route path="billing" element={<Billing />} />
        <Route path="activate" element={<Activate />} />
        <Route path="filters" element={<Filters />} />
      </Route>

      <Route path="/login" element={<Login />} />

      <Route path="/logout" element={<Logout />} />

      <Route path="/r/:session_id" element={<Redirect />} />

      <Route path="*" element={<ErrorPage errorType={404} />} />
    </Routes>
  );
};

export default Root;
