import { ChangeEvent, ChangeEventHandler, useEffect } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import { DataField } from '../../../interfaces';
import { STATES } from '../../../utils';

interface InputPlacesProps {
  inputField: DataField;
  updateAddress: Function;
  value: any;
  onBlur: ChangeEventHandler<HTMLInputElement>;
}

const InputPlaces = ({ inputField, updateAddress, value: changeValue, onBlur }: InputPlacesProps) => {
  const { inputName, placeholder, type } = inputField;

  // GOOGLE PLACES
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  const registerRef = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description, structured_formatting: { main_text } }: any) =>
    () => {
      setValue(main_text, false);
      clearSuggestions();
      getGeocode({ address: description })
        .then((result) => {
          let address1 = '';
          let postcode = '';

          for (const component of result[0].address_components) {
            const componentType = component.types[0];
            switch (componentType) {
              case 'street_number': {
                address1 = `${component.long_name} `;
                break;
              }

              case 'route': {
                address1 += component.short_name;
                break;
              }

              case 'postal_code': {
                postcode = `${component.long_name}${postcode}`;
                break;
              }

              case 'postal_code_suffix': {
                postcode = `${postcode}-${component.long_name}`;
                break;
              }
              case 'locality':
                updateAddress({ name: 'city', value: component.long_name });
                break;
              case 'administrative_area_level_1': {
                updateAddress({
                  name: 'state',
                  value: STATES.find((state) => state.postalCode === component.short_name)?.name || '',
                });

                break;
              }
              case 'country': {
                console.log(component.long_name);
                break;
              }
              default: {
                console.log('no type found', componentType);
              }
            }
          }
          updateAddress({ name: 'address_line_1', value: address1 });
          updateAddress({ name: 'zip_code', value: parseInt(postcode.substring(0, 5)) });
        })
        .catch((error) => {
          console.log('😱 Error: ', error);
        });
    };

  const renderSuggestions = () =>
    data.map((suggestion, index) => {
      const {
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={index} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  useEffect(() => {
    setValue(changeValue);
    const dismissTimeout = setTimeout(() => {
      clearSuggestions();
    }, 300);

    return () => {
      clearTimeout(dismissTimeout);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="shippingAddressFormGroup" ref={registerRef}>
      <input
        name={inputName}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        autoComplete="off"
        className="form-control"
        disabled={!ready}
      />
      {status === 'OK' && <ul className="suggestionsGooglePlaces">{renderSuggestions()}</ul>}
    </div>
  );
};

export default InputPlaces;
