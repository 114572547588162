import { ReactElement, useContext } from 'react';
import { Loading } from '../Loading';
import { Footer } from '../Footer';
import { GeneralContext } from '../../../store/context/GeneralState';
import { WizardContext } from '../../../store/context/wizard/WizardState';
import { StepComponentProps } from '../../../interfaces';
import { PhoneNumberInput } from '../../checkout/PhoneNumberInput';
import { socket } from '../../../store/context/socket/SocketContext';
import { SENDINBLUE_EVENTS } from '../../../utils';

interface ScheduleFilterAssistanceProps extends StepComponentProps {
  isSkipped: boolean;
  sessionId: string;
  dataTemplate: Function;
}

export const ScheduleFilterAssistance = ({
  actions,
  sessionId,
  dataTemplate,
}: ScheduleFilterAssistanceProps): ReactElement => {
  const { isLoading, sessionsFN, sendinblueFN } = useContext(GeneralContext);
  const { phoneNumber, email, setWizardState } = useContext(WizardContext);

  const addPhoneNumber = () => {};

  return (
    <div className="step" data-step="profile-set">
      <h3>Would you like to be contacted by a professional to help you find your filter size?</h3>
      <h5>
        Leave us a phone number to schedule a call or just leave it blank if you prefer to be contacted via email.
      </h5>
      <Loading loading={isLoading}>
        <PhoneNumberInput addPhoneNumber={addPhoneNumber} skipTitle />
        <Footer>
          {/* <BackButton onClick={() => actions.backStep?.()} /> */}
          <button
            className="button next"
            onClick={() => {
              setWizardState('scheduleFilterHelp', false);
              actions.nextStep?.(false);
            }}
          >
            No
          </button>
          <button
            className="button next"
            onClick={async () => {
              sessionsFN.startLoading();
              setWizardState('scheduleFilterHelp', true);
              window.gtag('event', 'set_appoinment', {
                firebase_session: sessionId,
              });
              await sessionsFN.put(dataTemplate('user_phone_number', phoneNumber));
              socket.emit('send-event', {
                event: SENDINBLUE_EVENTS.added_phone,
                properties: { PHONE: phoneNumber },
              });
              sendinblueFN.updateContact({ email, attributes: { SCHEDULE_FILTER_HELP: true } });
              sessionsFN.put(dataTemplate('schedule_filter_help', true), () =>
                sessionsFN.postUserProfile(sessionId, () => actions.nextStep?.(true)),
              );
            }}
          >
            Yes
          </button>
        </Footer>
      </Loading>
    </div>
  );
};
