import React, { ReactElement, useContext } from 'react';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';

import { STATES } from '../../utils';
import { WizardContext } from '../../store/context/wizard/WizardState';

export const ShippingInfoInput = (props: any): ReactElement => {
  const { addressLine1, addressLine2, addressState, addressCity, addressZipCode, setWizardState } =
    useContext(WizardContext);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  const registerRef = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Update the keyword of the input element
    setValue(e.target.value);
    setWizardState('addressLine1', e.target.value);
  };

  const handleSelect =
    ({ description, structured_formatting: { main_text } }: any) =>
    () => {
      setValue(main_text, false);
      clearSuggestions();
      getGeocode({ address: description })
        .then((result) => {
          let address1 = '';
          let postcode = '';

          for (const component of result[0].address_components) {
            const componentType = component.types[0];
            switch (componentType) {
              case 'street_number': {
                address1 = `${component.long_name} `;
                break;
              }

              case 'route': {
                address1 += component.short_name;
                break;
              }

              case 'postal_code': {
                postcode = `${component.long_name}${postcode}`;
                break;
              }

              case 'postal_code_suffix': {
                postcode = `${postcode}-${component.long_name}`;
                break;
              }
              case 'locality':
                setWizardState('addressCity', component.long_name);
                break;
              case 'administrative_area_level_1': {
                setWizardState(
                  'addressState',
                  STATES.find((state) => state.postalCode === component.short_name)?.name || '',
                );
                break;
              }
              case 'country': {
                break;
              }
              default: {
                console.log('no type found');
              }
            }
          }
          setWizardState('addressLine1', address1);
          setWizardState('addressZipCode', postcode.substring(0, 5));
        })
        .catch((error) => {
          console.log('😱 Error: ', error);
        });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div className="step" data-step="shipping-address">
      {/*
        <h3>Shipping Address</h3>
        <h5>Search for your address and select a result from the dropdown menu.</h5>
      */}

      <div className="shippingAddressStep">
        <div className="shippingAddressFormGroup" ref={registerRef}>
          <label htmlFor="" className="addressLines addressLineOne">
            <span>
              ADDRESS LINE 1 <b>*</b>
            </span>
            <input
              type="text"
              autoComplete="off"
              value={value}
              placeholder={addressLine1}
              onChange={handleInput}
              disabled={!ready}
              autoFocus={true}
            />
            {status === 'OK' && <ul className="suggestionsGooglePlaces">{renderSuggestions()}</ul>}
          </label>
          <label htmlFor="" className="addressLines">
            <span>ADDRESS LINE 2</span>
            <input
              type="text"
              autoComplete="off"
              value={addressLine2}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => setWizardState('addressLine2', e.target.value)}
            />
          </label>
          <label htmlFor="" className="addressLines">
            <span>
              CITY <b>*</b>
            </span>
            <input
              type="text"
              autoComplete="off"
              value={addressCity}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => setWizardState('addressCity', e.target.value)}
            />
          </label>
        </div>
        <div className="shippingAddressFormGroup fourColLayout">
          <label htmlFor="" className="addressLines">
            <span>
              STATE <b>*</b>
            </span>
            <select
              className="stateInput"
              name=""
              value={addressState}
              onChange={(e) => setWizardState('addressState', e.target.value)}
            >
              <option disabled value="">
                Select State
              </option>
              {STATES.map((state, index) => (
                <option key={index} value={state.name}>
                  {state.name}
                </option>
              ))}
            </select>
          </label>
          <label htmlFor="" className="addressLines">
            <span>
              ZIP CODE <b>*</b>
            </span>
            <input
              type="text"
              autoComplete="off"
              inputMode="numeric"
              pattern="[0-9]*"
              maxLength={5}
              onKeyDown={(e) => (e.target.value.length === 2 ? false : true)}
              value={addressZipCode}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => setWizardState('addressZipCode', e.target.value)}
            />
          </label>
        </div>
      </div>
    </div>
  );
};
