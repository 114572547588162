// Extend Functionality
Object.assign(String.prototype, {
  capitalizeFirstCharacter() {
    return this.trim()
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  },
});

// .env
export const ENV = process.env.NODE_ENV ?? "local";
export const API_HOST = process.env.REACT_APP_API_HOST;
export const API_VERSION = process.env.REACT_APP_API_VERSION;
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SENTRY_SAMPLE_RATE = process.env.REACT_APP_SENTRY_SAMPLE_RATE;
export const FULLSTORY_ORG_ID = process.env.REACT_APP_FULLSTORY_ORG_ID;
export const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
export const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY;

// Firebase
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// wizard
export const INITIAL_STEP = 0;
// TODO: This needs to go
export const STANDARD_PRODUCT_ID = parseInt(process.env.REACT_APP_STANDARD_PRODUCT_ID); // Hardcoded shopify product id -> .env
// TODO: This needs to go
export const PREMIUM_PRODUCT_ID = parseInt(process.env.REACT_APP_PREMIUM_PRODUCT_ID); // Hardcoded shopify product id -> .env
export const REGULAR_TIER_LABEL = process.env.REACT_APP_REGULAR_TIER_LABEL || "None";
export const PREMIUM_TIER_LABEL = process.env.REACT_APP_PREMIUM_TIER_LABEL || "Carbon";
export const PRODUCT_TIER_LABELS = process.env.REACT_APP_PRODUCT_TIER_LABELS.split(",");
export const SUPPORTED_MERV_VARIANTS = process.env.REACT_APP_SUPPORTED_MERV_VARIANTS.split(",");
export const ECO_DELIVERY_DISCOUNT = parseFloat(process.env.REACT_APP_ECO_DELIVERY_DISCOUNT);
export const PREMIUM_UPGRADE_RATE = parseFloat(process.env.REACT_APP_PREMIUM_UPGRADE_RATE);
export const FILTER_DATA_STRUCTURE = [
  { filter_1_label: '', filter_1_x: '', filter_1_y: '', filter_1_z: '' },
  { filter_2_label: '', filter_2_x: '', filter_2_y: '', filter_2_z: '' },
  { filter_3_label: '', filter_3_x: '', filter_3_y: '', filter_3_z: '' },
  { filter_4_label: '', filter_4_x: '', filter_4_y: '', filter_4_z: '' },
  { filter_5_label: '', filter_5_x: '', filter_5_y: '', filter_5_z: '' },
  { filter_6_label: '', filter_6_x: '', filter_6_y: '', filter_6_z: '' },
];

export const STANDARD_IMAGE =
  'https://cdn.shopify.com/s/files/1/0611/2120/0349/products/air-filter-01.png?v=1645209654&width=300';
export const PREMIUM_IMAGE =
  'https://cdn.shopify.com/s/files/1/0611/2120/0349/products/carbon-air-filter.png?v=1645209631&width=300';
export const STANDARD_NAME = 'Air Filter';
export const PREMIUM_NAME = 'Carbon Air Filter';

export const API_URL = `${API_HOST}/api/${API_VERSION}/`;

// Auth
export const TOKEN_NAME = 'apiKey';
