import uuid from 'react-native-uuid';
import { ECO_DELIVERY_DISCOUNT, PREMIUM_TIER_LABEL, REGULAR_TIER_LABEL } from '../config';
import { KeyValuePair, ProductsHelper, SubscriptionInterval } from '../interfaces';

export const sessionId = uuid.v4();

export const isEmail = (email: string) => {
  // eslint-disable-next-line
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
};

export const isLegacySessionId = (sessionId: string) => {
  const sessionRegex = /\d{8}-?\d{6}-?\d{6}/;
  return sessionRegex.test(sessionId);
};

export const placeholders = [
  'Label (Ex: Main Unit)',
  'Label (Ex: Living Room)',
  'Label (Ex: Bedroom)',
  'Label (Ex: Kitchen)',
  `Label (Ex: Alice's Room)`,
  `Label (Ex: Bryce's Room)`,
];

export const centralTimeDate = () => {
  const time = new Date().getTime();
  const date = new Date(time);
  const options: Intl.DateTimeFormatOptions = {};
  options.timeZone = 'America/Chicago';
  options.timeZoneName = 'short';
  return date.toLocaleString('en-US', options);
};

/* Return all url params */
export const getParams = () => {
  const params = new URLSearchParams(window.location.search);
  const paramsArray = [];
  for (let pair of params.entries()) {
    paramsArray.push({ key: pair[0], value: pair[1] });
  }
  return paramsArray;
};

/* Converts any string to camelCase */
export const toCamelCase = (str: string) =>
  str.replace(/^([A-Z])|[\s-_](\w)/g, (match, p1, p2, offset) => (p2 ? p2.toUpperCase() : p1.toLowerCase()));

/* Remove duplicates */
export const removeDuplicates = (arr: any[]) => arr.filter((item, index) => arr.indexOf(item) === index);

/* Copy text to clipboard */
export const copyToClipboard = (text: string) => navigator.clipboard.writeText(text);

/* Get link to share redirect */
export const getShareLink = (sessionId: string) => `${window.location.origin}/r/${sessionId}`;

export const HOME_TYPES = [
  { name: 'Single-Family House', value: 'Single-Family House' },
  { value: 'Duplex', name: 'Duplex' },
  { name: 'Condo / Apartment', value: 'Condo / Apartment' },
];

export const SUBSCRIPTION_INTERVALS: SubscriptionInterval[] = [
  { value: 1, name: 'Every Month', plan: 'Cleanest' },
  { value: 2, name: 'Every Two Months', plan: 'Cleaner' },
  { value: 3, name: 'Every Three Months', plan: 'Clean' },
  { value: 6, name: 'Every Six Months', plan: '' },
];

export const calculateNextDeliveryDate = (subscribedAt: any, subscriptionInterval: number): string => {
  const currentDate = new Date();
  const nextDeliveryDate = new Date(subscribedAt);
  nextDeliveryDate.setMonth(nextDeliveryDate.getMonth() + subscriptionInterval);
  if (nextDeliveryDate < currentDate) return calculateNextDeliveryDate(nextDeliveryDate, subscriptionInterval);
  return Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(nextDeliveryDate);
};

export const productTierLabel = (premium: boolean): string => {
  return premium ? PREMIUM_TIER_LABEL : REGULAR_TIER_LABEL;
}

export const carbonRegularPriceDiff = (prodHelper: ProductsHelper, merv: string): number => {
  return prodHelper.variantsTotal[productTierLabel(true)][merv] - prodHelper.variantsTotal[productTierLabel(false)][merv];
}

export const calculateNewFrequencyPrice = (orderTotal: number, frequency: number, ecoDelivery: boolean): number => {
  let subscriptionPrice = orderTotal;
  if (ecoDelivery) subscriptionPrice *= (1 - ECO_DELIVERY_DISCOUNT);
  subscriptionPrice /= frequency;
  return subscriptionPrice;
}

export const STATES = [
  { name: 'Alabama', postalCode: 'AL' },
  { name: 'Arizona', postalCode: 'AZ' },
  { name: 'Arkansas', postalCode: 'AR' },
  { name: 'California', postalCode: 'CA' },
  { name: 'Colorado', postalCode: 'CO' },
  { name: 'Connecticut', postalCode: 'CT' },
  { name: 'Delaware', postalCode: 'DE' },
  { name: 'District of Columbia', postalCode: 'DC' },
  { name: 'Florida', postalCode: 'FL' },
  { name: 'Georgia', postalCode: 'GA' },
  { name: 'Idaho', postalCode: 'ID' },
  { name: 'Illinois', postalCode: 'IL' },
  { name: 'Indiana', postalCode: 'IN' },
  { name: 'Iowa', postalCode: 'IA' },
  { name: 'Kansas', postalCode: 'KS' },
  { name: 'Kentucky', postalCode: 'KY' },
  { name: 'Louisiana', postalCode: 'LA' },
  { name: 'Maine', postalCode: 'ME' },
  { name: 'Maryland', postalCode: 'MD' },
  { name: 'Massachusetts', postalCode: 'MA' },
  { name: 'Michigan', postalCode: 'MI' },
  { name: 'Minnesota', postalCode: 'MN' },
  { name: 'Mississippi', postalCode: 'MS' },
  { name: 'Missouri', postalCode: 'MO' },
  { name: 'Montana', postalCode: 'MT' },
  { name: 'Nebraska', postalCode: 'NE' },
  { name: 'Nevada', postalCode: 'NV' },
  { name: 'New Hampshire', postalCode: 'NH' },
  { name: 'New Jersey', postalCode: 'NJ' },
  { name: 'New Mexico', postalCode: 'NM' },
  { name: 'New York', postalCode: 'NY' },
  { name: 'North Carolina', postalCode: 'NC' },
  { name: 'North Dakota', postalCode: 'ND' },
  { name: 'Ohio', postalCode: 'OH' },
  { name: 'Oklahoma', postalCode: 'OK' },
  { name: 'Oregon', postalCode: 'OR' },
  { name: 'Pennsylvania', postalCode: 'PA' },
  { name: 'Rhode Island', postalCode: 'RI' },
  { name: 'South Carolina', postalCode: 'SC' },
  { name: 'South Dakota', postalCode: 'SD' },
  { name: 'Tennessee', postalCode: 'TN' },
  { name: 'Texas', postalCode: 'TX' },
  { name: 'Utah', postalCode: 'UT' },
  { name: 'Vermont', postalCode: 'VT' },
  { name: 'Virginia', postalCode: 'VA' },
  { name: 'Washington', postalCode: 'WA' },
  { name: 'West Virginia', postalCode: 'WV' },
  { name: 'Wisconsin', postalCode: 'WI' },
  { name: 'Wyoming', postalCode: 'WY' },
];

export const STEPS_DATA_CASES: KeyValuePair<string[]> = {
  0: ['email'],
  1: ['name'],
  2: ['homeType'],
  3: ['squareFootage'],
  4: ['mervOne'],
  5: ['mervTwo'],
  6: ['old'],
  7: ['petDander', 'allergens', 'viruses', 'smoke', 'pollution'],
  8: ['count'],
  9: ['filterFields'],
  10: ['addressLine1', 'addressLine2', 'addressState', 'addressCity', 'addressZipCode'],
  11: ['phoneNumber'],
  17: ['profileCompleted'],
};

export const SENDINBLUE_EVENTS = {
  added_email: 'added_email',
  added_name: 'added_name',
  added_filters: 'added_filters',
  added_address: 'added_address',
  added_phone: 'added_phone',
  added_payment: 'added_payment',
  added_home: 'added_home',
  added_square_footage: 'added_square_footage',
  added_concerns: 'added_concerns',
  out_of_stock: 'out_of_stock',
  enter_cart: 'enter_cart',
  abandoned_cart: 'abandoned_cart',
  subscription_purchased: 'subscription_purchased',
  subscription_canceled: 'subscribed_canceled',
  abandoned_profile: 'abandoned_profile',
  profile_completed: 'profile_completed',
};

export const ACCEPTED_PARAMS = [
  'admin',
  'wizard_id',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  '_gl',
];
