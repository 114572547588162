import { ChangeEventHandler } from 'react';
import { DataField } from '../../../interfaces';
import './forms.css';

interface SelectFieldProps extends DataField {
  value: any;
  onChange: ChangeEventHandler<HTMLSelectElement>;
  onBlur: ChangeEventHandler<HTMLSelectElement>;
}

const SelectField = ({
  placeholder,
  inputName,
  value,
  onChange,
  onBlur,
  options,
  disabled = false,
}: SelectFieldProps) => {
  return (
    <select name={inputName} id={inputName} onChange={onChange} onBlur={onBlur} value={value} disabled={disabled}>
      <option value="" disabled selected>
        {placeholder}
      </option>
      {options.map((option: any, index: number) => (
        <option key={index} value={option.value}>
          {option.name}
        </option>
      ))}
    </select>
  );
};

export default SelectField;
