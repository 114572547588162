import { ReactElement } from "react";
import { endOfToday, add, format } from 'date-fns';

interface ShippingScheduleElementProps {
  ecoDelivery: boolean;
  productsCount: number;
  subscriptionInterval: number
}

export const ShippingScheduleElement = ({ ecoDelivery, productsCount, subscriptionInterval }: ShippingScheduleElementProps): ReactElement => {
  const shipScheduleWording = () => {
    if (ecoDelivery)
      return `${productsCount * 2} Filters`
    else
      if (productsCount > 1)
        return `${productsCount} Filters`
      else
        return `${productsCount} Filter`
  }

  return (
    <div className="frequencyShippingSchedule">
    <h3>
      <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
        <path d="M180-80q-24 0-42-18t-18-42v-620q0-24 18-42t42-18h65v-60h65v60h340v-60h65v60h65q24 0 42 18t18 42v620q0 24-18 42t-42 18H180Zm0-60h600v-430H180v430Zm0-490h600v-130H180v130Zm0 0v-130 130Zm300 230q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z"/>
      </svg>
      {"  "}Shipping Schedule
    </h3>
    <div className="scheduleRow">
      <span className="alignLeft">
        { shipScheduleWording() }
      </span>
      <span className="alignRight">This week</span>
    </div>
    <div className="scheduleRow">
      <span className="alignLeft">
        { shipScheduleWording() }
      </span>
      <span className="alignRight">
        {format(
          add(endOfToday(), { months: ecoDelivery ? subscriptionInterval * 2 : subscriptionInterval }),
          'MMMM yyyy',
        )}
      </span>
    </div>
    <div className="scheduleRow">
      <span className="alignLeft">
        { shipScheduleWording() }
      </span>
      <span className="alignRight">
        {format(
          add(endOfToday(), {
            months: ecoDelivery ? subscriptionInterval * 4 : subscriptionInterval * 2,
          }),
          'MMMM yyyy',
        )}
      </span>
    </div>
  </div>
  );
};
