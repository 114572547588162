import React, { ReactElement, useContext } from 'react';
import { Loading } from '../Loading';
import { Footer } from '../Footer';
import { GeneralContext } from '../../../store/context/GeneralState';
import { WizardContext } from '../../../store/context/wizard/WizardState';
import { StepComponentProps } from '../../../interfaces';
import { socket } from '../../../store/context/socket/SocketContext';
import { SENDINBLUE_EVENTS } from '../../../utils';

interface EnterSquareFootageProps extends StepComponentProps {
  dataTemplate: Function;
}

export const EnterSquareFootage = ({ dataTemplate, actions, children }: EnterSquareFootageProps): ReactElement => {
  const { sessionsFN, isLoading } = useContext(GeneralContext);
  const { squareFootage, setWizardState } = useContext(WizardContext);

  const addSquareFootage = () => {
    sessionsFN.put(dataTemplate('home_square_footage', squareFootage), actions.nextStep?.());
    socket.emit('send-event', {
      event: SENDINBLUE_EVENTS.added_square_footage,
      properties: {
        HOME_SQUARE_FOOTAGE: squareFootage,
      },
    });

    actions.nextStep?.();
  };

  const onKeyUpSquareFootage = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      addSquareFootage();
    }
  };

  return (
    <div className="step" data-step="square-footage">
      <h3>What's your home's square footage?</h3>
      <h5>This will help us determine how much air in your home needs to be cleaned.</h5>
      <Loading loading={isLoading}>
        <div className="input">
          <input
            className="square-footage-input simple-input"
            type="number"
            placeholder="Type your answer here..."
            autoComplete="off"
            inputMode="numeric"
            pattern="[0-9]*"
            onKeyUp={onKeyUpSquareFootage}
            value={squareFootage}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => setWizardState('squareFootage', e.target.value)}
            autoFocus={true}
          />
        </div>
        <Footer>
          {children({
            customCallback: addSquareFootage,
            nextDisabled: !squareFootage || isLoading,
            backDisabled: isLoading,
          })}
        </Footer>
      </Loading>
    </div>
  );
};
