import { REGULAR_TIER_LABEL } from '../../config';
import {
  LOADING,
  LOADING_UPGRADE_AVAILABLE,
  GET_UPGRADE_AVAILABLE,
  GET_UPGRADE_AVAILABLE_ERROR,
  POST_CHECK_EMAIL_ERROR,
  POST_CHECK_EMAIL_SUCCESS,
  PUT_SESSION_ERROR,
  PUT_SESSION_SUCCESS,
  GET_ALL_VARIANTS_SUCCESS,
  GET_VARIANTS_SUCCESS,
  GET_PRODUCTS_SUCCESS,
  UPDATE_VARIANTS,
  SET_VARIANTS,
  GET_COUPON_SUCCESS,
  GET_COUPON_ERROR,
  POST_USER_PROFILE_SUCCESS,
  GET_PAYMENT_SUCCESS,
  GET_PAYMENT_ERROR,
  POST_SUBSCRIPTION_SUCCESS,
  POST_SUBSCRIPTION_ERROR,
  POST_CHECKOUT_SESSION_SUCCESS,
  POST_CHECKOUT_SESSION_ERROR,
  UPDATING_VERSION,
  POST_CHECK_EMAIL_LOADING,
  GET_REDIRECT_SUCCESS,
  GET_REDIRECT_ERROR,
  GET_WIZARD_ERROR,
  GET_WIZARD_SUCCESS,
  UPDATE_CUSTOMER_FILTERS_SUCCESS,
  UPDATE_CUSTOMER_FILTERS_ERROR,
  UPDATE_CUSTOMER_FILTERS_LOADING,
  SET_MERV,
} from '../types';

const GeneralReducer = (state: any, action: any) => {
  switch (action.type) {
    case POST_USER_PROFILE_SUCCESS:
      return {
        ...state,
        createdUser: action.payload,
        isLoading: false,
        isError: false,
      };
    case PUT_SESSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    case POST_CHECK_EMAIL_LOADING:
      return {
        ...state,
        isCheckEmailLoading: true,
      };
    case POST_CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        isCheckEmailLoading: false,
        isError: false,
        checkEmailData: { alreadyLoaded: true, user: action.payload },
      };
    case POST_CHECK_EMAIL_ERROR:
      return {
        ...state,
        isCheckEmailLoading: false,
      };

    case PUT_SESSION_ERROR:
    case POST_CHECKOUT_SESSION_ERROR:
    case POST_SUBSCRIPTION_ERROR:
    case GET_REDIRECT_ERROR:
    case GET_WIZARD_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case GET_ALL_VARIANTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        allVariants: action.payload,
      };
    case GET_VARIANTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        productsData: action.payload,
      };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError:false,
        productsData: action.payload.products,
        productsHelper: action.payload.productsHelper,
        mervGrade: action.payload.productsHelper.optionsAvailable[REGULAR_TIER_LABEL].highestMERV,
      }

    // TODO: check where this is being used as params changed
    case SET_VARIANTS:
    case UPDATE_VARIANTS:
      return {
        ...state,
      };
    case SET_MERV:
      return {
        ...state,
        mervGrade: action.payload.newMerv,
      }
    case GET_COUPON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        couponData: action.payload,
      };
    case GET_COUPON_ERROR:
      return {
        ...state,
        isLoadin: false,
        isError: true,
      };
    case POST_CHECKOUT_SESSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        checkoutSessionUrl: action.payload,
      };

    case POST_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        stripeClientSecret: action.payload.paymentIntentSecret,
        intentType: action.payload.intentType,
        subscriptionSuccessUrl: action.payload.subscriptionSuccessUrl,
        tax: action.payload.tax,
      };

    case GET_REDIRECT_SUCCESS: {
      return {
        ...state,
        isError: false,
        isLoading: false,
        redirectUrl: action.payload.res.url,
        sessionId: action.payload.sessionId,
      };
    }

    case GET_WIZARD_SUCCESS: {
      return {
        ...state,
        isError: false,
        isLoading: false,
        wizard: action.payload,
      };
    }

    case GET_PAYMENT_SUCCESS:
      return { ...state, isLoading: false, isError: false, paymentData: action.payload };
    case GET_PAYMENT_ERROR:
      return { ...state, isLoading: false, isError: true };

    case GET_UPGRADE_AVAILABLE:
      return { ...state, isLoadingUpgradeAvailable: false, isError: false, upgradeAvailable: action.payload };
    case GET_UPGRADE_AVAILABLE_ERROR:
      return { ...state, isLoadingUpgradeAvailable: false, isError: true };

    case UPDATING_VERSION:
      return {
        ...state,
        updatingVersion: true,
        isError: false,
      };

    case LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case LOADING_UPGRADE_AVAILABLE:
      return {
        ...state,
        isLoadingUpgradeAvailable: true,
        isError: false,
      };

    case UPDATE_CUSTOMER_FILTERS_LOADING:
      return {
        ...state,
        isLoadingUpdateFilters: true,
        isError: false,
      };

    case UPDATE_CUSTOMER_FILTERS_SUCCESS:
      return {
        ...state,
        isLoadingUpdateFilters: false,
        customerFiltersUpdated: true,
        isError: false,
      };

    case UPDATE_CUSTOMER_FILTERS_ERROR:
      return {
        ...state,
        isLoadingUpdateFilters: false,
        customerFiltersUpdated: false,
        isError: true,
      };

    default:
      return state;
  }
};

export default GeneralReducer;
