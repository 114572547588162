import { ReactElement } from "react";
import { ShopifyProduct } from "../../../interfaces";

interface FilterElementProps {
  className?: string;
  filter: ShopifyProduct;
  label: string;
  carbon: boolean;
}

export const FilterElement = ({ className = "", filter, label, carbon }: FilterElementProps): ReactElement => {
  return (
    <div className={ `filter-element-container ${className}`}>
      <img
        src={ carbon ? filter.filtered_images.carbon?.src || filter.images[0].src : filter.filtered_images.regular?.src || filter.images[1].src }
        alt=""
      ></img>
      <div>
        <h3>{ filter.dimensions } Air Filter</h3>
        <h5>{ label }</h5>
      </div>
    </div>
  );
};
