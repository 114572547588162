import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

const Layout = ({ children }: { children: ReactNode }) => (
  <div className="PageContainer">
    <header id="section-header" className="Header Header--sidebar Header--initialized Header--withIcons" role="banner">
      <div className="Header__Wrapper">
        <div className="Header__FlexItem Header__FlexItem--logo">
          <div className="Header__Logo">
            <a href="http://nanohome.com/" className="Header__LogoLink">
              <img src="https://nano-home.github.io/nano/assets/brand/logotype-E9AA5F.svg" alt="NANO Logo" />
            </a>
          </div>
        </div>

        <div className="headerCTA">
          <Link to={{ pathname: '/login', search: window.location.search }} className="signInBtn">
            Sign In
          </Link>
        </div>
      </div>
    </header>
    <main id="main" role="main">
      {children}
    </main>
  </div>
);

export default Layout;
