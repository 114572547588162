import { useState, useEffect, useContext } from 'react';
import * as FullStory from '@fullstory/browser';
import { useNavigate } from 'react-router-dom';
import { useBeforeunload } from 'react-beforeunload';
// import reactGA from 'react-ga4';

import ipApiService from '../../services/ipApiService';
import {
  isEmail,
  centralTimeDate,
  sessionId as sessionId_utils,
  getParams,
  toCamelCase,
  removeDuplicates,
  STEPS_DATA_CASES,
  isLegacySessionId,
  ACCEPTED_PARAMS,
} from '../../utils';

import { GeneralContext } from '../../store/context/GeneralState';
import { WizardContext } from '../../store/context/wizard/WizardState';

import { INITIAL_STEP } from '../../config';

import { socket } from '../../store/context/socket/SocketContext';

import { ISteps, KeyValuePair, WizardStepIdentifiers } from '../../interfaces';
import { setupWizard } from './WizardSetup';
import { withActions } from './withActions';
import { Loading } from './Loading';
import { toLower } from 'lodash';

const Wizard = () => {
  const STEPS_UPDATE_CASES: KeyValuePair<Function> = {
    email: (value: string) => {
      setWizardState('email', value.replaceAll(' ', '+'));
      if (!sessionIdRedirectValid) validateEmail(value);
    },
    name: (value: string) => {
      addName(value);
      setWizardState('name', value);
    },
    homeType: (value: string) => {
      setWizardState('homeType', value);
    },
    squareFootage: (value: string) => {
      setWizardState('squareFootage', value);
    },
    mervOne: (value: string) => {
      setWizardState('mervOne', value);
    },
    mervTwo: (value: string) => {
      setWizardState('mervTwo', value);
    },
    old: (value: string) => {
      setWizardState('old', value);
    },
    petDander: (value: string) => {
      setWizardState('petDander', value);
    },
    allergens: (value: string) => {
      setWizardState('allergens', value);
    },
    viruses: (value: string) => {
      setWizardState('viruses', value);
    },
    smoke: (value: string) => {
      setWizardState('smoke', value);
    },
    pollution: (value: string) => {
      setWizardState('pollution', value);
    },
    phoneNumber: (value: string) => {
      setWizardState('phoneNumber', value);
    },
    count: (value: string) => {
      setWizardState('count', value);
    },
    filterFields: (value: string) => {
      setWizardState('filterFields', JSON.parse(value));
    },
    addressLine1: (value: string) => {
      setWizardState('addressLine1', value);
    },
    addressLine2: (value: string) => {
      setWizardState('addressLine2', value);
    },
    addressState: (value: string) => {
      setWizardState('addressState', value);
    },
    addressCity: (value: string) => {
      setWizardState('addressCity', value);
    },
    addressZipCode: (value: string) => {
      setWizardState('addressZipCode', value);
    },
    profileCompleted: (value: boolean) => {
      if (value) navigate(`/login`);
    },
  };

  const DEFAULT_STEPS_UPDATE_CASES = (value: any) => {};

  const navigate = useNavigate();

  // CONTEXT
  const {
    env,
    sessionsFN,
    sendinblueFN,
    checkEmailData,
    stripeClientSecret,
    sessionId: sessionIdFromRedirect,
    wizard,
    getWizard,
    isLoading,
  } = useContext(GeneralContext);

  const {
    email,
    name,
    mervOne,
    old,
    smoke,
    viruses,
    allergens,
    pollution,
    petDander,
    count,
    addressState,
    addressCity,
    optIn,
    filterSizeUnknown,
    setWizardState,
  } = useContext(WizardContext);

  // STATE
  const [sessionIdRedirectValid] = useState(
    sessionIdFromRedirect ? (!isLegacySessionId(sessionIdFromRedirect) ? sessionIdFromRedirect : null) : null,
  );
  const [sessionId] = useState<string>(sessionIdRedirectValid || sessionId_utils);
  const [queryParams] = useState(getParams());
  const [isSkipped, setIsSkipped] = useState<boolean>(false);
  const [step, setStep] = useState<number>(INITIAL_STEP);
  const [ip, setIP] = useState<string>('');
  const [mediumLandingPage, setMediumLandingPage] = useState<string>('');
  const [campaingLandingPage, setCampaingLandingPage] = useState<string>('');
  const [wizardId, setWizardId] = useState<number | string | null>(null);
  const [wizardSteps, setWizardSteps] = useState<ISteps[] | null>(null);
  const [progress, setProgress] = useState('0%');

  if (!(env === "development")) 
    FullStory.identify(sessionId);

  // EFFECTS
  useEffect(() => {
    if (wizard) {
      setWizardId(wizard.id);
      // TODO need to review this logic of setting components
      const wizardMapping = new Map([
        [WizardStepIdentifiers.EMAIL, { props: {}, actions: { nextStep: validateEmail } }],
        [WizardStepIdentifiers.NAME, { props: { dataTemplate }, actions: { nextStep } }],
        [
          WizardStepIdentifiers.FILTERS_PROFILE,
          {
            props: { sessionId, dataTemplate },
            actions: {
              backStep,
              nextStep: (scheduleFilterHelp: boolean) => {
                if (scheduleFilterHelp) {
                  skipFilterProfile();
                } else {
                  nextStep();
                }
              },
              skipStep: nextStep,
            },
          },
        ],
        [
          WizardStepIdentifiers.FILTERS_PROFILE_WITH_DROPDOWN,
          {
            props: { sessionId, dataTemplate },
            actions: {
              backStep,
              nextStep: (scheduleFilterHelp: boolean) => {
                if (scheduleFilterHelp) {
                  skipFilterProfile();
                } else {
                  nextStep();
                }
              },
              skipStep: nextStep,
            },
          },
        ],
        [
          WizardStepIdentifiers.MULTI_SELECT, { props: { sessionId, dataTemplate }, actions: { backStep, nextStep } }
        ],
        [
          WizardStepIdentifiers.PROFILE_SUMMARY,
          {
            props: { sessionId, dataTemplate },
            actions: { backStep, nextStep: filterSizeUnknown ? () => setStep(wizard.steps.length - 1) : nextStep },
          },
        ],
        [
          WizardStepIdentifiers.HOME_TYPE,
          { props: { dataTemplate, hideBackStep: true }, actions: { backStep, nextStep, skipStep: skipHomeType } },
        ],
        [
          WizardStepIdentifiers.SQUARE_FOOTAGE,
          { props: { dataTemplate }, actions: { backStep, nextStep, skipStep: skipSquareFootage } },
        ],
        [WizardStepIdentifiers.ACSYSTEM_AGE, { props: {}, actions: { backStep, nextStep } }],
        [WizardStepIdentifiers.CONCERNS, { props: {}, actions: { backStep, nextStep, skipStep: nextStep } }],
        [
          WizardStepIdentifiers.STOCK_SELECTION,
          { props: { sessionId, dataTemplate }, actions: { backStep, nextStep, skipStep: () => setStep(wizard.steps.length - 1) } },
        ],
        [WizardStepIdentifiers.ORDER, { props: { sessionId, dataTemplate }, actions: { backStep, nextStep } }],
        [
          WizardStepIdentifiers.SCHEDULE_FILTER_ASSITANCE,
          {
            props: { dataTemplate, sessionId },
            actions: {
              backStep,
              nextStep: (scheduleFilterHelp: boolean) => {
                if (scheduleFilterHelp) {
                  setStep(wizard.steps.length - 1);
                } else {
                  returnToProfileSummary();
                }
              },
            },
          },
        ],
        [
          WizardStepIdentifiers.CONFIRMATION,
          { props: { isSkipped, sessionId, isAdmin: queryParams.some(({ key }) => key === 'admin') }, actions: {} },
        ],
        [WizardStepIdentifiers.SHIPPING, { props: { sessionId }, actions: { backStep, nextStep } }],
      ]);
      setWizardSteps(setupWizard(wizard, wizardMapping).steps as ISteps[]);
    }
    // eslint-disable-next-line
  }, [wizard, step, email, optIn]);

  useEffect(() => {
    if (wizard) {
      // GA event for wizard A/B version
      window.gtag('event', 'wizard_version', {
        name: wizard.name,
        wizard_id: wizard.id,
      });
    }
  }, [wizard]);

  useEffect(() => {
    if (queryParams.length > 0 && !queryParams.map((param) => param.key).includes('wizard_id')) {
      fillDataWithQuery(queryParams);
      skipFilledSteps(queryParams);

      const landingPageMediumParam = queryParams.find((param) => param.key === 'utm_medium');
      const landingPageCampaignParam = queryParams.find((param) => param.key === 'utm_campaign');
      if (landingPageMediumParam) setMediumLandingPage(landingPageMediumParam?.value);
      if (landingPageCampaignParam) setCampaingLandingPage(landingPageCampaignParam?.value);
    }
    const wizardIdParam = queryParams.find((param) => param.key === 'wizard_id')?.value || '';
    getWizard(wizardIdParam);
    // eslint-disable-next-line
  }, [queryParams]);

  useEffect(() => {
    document.querySelector('body')?.classList.add('loaded');
  }, []);

  useEffect(() => {
    if (wizardSteps) {
      const progress = ((step + 1) / wizardSteps.length) * 100;
      setProgress(`${progress}%`);

      // GA event for wizard step
      window.gtag('event', `${toLower(wizardSteps[step].type)}_step`, {
        firebase_session: sessionId,
      });
    }

    socket.emit('send-last-step', step);

    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [step, wizardSteps]);

  useEffect(() => {
    if (ip !== '' || addressCity !== '' || addressState !== '')
      sessionsFN.put({
        sessionId,
        sessionData: [
          { key: 'user_ip_address', value: ip },
          { key: 'user_city', value: `${addressCity}, ${addressState}` },
          { key: 'user_navigator', value: navigator.appVersion },
          { key: 'user_resolution', value: window.screen.width + 'x' + window.screen.height },
          { key: 'timestamp', value: centralTimeDate() },
        ],
      });
    // eslint-disable-next-line
  }, [ip, addressCity, addressState]);

  useEffect(() => {
    proceedToCheckout();
    // eslint-disable-next-line
  }, [stripeClientSecret]);

  const proceedToCheckout = async () => {
    if (stripeClientSecret) {
      const orderStep = wizardSteps?.find((s) => {
        return s.type === WizardStepIdentifiers.ORDER;
      });

      if (orderStep) {
        setStep(orderStep?.position - 1);
      } else {
        setStep(6); // default wizard Order step position is 6
      }
    }
  };

  useEffect(() => {
    ipApiService(
      (response: any) => {
        setIP(response.data.ip);
        setWizardState('addressCity', response.data.city);
        setWizardState('addressState', response.data.region_code);
      },
      (error: any) => {
        console.log('Error getting ip data', error);
      },
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (mervOne !== '') {
      sessionsFN.put(dataTemplate('merv_known', mervOne));
      sendinblueFN.updateContact({
        email,
        attributes: {
          MERV_KNOWN: mervOne,
        },
      });
    }
    // eslint-disable-next-line
  }, [mervOne]);

  useEffect(() => {
    if (old !== '') {
      sessionsFN.put(dataTemplate('home_system_old', old));
      sendinblueFN.updateContact({
        email,
        attributes: {
          HOME_SYSTEM_OLD: old,
        },
      });
    }
    // eslint-disable-next-line
  }, [old]);

  useEffect(() => {
    if (smoke !== '') {
      sessionsFN.put(dataTemplate('user_concern_smoke', smoke));
      sendinblueFN.updateContact({
        email,
        attributes: {
          USER_CONCERN_SMOKE: smoke,
        },
      });
    }
    // eslint-disable-next-line
  }, [smoke]);

  useEffect(() => {
    if (viruses !== '') {
      sessionsFN.put(dataTemplate('user_concern_viruses', viruses));
      sendinblueFN.updateContact({
        email,
        attributes: {
          USER_CONCERN_VIRUSES: viruses,
        },
      });
    }
    // eslint-disable-next-line
  }, [viruses]);

  useEffect(() => {
    if (allergens !== '') {
      sessionsFN.put(dataTemplate('user_concern_allergens', allergens));
      sendinblueFN.updateContact({
        email,
        attributes: {
          USER_CONCERN_ALLERGENS: allergens,
        },
      });
    }
    // eslint-disable-next-line
  }, [allergens]);

  useEffect(() => {
    if (pollution !== '') {
      sessionsFN.put(dataTemplate('user_concern_pollution', pollution));
      sendinblueFN.updateContact({
        email,
        attributes: {
          USER_CONCERN_POLLUTION: pollution,
        },
      });
    }
    // eslint-disable-next-line
  }, [pollution]);

  useEffect(() => {
    if (petDander !== '') {
      sessionsFN.put(dataTemplate('user_concern_pet_dander', petDander));
      sendinblueFN.updateContact({
        email,
        attributes: {
          USER_CONCERN_PET_DANDER: petDander,
        },
      });
    }
    // eslint-disable-next-line
  }, [petDander]);

  useEffect(() => {
    if (count !== '') {
      sessionsFN.put(dataTemplate('count', count));
      sendinblueFN.updateContact({
        email,
        attributes: {
          COUNT: count,
        },
      });
    }
    // eslint-disable-next-line
  }, [count]);

  // step 0 -> step 1
  useEffect(() => {
    if (checkEmailData.alreadyLoaded && email) {
      if (checkEmailData.user.userExists) {
        setWizardState('email', '');
        let wizardIdParam = queryParams.find((param) => param.key === 'wizard_id')?.value;
        return navigate(
          `/login?email=${encodeURIComponent(email)}${wizardIdParam ? `&wizard_id=${wizardIdParam}` : ''}`,
        );
      }

      const condition = !(queryParams.length > 0) || queryParams.some(({ key }) => ACCEPTED_PARAMS.includes(key));
      sessionsFN.put(dataTemplate('user_email', email), condition ? nextStep() : () => {});
    }
    // eslint-disable-next-line
  }, [checkEmailData]);

  // FUNCTIONS

  // -- MAGICK LINK START --
  const fillDataWithQuery = (queryParams: KeyValuePair<string>[]) => {
    queryParams.forEach(({ key, value }) => {
      let camelizedKey: string = toCamelCase(key);
      STEPS_UPDATE_CASES[camelizedKey] ? STEPS_UPDATE_CASES[camelizedKey](value) : DEFAULT_STEPS_UPDATE_CASES(value);
    });
  };

  const skipFilledSteps = (queryParams: KeyValuePair<string>[]) => {
    let foundSteps: number[] = [];
    queryParams.forEach(({ key }) => {
      Object.keys(STEPS_DATA_CASES).forEach((keyStep) => {
        if (STEPS_DATA_CASES[keyStep].includes(toCamelCase(key))) foundSteps.push(parseInt(keyStep));
      });
    });

    foundSteps.sort();
    foundSteps = removeDuplicates(foundSteps);

    let lastStep = 0;
    foundSteps.forEach((step, index) => {
      if (step !== index) {
        lastStep = index;
        return;
      }
    });
    setStep(foundSteps.length > 0 ? (lastStep === 0 ? foundSteps[foundSteps.length - 1] + 1 : lastStep) : 0);
  };
  // -- MAGICK LINK END --

  const skipFilterProfile = () => {
    const callStep = wizardSteps?.find((s) => {
      return s.type === WizardStepIdentifiers.SCHEDULE_FILTER_ASSITANCE;
    });

    if (callStep) {
      setStep(callStep?.position - 1);
    } else {
      nextStep();
    }
  };

  const returnToProfileSummary = () => {
    const profileSummaryStep = wizardSteps?.find((s) => {
      return s.type === WizardStepIdentifiers.PROFILE_SUMMARY;
    });

    if (profileSummaryStep) {
      setStep(profileSummaryStep?.position - 1);
    } else {
      nextStep();
    }
  };

  const skipHomeType = () => {
    setWizardState('homeType', null);
    nextStep();
  };

  const skipSquareFootage = () => {
    setWizardState('squareFootage', null);
    nextStep();
  };

  const nextStep = () => {
    let newStep = step + 1;
    const upcomingStep = wizardSteps && wizardSteps[newStep];
    if (upcomingStep?.type === WizardStepIdentifiers.SCHEDULE_FILTER_ASSITANCE && !filterSizeUnknown) newStep++;

    setStep(newStep);
    setIsSkipped(false);
  };

  const backStep = () => {
    if (!isSkipped) setStep(step - 1);
    else {
      setStep(5);
      setIsSkipped(false);
    }
  };

  const createContact = (sessionId: string, customerEmail: string) => {
    sessionsFN.postCheckEmail({ sessionId, customerEmail });
    sessionsFN.put(dataTemplate('wizard_id', wizardId));
    sessionsFN.put(dataTemplate('profile_completed', false));
    sessionsFN.put(dataTemplate('opt_in', optIn));

    // Sets email & session for further sendinblue events
    socket.emit('send-profile-data', { sessionId, customerEmail });

    // Create contact in Sendinblue
    sendinblueFN.createContact({
      email: customerEmail,
      emailBlacklisted: !optIn,
      smsBlacklisted: !optIn,
      attributes: {
        PROFILE_COMPLETED: false,
        NO_STOCK: false,
        MAGIC_LINK: `https://app.nanohome.com/r/${sessionId}`,
        A_B_C: mediumLandingPage,
        CAMPAIGN: campaingLandingPage,
        WIZARD_VERSION: wizardId,
      },
    });
  };

  const validateEmail = (emailValue: string): boolean => {
    const customerEmail = emailValue || email;
    const result = isEmail(customerEmail);
    if (result) {
      createContact(sessionId, customerEmail);
    } else {
      alert('Please enter an email address.');
    }
    return result;
  };

  const addName = (userName: string | null = null) => {
    const user_name = userName || name;
    if (user_name !== '') {
      sessionsFN.put(dataTemplate('user_name', user_name), !userName ? nextStep() : () => {});
      sendinblueFN.updateContact({ email, attributes: { FIRSTNAME: user_name } });
    }
  };

  const dataTemplate = (key: any, value: any) => {
    return {
      sessionId,
      sessionData: [
        {
          key,
          value,
        },
      ],
    };
  };

  useBeforeunload(() => {
    if (email !== '') {
      sendinblueFN.disconnectUser({ last_step: step, email });
      socket.emit('handle-disconnection', {
        last_step: step,
        email,
      });
    }

    if (wizardSteps) {
      window.gtag('event', 'wizard_abandoned', {
        wizard_step: wizardSteps[step].type,
        firebase_session: sessionId,
      });
    }
  });

  return (
    <div className="wizardContent">
      <div className="start table">
        <div className="middle">
          <Loading loading={isLoading && (!wizardSteps || !wizardSteps[step])} />
          <>
            {wizardSteps &&
              wizardSteps[step] &&
              withActions(
                wizardSteps[step].content?.body,
                wizardSteps[step].content?.props,
                wizardSteps[step].actions,
                wizardSteps[step].required,
              )}
          </>
        </div>
      </div>
      <div className="progressWrapper">
        <div className="progressContainer">
          <div className="progressBar" style={{ width: progress }}></div>
        </div>
      </div>
    </div>
  );
};

export default Wizard;
