import { every } from 'lodash';
import { ReactElement, useContext } from 'react';
import { Link } from 'react-router-dom';

import AuthContext from '../../store/context/auth/AuthContext';

const UserInfo = (): ReactElement => {
  // CONTEXT
  const { userData } = useContext(AuthContext);

  const subscribed = userData?.customer?.lastSubscription?.subscribedAt;
  const filters = userData?.customer?.filters || [];
  const canCompleteAccount =
    !subscribed && filters?.length > 0 && every(filters, (filter) => !!filter.selectedVariantSize);
  const calculateRedirection =
    filters.length > 0 && every(filters, (filter) => !!filter.selectedVariantSize) ? 'activate' : 'filters';

  return (
    <Link to="/dashboard/profile" className="dashboardUserInfoAnchor">
      <div className="dashboardUserInfo">
        <div className="current-user">
          <div
            className="userPhoto"
            style={{
              backgroundImage: 'url(https://cdn.pixabay.com/photo/2018/11/13/21/43/avatar-3814049_1280.png)',
            }}
          ></div>
          <div className="userInfo name">
            {userData?.customer?.user?.name && userData.customer.user.name.capitalizeFirstCharacter()}
          </div>
          <div className="userInfo email">{userData?.customer?.user.email && userData.customer.user.email}</div>
          {canCompleteAccount && (
            <div className="completeAccountHeaderContainer">
              <Link to={`/dashboard/${calculateRedirection}`} className="completeAccountHeaderBtn">
                Complete Profile!
              </Link>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default UserInfo;
