import { DataField } from '../../../interfaces';
import './forms.css';
import { ChangeEventHandler } from 'react';

export interface DataInputField extends DataField {
  value: any;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur: ChangeEventHandler<HTMLInputElement>;
}

const InputField = ({ placeholder, inputName, value, onChange, onBlur, type, disabled = false }: DataInputField) => {
  return (
    <input
      name={inputName}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      autoComplete="off"
      className="form-control"
      disabled={disabled}
    />
  );
};

export default InputField;
