import React, { useEffect, useContext, ReactElement, useState } from 'react';
import Spinner from '../../common/spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import { GeneralContext } from '../../../store/context/GeneralState';
import AuthContext from '../../../store/context/auth/AuthContext';
import { placeholders } from '../../../utils';

const Filters = (): ReactElement => {
  const navigate = useNavigate();
  const { productsFN, isLoadingUpdateFilters, isError } = useContext(GeneralContext);

  const [filterFields, setFilterFields]: any = useState([{ inputSize: '', label: '' }]);
  const [customerFiltersUpdated, setCustomerFiltersUpdated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const {
    AuthFN,
    userData: { customer },
  } = useContext(AuthContext);

  useEffect(() => {
    if (customerFiltersUpdated) {
      setCustomerFiltersUpdated(false);
      AuthFN.verify();
      navigate('/dashboard');
    }
    // eslint-disable-next-line
  }, [customerFiltersUpdated]);

  useEffect(() => {
    if (customer?.filters) {
      setFilterFields(customer?.filters);
    }

    // eslint-disable-next-line
  }, [customer]);

  const handleFilterInputsChange = (event: React.ChangeEvent<HTMLInputElement>, field: string, index: number) => {
    setFilterFieldsData(event.target.value, index, field);
  };

  const setFilterFieldsData = (value: string, index: number, field: string) => {
    let data = [...filterFields];
    data[index][field] = value;

    setFilterFields(data);
  };

  const handleFilterDimensionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    inputSizeIndex: number,
    index: number,
  ) => {
    let newValue = event.target.value;
    if (inputSizeIndex === 2 && !["", "1", "2", "4"].includes(newValue)) return;
    let inputSize = filterFields[index].inputSize;
    let dimensions = inputSize.split('x');
    dimensions[inputSizeIndex] = newValue;
    inputSize = dimensions.join('x');
    setFilterFieldsData(inputSize, index, 'inputSize');
  };

  const handleAddFilter = () => {
    const newFilter = {
      inputSize: 'xx1',
      selectedVariantSize: '',
      label: '',
      position: filterFields.length + 1,
    };

    setFilterFields(filterFields.concat([newFilter]));
  };

  const handleRemoveFilter = (event: any, position: number) => {
    event.preventDefault();
    let newFilterFields = Object.assign([] as any[], filterFields);
    newFilterFields.splice(position - 1, 1);
    newFilterFields = newFilterFields.map((filter: any, index: number) => {
      filter.position = index + 1;
      return filter;
    });
    setFilterFields([...newFilterFields]);
  };

  const saveFilters = async () => {
    const result = await productsFN.customerUpdateFilters(customer.id, filterFields, false);
    if (result.success) {
      setCustomerFiltersUpdated(true);
      setErrorMessage(null);
    } else {
      setErrorMessage(result.message);
    }
  };

  return (
    <div className="wrapper">
      <div className="inner-wrapper">
        <div className="wizardContent">
          <div className="start table">
            <div className="middle">
              <div className="step" data-step="filters">
                <h3>Build your home’s filtration profile.</h3>
                <h5>This is literally the last time you will ever need to look-up the sizes of your home's filters.</h5>
                <h5>Type filter dimensions (width x length x depth) in inches.</h5>
                {isLoadingUpdateFilters ? (
                  <Spinner />
                ) : (
                  <div>
                    <div className="guide">
                      <img src="https://cdn.shopify.com/s/files/1/0611/2120/0349/products/air-filter-01.png" alt="" />
                      <div className="dim w">
                        <span>W</span>
                      </div>
                      <div className="dim l">
                        <span>L</span>
                      </div>
                      <div className="dim d">
                        <span>D</span>
                      </div>
                    </div>
                    <div className="filters-container">
                      {filterFields.map((filter: any, index: number) => {
                        const filterDimensions = filter.inputSize.split('x');
                        return (
                          <div key={`filter-${index}`} className="input-row" data-filter={index + 1}>
                            <div className="input label">
                              <input
                                name={`filter_${index + 1}_label`}
                                className="label-input simple-input"
                                type="text"
                                placeholder={placeholders[index]}
                                autoComplete="off"
                                autoCapitalize="words"
                                value={filter.label}
                                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handleFilterInputsChange(e, 'label', index)
                                }
                              />
                            </div>
                            <div className="input dimensions">
                              <input
                                name={`filter_${index + 1}_x`}
                                className="search-box x-search-box"
                                type="number"
                                placeholder="W"
                                autoComplete="off"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                value={filterDimensions[0] || ""}
                                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handleFilterDimensionChange(e, 0, index)
                                }
                              />
                              <span className="mdi mdi-close"></span>
                              <input
                                name={`filter_${index + 1}_y`}
                                className="search-box y-search-box"
                                type="number"
                                placeholder="L"
                                autoComplete="off"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                value={filterDimensions[1] || ""}
                                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handleFilterDimensionChange(e, 1, index)
                                }
                              />
                              <span className="mdi mdi-close"></span>
                              <input
                                name={`filter_${index + 1}_z`}
                                className="search-box z-search-box"
                                type="number"
                                placeholder="D"
                                autoComplete="off"
                                pattern="(1|2|4)"
                                value={filterDimensions[2] || ""}
                                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handleFilterDimensionChange(e, 2, index)
                                }
                              />
                              {index > 0 && (
                                <button
                                  className="remove-filter-button"
                                  onClick={(e: any) => handleRemoveFilter(e, index + 1)}
                                  title="Remove filter"
                                >
                                  <span className="mdi mdi-close"></span>
                                </button>
                              )}
                            </div>
                          </div>
                        );
                      })}
                      <div className="add-filter-row" style={{ display: 'flex', justifyContent: 'center' }}>
                        <button
                          className="add-filter-button"
                          disabled={filterFields.length >= 6}
                          onClick={handleAddFilter}
                          title={filterFields.length >= 6 ? 'Max of 6 filters per account' : 'Add more filters'}
                        >
                          <span className="mdi mdi-plus"></span>
                        </button>
                      </div>
                    </div>
                    <div className="buttons-footer">
                      <button className="button next" disabled={isLoadingUpdateFilters} onClick={saveFilters}>
                        <span className="mdi mdi-loading mdi-spin"></span>Save Filters
                      </button>
                    </div>
                  </div>
                )}
                {isError && (
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <label style={{ padding: '0 10px', fontWeight: 400, color: 'red' }}>
                      {`Error saving filters: ${errorMessage || 'please check filter sizes.'}`}
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
