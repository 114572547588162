import { ReactElement } from "react";

interface SelectableContainerSmallProps {
  className?: string;
  title: string;
  onClick: Function;
  children?: ReactElement;
}

export const SelectableContainerSmall = ({ className = "", title, onClick, children }: SelectableContainerSmallProps): ReactElement => {
  return (
    <div className={ `selectable-small-container ${className}` }>
      <div className="selector-element" onClick={ (_e) => onClick() }></div>
      <div className="title-container">
        <h4>{ title }</h4>
      </div>
      <div className="data-container">
        { children }
      </div>
    </div>
  );
};
