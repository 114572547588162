import { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { Loading } from '../Loading';
import { Footer } from '../Footer';
import { GeneralContext } from '../../../store/context/GeneralState';
import { WizardContext } from '../../../store/context/wizard/WizardState';
import * as FullStory from '@fullstory/browser';

import {
  ECO_DELIVERY_DISCOUNT,
  PREMIUM_TIER_LABEL,
  REGULAR_TIER_LABEL,
  SUPPORTED_MERV_VARIANTS,
} from '../../../config';

import { calculateNewFrequencyPrice, productTierLabel, SENDINBLUE_EVENTS } from '../../../utils';
import { ShopifVariant, ShopifyProduct, SessionData, StepComponentProps } from '../../../interfaces';
import { socket } from '../../../store/context/socket/SocketContext';
import { FilterElement } from '../../common/elements/FilterElement';
import { ShippingScheduleElement } from '../../common/elements/ShippingScheduleElement';
import { SubscriptionPrice } from '../../common/elements/containers/SubscriptionPrice';
import { Box, Modal } from '@mui/material';
import { SelectableContainerSmall } from './SelectableContainerSmall';

interface StockSelectionProps extends StepComponentProps {
  sessionId: string;
  dataTemplate: Function;
}

export const StockSelection = ({ sessionId, dataTemplate, actions, children }: StockSelectionProps): ReactElement => {
  const { env, sendinblueFN, PaymentFN, productsFN, isLoading, productsData, mervGrade, productsHelper, couponData, sessionsFN} = useContext(GeneralContext);

  const {
    count,
    email,
    name,
    homeType,
    squareFootage,
    mervOne,
    mervTwo,
    old,
    smoke,
    viruses,
    allergens,
    pollution,
    petDander,
    phoneNumber,
    filterFields,
    subscriptionInterval,
    premiumUpgrade,
    couponCode,
    requestedCode,
    ecoDelivery,
    clientSurvey,
    setWizardState,
  } = useContext(WizardContext);

  const tierLabel = productTierLabel(premiumUpgrade);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOptions, setModalOptions] = useState({
    mervGrade: clientSurvey.hvacOld ? productsHelper.optionsAvailable[tierLabel].lowestMERV : mervGrade,
    carbon: premiumUpgrade,
    frequency: subscriptionInterval
  });

  const odorRef = useRef<null | HTMLDivElement>(null);
  const shippingRef = useRef<null | HTMLDivElement>(null);

  const modalTierLabel = productTierLabel(modalOptions.carbon);

  useEffect(() => {
    if (productsData.length < filterFields.length) {
      sendinblueFN.updateContact({
        email,
        attributes: {
          NO_STOCK: true,
        },
      });
      socket.emit('send-event', { event: SENDINBLUE_EVENTS.out_of_stock, properties: { NO_STOCK: true } });
      actions.skipStep?.();
    } else socket.emit('send-event', { event: SENDINBLUE_EVENTS.enter_cart, properties: {} });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (productsData.length === 1 && productsData[0].dimensions.split("x")[2] === '1') setWizardState("ecoDelivery", true);
    if (clientSurvey.hvacOld) {
      productsFN.setMerv(productsHelper.optionsAvailable[tierLabel].lowestMERV);
      setModalOptions({ ...modalOptions, mervGrade: productsHelper.optionsAvailable[tierLabel].lowestMERV });
    }
    if (clientSurvey.odors) {
      setWizardState("premiumUpgrade", true);
      setModalOptions({ ...modalOptions, carbon: true });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(couponData.id)
      sessionsFN.put(dataTemplate("coupon_id", couponData.id));
  }, [couponData.id, sessionsFN, dataTemplate]);

  const handleEcoDeliveryChange = () => {
    const ecoDeliverySet = !ecoDelivery;
    setWizardState('ecoDelivery', ecoDeliverySet);
  };

  const handleCoupon = () => {
    sessionsFN.put(dataTemplate("coupon_code", couponCode));
    PaymentFN.getCoupon(couponCode);
    setWizardState('requestedCode', couponCode);
  };
  
  const sendFiltersToFirebase = async () => {
    const sessionData: SessionData[] = [];
    const userProducts: ShopifVariant[] = productsData.map((p: ShopifyProduct) => {
      return p.variants.find((v: ShopifVariant) => v.option1 === mervGrade && v.option2 === tierLabel) as ShopifVariant;
    });
    userProducts.forEach((v: ShopifVariant, index: number) => {
      sessionData.push(
        { key: `filter_${index + 1}_selection`, value: v.id },
        { key: `filter_${index + 1}_selection_size`, value: v.title },
        { key: `filter_${index + 1}_product_id`, value: v.product_id, },
      );
    });

    await sessionsFN.put({sessionId, sessionData});
  };

  const orderNow = async () => {
    if (!(env === "development"))
      registerFullStory();
    await sendFiltersToFirebase();
    await sessionsFN.postUserProfile(sessionId);
    await PaymentFN.postSubscription(subscriptionInterval, email, premiumUpgrade, ecoDelivery, mervGrade, couponData.id);
    actions.nextStep?.();
  };

  const registerFullStory = () => {
    FullStory.setUserVars({
      email,
      name,
      homeType,
      squareFootage,
      mervOne,
      mervTwo,
      old,
      smoke,
      viruses,
      allergens,
      pollution,
      petDander,
      phoneNumber,
      count,
    });
  };

  let couponMessage = "";
  switch (couponData.status) {
    case 200:
      couponMessage = `Coupon <b>${couponData.name}</b> applied.`;
      break;
    case 404:
      couponMessage = `Seems like we couldn't find coupon <b>${requestedCode}</b>, please try again.`;
      break;
    case 500:
      couponMessage = couponData.message;
      break;
  }

  const mervOptionsTitles = ["Clean", "Cleaner", "Cleanest"];

  const handleModalCancel = () => {
    setModalOptions({
      mervGrade: mervGrade,
      carbon: premiumUpgrade,
      frequency: subscriptionInterval,
    });
    setModalOpen(false);
  };

  const handleModalAccept = () => {
    productsFN.setMerv(modalOptions.mervGrade);
    setWizardState("premiumUpgrade", modalOptions.carbon);
    setWizardState("subscriptionInterval", modalOptions.frequency);
    setModalOpen(false);
  };

  return (
    <div className="step" data-step="confirm">
      <h5>Based on your location, HVAC system, and filtering needs</h5>
      <h3>We've found the best filters for you!</h3>
      <Loading loading={isLoading}>
        <div>
          <div className="carousel-container">
            <div className="carousel">
              <div className="items">
                { productsData.map((product, i) => {
                  return (
                    <FilterElement
                      key={ i }
                      className="carousel-item"
                      filter={ product }
                      label = { filterFields[i][`filter_${i + 1}_label`] || `Filter #${i + 1}` }
                      carbon={ premiumUpgrade }
                    ></FilterElement>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="filter-description">
            <div><b></b>{ premiumUpgrade ? " Carbon " : " Regular " }filter</div>
            <div><b></b> MERV { mervGrade }</div>
            <div><b></b> Change every { subscriptionInterval } month{ subscriptionInterval > 1 ? "s" : "" }</div>
            <div><b></b> Free shipping</div>
          </div>
          <div className="frequencyCopyContainer">
            {productsData.length === 1 && productsData[0].dimensions.split("x")[2] === '1' && (
              <div
                className={ecoDelivery ? 'frequencyCopy selected' : 'frequencyCopy'}
                onClick={handleEcoDeliveryChange}
              >
                <span className="mdi mdi-checkbox-blank-outline first-line-icon"></span>
                <span className="mdi mdi-checkbox-marked first-line-icon"></span>
                <div>
                  Save
                  <b> ${`${(productsHelper.variantsTotal[tierLabel][mervGrade] * ECO_DELIVERY_DISCOUNT / subscriptionInterval).toFixed(2)}`} </b> 
                  with "Eco Delivery".
                  <div className="nano-tooltip">
                    <i></i>
                    <span className="nano-tooltip-text topTooltip blueAccent">
                      <p>
                        <b>"Eco Delivery"</b> helps us be more environment friendly. The fewer trips we make to your
                        home, the more we can save on boxes and gasoline.
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <ShippingScheduleElement ecoDelivery={ ecoDelivery } subscriptionInterval={ subscriptionInterval } productsCount={ productsData.length }></ShippingScheduleElement>
          <button className="primary-button" onClick={ () => setModalOpen(true) }>Change</button>
          <SubscriptionPrice
            className="selected"
            price={ "$" + calculateNewFrequencyPrice(productsHelper.variantsTotal[tierLabel][mervGrade], subscriptionInterval, ecoDelivery).toFixed(2) }
            frequency={ subscriptionInterval }
          ></SubscriptionPrice>
          <div className='coupon-container'>
            <div className="coupon-code">
              <div>Apply coupon</div>
              <div>
                <input
                  type="text"
                  value={couponCode}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => setWizardState('couponCode', e.target.value)}
                ></input>
              </div>
              <div className='apply' onClick={handleCoupon}>Apply</div>
            </div>
            <div dangerouslySetInnerHTML={{__html: couponMessage}}></div>
            {couponData.description && (
              <div>{couponData.description}</div>
            )}
          </div>
        </div>
        <Footer>
          {children({
            customCallback: orderNow,
            nextButtonProps: {
              customSpan: true,
              customText: 'Proceed to checkout',
            },
            nextDisabled: isLoading || subscriptionInterval <= 0,
            backDisabled: isLoading,
          })}
        </Footer>
      </Loading>

      <Modal className="modal-container" open={ modalOpen } onClose={ () => setModalOpen(false) }>
        <Box className="modal-content">
          <div className="recommended-vs-current">
            <div className="recommended">
              <SubscriptionPrice
                className="compare-item"
                price={ "$" + calculateNewFrequencyPrice(productsHelper.variantsTotal[tierLabel][mervGrade], subscriptionInterval, ecoDelivery).toFixed(2) }
                frequency={ subscriptionInterval }
              ></SubscriptionPrice>
              <span>Recommended</span>
            </div>
            <div>
              <SubscriptionPrice
                className="compare-item"
                price={ "$" + calculateNewFrequencyPrice(productsHelper.variantsTotal[modalTierLabel][modalOptions.mervGrade], modalOptions.frequency, ecoDelivery).toFixed(2) }
                frequency={ modalOptions.frequency }
              ></SubscriptionPrice>
              <span>Current Selection</span>
            </div>
          </div>

          <div>
          <div className="merv-selection modal-element">
            <b>
              MERV
              <div className="nano-tooltip">
                <i></i>
                <span className="nano-tooltip-text topTooltip blueAccent">
                  <p>
                    Some A/C systems may not be able to perform efficeintly with a MERV rating higher than 8 (usually older A/C systems built before 2010).
                  </p>
                </span>
              </div>
            </b> 
            Choose how well should your filter perform.
            <div className="merv-options-container">
              { SUPPORTED_MERV_VARIANTS.map((merv, i) => {
                if (productsHelper.optionsAvailable[modalTierLabel][merv]) {
                  return (
                    <SelectableContainerSmall
                      key={ i }
                      className={ modalOptions.mervGrade === merv ? "selected" : "" }
                      title={ mervOptionsTitles[i] }
                      onClick={ () => {
                        setModalOptions({...modalOptions, mervGrade: merv});
                        odorRef.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
                      }}
                    >
                      <span>MERV rating of { merv }</span>
                    </SelectableContainerSmall>
                  );
                } else {
                  return ""
                }
              })}
            </div>
          </div>

          <div ref={ odorRef } className="odor-elimination-layer modal-element">
            <p><b>Odor Elimination Layer.</b> Choose if you want to filter out smells.</p>
            <div>
              <img
                src={ modalOptions.carbon ? productsData[0].filtered_images.carbon?.src || productsData[0].images[0].src : productsData[0].filtered_images.regular?.src || productsData[0].images[1].src }
                width="150"
                height="150"
                alt=""
              ></img>
              <div className="tier-selection">
                <div
                  className={ modalOptions.carbon ? "" : "selected" }
                  onClick={ () => {
                    setModalOptions({...modalOptions, carbon: false});
                    shippingRef.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
                  }}
                >{ REGULAR_TIER_LABEL }</div>
                <div
                  className={ modalOptions.carbon ? "selected" : "" }
                  onClick={ () => {
                    setModalOptions({...modalOptions, carbon: true});
                    shippingRef.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
                  }}
                >{ PREMIUM_TIER_LABEL }</div>
              </div>
            </div>
          </div>

          <div ref={ shippingRef } className="shipping-schedule modal-element">
            <p><b>Shipping.</b> Choose how often wll you change your filters</p>
            <div className="frequency-selector">
              <div className="options-bar">
                <div
                  className={ "ship-schedule-option " + (modalOptions.frequency === 1 ? "selected" : "") }
                  onClick={ () => setModalOptions({ ...modalOptions, frequency: 1 }) }
                >
                  <div className="wording">Every Month</div>
                </div>
                <div
                  className={ "ship-schedule-option " + (modalOptions.frequency === 2 ? "selected" : "") }
                  onClick={ () => setModalOptions({ ...modalOptions, frequency: 2 }) }
                >
                  <div className="wording">Every 2 Months</div>
                  <div className="recommended"> Recommended</div>
                </div>
                <div
                  className={ "ship-schedule-option " + (modalOptions.frequency === 3 ? "selected" : "") }
                  onClick={ () => setModalOptions({ ...modalOptions, frequency: 3 }) }
                >
                  <div className="wording">Every 3 Months</div>
                </div>
              </div>
            </div>
            <div className="modal-buttons">
              <button className="secondary-button" onClick={ handleModalCancel }>Cancel</button>
              <button className="primary-button" onClick={ handleModalAccept }>Update</button>
            </div>
          </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
