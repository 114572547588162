import React, { ReactElement, useContext } from 'react';
import { Loading } from '../Loading';
import { Footer } from '../Footer';
import { GeneralContext } from '../../../store/context/GeneralState';
import { WizardContext } from '../../../store/context/wizard/WizardState';
import { StepComponentProps } from '../../../interfaces';
import { socket } from '../../../store/context/socket/SocketContext';
import { SENDINBLUE_EVENTS } from '../../../utils';

interface EnterNameProps extends StepComponentProps {
  dataTemplate: Function;
}

export const EnterName = ({ dataTemplate, actions, children }: EnterNameProps): ReactElement => {
  const { sessionsFN, isLoading } = useContext(GeneralContext);
  const { name, setWizardState } = useContext(WizardContext);

  const addName = () => {
    if (name !== '') {
      sessionsFN.startLoading();
      socket.emit('send-event', {
        event: SENDINBLUE_EVENTS.added_name,
        properties: { FIRSTNAME: name },
      });
      sessionsFN.startLoading();
      sessionsFN.put(dataTemplate('user_name', name), actions.nextStep);
    }
  };

  const onKeyUpName = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      addName();
    }
  };

  return (
    <div className="step" data-step="name">
      <h3>
        What is your full <strong>name</strong>?
      </h3>
      <Loading loading={isLoading}>
        <div className="input">
          <input
            className="name-input simple-input"
            type="text"
            placeholder="Type your answer here..."
            autoComplete="off"
            onKeyUp={onKeyUpName}
            value={name}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => setWizardState('name', e.target.value)}
            autoFocus={true}
          />
        </div>
        <Footer>{children({ customCallback: addName, nextDisabled: !name || isLoading })}</Footer>
      </Loading>
    </div>
  );
};
