import { SET_WIZARD_STATE } from '../../types';

const WizardReducer = (state: any, action: any) => {
  switch (action.type) {
    case SET_WIZARD_STATE: {
      return {
        ...state,
        [action.key]: action.payload,
      };
    }
    default:
      return state;
  }
};

export default WizardReducer;
