import React, { ReactElement, useContext } from 'react';
import { Loading } from '../Loading';
import { Footer } from '../Footer';
import { GeneralContext } from '../../../store/context/GeneralState';
import { WizardContext } from '../../../store/context/wizard/WizardState';
import { StepComponentProps } from '../../../interfaces';

export const EnterACSystemAge = ({ actions, children }: StepComponentProps): ReactElement => {
  const { isLoading } = useContext(GeneralContext);
  const { old, setWizardState } = useContext(WizardContext);

  const addOld = (element: Element) => {
    document.querySelectorAll(".step[data-step='old'] ul.radio li").forEach((e) => e.classList.remove('selected'));
    element.classList.add('selected');
    setWizardState('old', element.getAttribute('data-value'));
  };

  const onKeyUpAddOld = (event: React.KeyboardEvent<HTMLLIElement>) => {
    if (event.key === 'Enter') {
      addOld(event.target);
    }
  };

  const onClickHandler = (event: React.MouseEvent<HTMLLIElement>) => {
    addOld(event.target as Element);
  };

  return (
    <div className="step" data-step="old">
      <h3>Is your home's AC system more than 15 years old?</h3>
      <h5>
        The age of your AC System matters. Older AC systems may not be able to handle our current line of filtration
        technology.
      </h5>
      <Loading loading={isLoading}>
        <ul className="radio radio-text">
          <li
            tabIndex={0}
            data-value="Yes"
            className={old === 'Yes' ? 'mdi selected' : 'mdi'}
            onClick={onClickHandler}
            onKeyUp={onKeyUpAddOld}
          >
            Yes
          </li>
          <li
            tabIndex={0}
            data-value="No"
            className={old === 'No' ? 'mdi selected' : 'mdi'}
            onClick={onClickHandler}
            onKeyUp={onKeyUpAddOld}
          >
            No
          </li>
          <li
            tabIndex={0}
            data-value="Unknown"
            className={old === 'Unknown' ? 'mdi selected' : 'mdi'}
            onClick={onClickHandler}
            onKeyUp={onKeyUpAddOld}
          >
            I Don't Know
          </li>
        </ul>
        <Footer>{children({ nextDisabled: !old || isLoading, backDisabled: isLoading })}</Footer>
      </Loading>
    </div>
  );
};
