import { ReactElement } from 'react';
import { Actions } from './Actions';

export const withActions = (Component: any, props: any, actionProps: any, required: boolean): ReactElement => {
  return (
    <Component {...props} actions={actionProps}>
      {(p: any) => <Actions {...actionProps} {...p} required={required} />}
    </Component>
  );
};
