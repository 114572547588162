import { ReactElement } from 'react';

interface SummaryProps {
  amount: number;
  filtersCount: number;
  deliveryInterval: string;
  tax: number;
  ecoDelivery: boolean;
}

const Summary = ({ amount, filtersCount, deliveryInterval, tax, ecoDelivery }: SummaryProps): ReactElement => {
  return (
    <>
      <div className="checkoutStep">
        <h3>Subscription Details</h3>
        <div className="subscriptionDetails">
          {/* <div className="amountCol">
            <i className="mdi mdi-currency-usd"></i>
            <div style={{ display: 'grid', width: '50%', margin: '0 auto' }}>
              <p style={{ gridColumn: '1' }}>
                <b>Subtotal</b>
                <br />
                <span style={{ fontSize: '2rem' }}></span>${amount}
              </p>
              <p style={{ gridColumn: '2' }}>
                <b>Tax</b>
                <br />
                <span style={{ fontSize: '2rem' }}></span>${tax}
              </p>
            </div>

            {ecoDelivery && (
              <>
                <br />
                <p>Free shipping</p>
              </>
            )}
          </div> */}
          <div className="amountCol">
            <i className="mdi mdi-currency-usd"></i>
            <div className="priceSummary">
              <div className="conceptCol">
                <p>
                  <b>Subtotal: </b>
                  <br />
                  <b>Tax: </b>
                </p>
                <p className="total">Total:</p>
              </div>
              <div className="valueCol">
                <p>
                  ${ amount.toFixed(2) }
                  <br />${ tax.toFixed(2) }
                </p>
                <p className="total">${ (tax + amount).toFixed(2) }</p>
              </div>
            </div>

            {ecoDelivery && (
              <>
                <br />
                <p>Free shipping</p>
              </>
            )}
          </div>
          <div className="filtersCol">
            <i className="mdi mdi-rhombus-split-outline"></i>
            <p>
              <b>Filters</b>
              <br />
              {filtersCount}
            </p>
          </div>
          <div className="intervalCol">
            <i className="mdi mdi-truck-fast"></i>
            <p>
              <b>Delivery Schedule</b>
              <br />
              {deliveryInterval}
            </p>
          </div>
        </div>
        <h6>
          To ensure the highest filtration standards all Nano Home filters are custom built to order.<br/>
          Your first subscription shipment will be sent within two weeks of purchase.
        </h6>
      </div>
    </>
  );
};

export default Summary;
